import axios from "axios";
import { Dispatch } from "redux";
import apiURL from "../../../URL";
import { setupInterceptorsTo } from "../../authentication/Interceptors";
import {
  deactivateMeetingLoadingAction,
  deactivateMeetingSuccessAction,
  deactivateMeetingErrorAction,
  deactivateMeetingAPIMsgAction,
  deactivateMeetingAPIResClearAction,
} from "./Action";
import { DeactivateMeetingActionTypes } from "./ActionTypes";
import { DeactivateMeetingRes, DeactivateMeetingBody } from "./Model";
import { headers } from "components/Utility";

let apiRes = {} as DeactivateMeetingRes;
export const deactivateMeeting = (payload: DeactivateMeetingBody) => {
  return function (dispatch: Dispatch<DeactivateMeetingActionTypes>) {
    dispatch(deactivateMeetingLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(apiURL.apiURL + `/telehealthapp/api/telehealth/deactivate_meeting`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(deactivateMeetingLoadingAction(false));
        dispatch(
          deactivateMeetingSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Selected Telehealth meeting has been deactivated successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(deactivateMeetingLoadingAction(false));
        dispatch(
          deactivateMeetingErrorAction(
            {} as DeactivateMeetingRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateDeactivateMeetingAPIResMsg = () => {
  return function (dispatch: Dispatch<DeactivateMeetingActionTypes>) {
    dispatch(deactivateMeetingAPIMsgAction(apiRes as DeactivateMeetingRes, "", "", 0));
  };
};

export const clearDeactivateMeetingAPIRes = () => {
  return function (dispatch: Dispatch<DeactivateMeetingActionTypes>) {
    dispatch(deactivateMeetingAPIResClearAction({} as DeactivateMeetingRes, "", "", 0));
  };
};
