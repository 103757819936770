import {
    WeekdayListDetails,
    GetWeekdayListActionTypes,
    GET_WEEKDAY_LIST_FAIL,
    GET_WEEKDAY_LIST_LOADING,
    GET_WEEKDAY_LIST_SUCCESS,
  } from "./ActionTypes";
  import { GetWeekdayListRes } from "./Model";
  
  const initialStateGetPosts: WeekdayListDetails = {
    loading: false,
    getWeekdayListRes: {} as GetWeekdayListRes,
    errRes: "",
    weekDays: function (_weekDays: any): unknown {
      throw new Error("Function not implemented.");
    }
  };
  export const getWeekdayListReducer = (
    state = initialStateGetPosts,
    action: GetWeekdayListActionTypes
  ): WeekdayListDetails => {
    switch (action.type) {
      case GET_WEEKDAY_LIST_SUCCESS:
        return {
          ...state,
          loading: false,
          getWeekdayListRes: action.payload,
          errRes: "",
        };
      case GET_WEEKDAY_LIST_LOADING:
        return {
          ...state,
          loading: true,
          getWeekdayListRes: {} as GetWeekdayListRes,
        };
      case GET_WEEKDAY_LIST_FAIL:
        return {
          ...state,
          getWeekdayListRes: {} as GetWeekdayListRes,
          loading: false,
          errRes: action.errRes,
        };
      default:
        return state;
    }
  };
  