import React from "react";
import MWLoader from "components/MWLoader";
import PageLayout from "components/MWPageLayout";
import MWPageTitle from "components/MWPageTitle";
import Task from "./Task";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import { Box } from "@mui/material";
export default function Dashboard() {
  const getTaskListRes = useSelector((state: AppState) => state.getTaskListRes);
  return (
    <Box>
      {getTaskListRes.loading ? <MWLoader /> : null}
      <PageLayout>
        <MWPageTitle
          title={t("docdashboardpage.docdashboard", { ns: ["home"] })!}
        />

        <Task />
      </PageLayout>
    </Box>
  );
}
