import * as React from "react";
import { useEffect } from "react";
import { Box, Card, CardContent } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import { t } from "i18next";
import { getDoctorCaseListApi } from "redux/GetDoctorCaseList/API";
import MWLoader from "components/MWLoader";
import MWResourceList from "components/MWResourceList";
import MWExceptionList from "components/MWExceptionList";
import { Link } from "react-router-dom";
import MWPageTitle from "components/MWPageTitle";
import PageLayout from "components/MWPageLayout";

export default function PatientCaseList() {
  const loadingMarkup = <MWLoader />;
  const dispatch = useDispatch();

  // encounter list api call ...
  useEffect(() => {
    dispatch(getDoctorCaseListApi());
  }, [dispatch]);

  const caseListValue = useSelector((state: AppState) => state.caseValue);
  const allCaseValues = caseListValue.getDoctorCaseListRes.patientCaseList;
  const pageBody = (
    <Box>
      {allCaseValues !== undefined ? (
        allCaseValues.length > 0 ? (
          allCaseValues.map((element: any,index) => (
            <Box
              key={index}
              style={{ textDecoration: "none", textTransform: "none" }}
            >
              <Link
                to={`/appointmentdetails/${element.patientId}/${element.caseId}`}
                style={{ textDecoration: "none" }}
              >
                <MWResourceList
                  icon={true}
                  title={element?.whyWant2ndOpinion}
                  showSubtitle={true}
                  subtitle={element?.secondOpinionReasons}
                  showReason={true}
                  reason={element?.consultationType}
                  patient={element?.patientName}
                  showPatient={true}
                  showNavigation={true}
                />
              </Link>
            </Box>
          ))
        ) : (
          <Card>
            <CardContent>
              <MWExceptionList />
            </CardContent>
          </Card>
        )
      ) : (
        <Card>
          <CardContent>
            <MWExceptionList />
          </CardContent>
        </Card>
      )}
    </Box>
  );

  return (
    <Box>
      {caseListValue.loading ? loadingMarkup : null}
      <PageLayout>
        <MWPageTitle title={t("patient.caselist", { ns: ["home"] })!} />
        {pageBody}
      </PageLayout>
    </Box>
  );
}
