import React from "react";
import { Stack } from "@mui/material";
import ProfessionSummary from "./ProfessionalDetails/ProfessionSummary";
import ProfessionalWorkExperience from "./ProfessionalDetails/WorkExperience";
import ProfessionalLicense from "./ProfessionalDetails/ProfessionalLicense";
import ProfessionalMembership from "./ProfessionalDetails/Membership";
export interface Props {
  drProfessionalDetailsLoading: boolean | any;
}
export default function DoctorProfessionalDetails({ drProfessionalDetailsLoading }: Props) {
  const updateProfessionalSummaryLoadingValueFromChild = (passedVal: boolean) => {
    drProfessionalDetailsLoading(passedVal);
  };
  const updateProfessionalWorkExperienceLoadingValueFromChild = (passedVal: boolean) => {
    drProfessionalDetailsLoading(passedVal);
  };
  const updateProfessionalLicenseLoadingValueFromChild = (passedVal: boolean) => {
    drProfessionalDetailsLoading(passedVal);
  };
  const updateProfessionalMembershipLoadingValueFromChild = (passedVal: boolean) => {
    drProfessionalDetailsLoading(passedVal);
  };
  return (
    <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
      <ProfessionSummary
        professionalSummaryLoading={updateProfessionalSummaryLoadingValueFromChild}
      />
      <ProfessionalWorkExperience
        professionalWorkExperienceLoading={updateProfessionalWorkExperienceLoadingValueFromChild}
      />
      <ProfessionalLicense
        professionalLicenseLoading={updateProfessionalLicenseLoadingValueFromChild}
      />
      <ProfessionalMembership
        professionalMembershipLoading={updateProfessionalMembershipLoadingValueFromChild}
      />
    </Stack>
  );
}
