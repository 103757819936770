import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Transition } from "components/MWDialogTransition";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import { useParams } from "react-router-dom";
import {
  clearGetSummaryListAPIRes,
  getDoctorSummaryList,
} from "redux/FilePreparation/doctorSummary/getSummaryList/ApiCall";
import {
  FilePreparationSectionSummaryList,
  GetSummeryListSubmitBody,
} from "redux/FilePreparation/doctorSummary/getSummaryList/Model";
import { ModifiedPatientSummaryList } from "./Model";
import MWExceptionList from "components/MWExceptionList";
export interface Props {
  dialogEnable?: boolean | any;
  dialogUpdateState: boolean | any;
  selectedItem?: any; //ModifiedDegreeList | any;
  selectedItemUpdateState: any;
  selectedChiefComplaintUpdatedValue: any;
  selectedPastHistoryUpdatedValue: any;
  selectedDiagnosisUpdatedValue: any;
  selectedTestProcedureUpdatedValue: any;
}
export default function PatientDetailsDialog({
  dialogEnable,
  dialogUpdateState,
  selectedItem,
  selectedItemUpdateState,
  selectedChiefComplaintUpdatedValue,
  selectedPastHistoryUpdatedValue,
  selectedDiagnosisUpdatedValue,
  selectedTestProcedureUpdatedValue,
}: Props) {
  const { caseid } = useParams() as {
    caseid: string;
  };
  const dispatch = useDispatch();
  const { t } = useTranslation(["home"]);

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [activeResetPasswordDialog, setActiveResetPasswordDialog] = useState(false);

  const closeDialog = () => {
    setActiveResetPasswordDialog(false);
    dialogUpdateState(false);
    selectedItemUpdateState("");
    dispatch(clearGetSummaryListAPIRes());
    setPatientSummaryDataList([] as ModifiedPatientSummaryList[]);
  };

  const [selectedSummaryData, setSelectedSummaryData] = useState("");
  useEffect(() => {
    setActiveResetPasswordDialog(dialogEnable);
  }, [dialogEnable]);

  useEffect(() => {
    if (selectedItem !== undefined) {
      if (selectedItem !== "") {
        dispatch(
          getDoctorSummaryList({
            caseId: caseid,
            secondOpinionUiSection: selectedItem,
          } as GetSummeryListSubmitBody),
        );
      }
    }
    if (selectedItem !== undefined) {
      if (selectedItem !== "") {
        if (selectedItem === "chiefCompliants") {
          selectedChiefComplaintUpdatedValue(selectedSummaryData);
        } else if (selectedItem === "pastHistory") {
          selectedPastHistoryUpdatedValue(selectedSummaryData);
        } else if (selectedItem === "diagnosis") {
          selectedDiagnosisUpdatedValue(selectedSummaryData);
          selectedTestProcedureUpdatedValue("");
        } else {
          selectedTestProcedureUpdatedValue(selectedSummaryData);
        }
      }
    } else {
      selectedChiefComplaintUpdatedValue("");
      selectedPastHistoryUpdatedValue("");
      selectedDiagnosisUpdatedValue("");
      selectedTestProcedureUpdatedValue("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, selectedSummaryData]);

  const patientSummaryDataTableColumns: GridColDef[] = [
    {
      field: "id",
      headerName: `${t("appointmentpage.sel", { ns: ["home"] })}`,
      minWidth: 100,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          disabled={params.row.supportingDocument === "" ? true : false}
          onClick={() => {
            setSelectedSummaryData(params.row.summaryText);
            setTimeout(function () {
              closeDialog();
            }, 25);
          }}
          sx={{ textTransform: "none" }}
        >
          {t("appointmentpage.sel", { ns: ["home"] })}
        </Button>
      ),
    },
    {
      field: "summaryText",
      headerName: `${t("filePrepPage.sum", { ns: ["home"] })}`,
      minWidth: 200,
      flex: 1,
    },
  ];

  // Call Specialty List store
  const patientDataListRes = useSelector((state: AppState) => state.getDoctorSummaryList);
  const [patientSummaryDataList, setPatientSummaryDataList] = useState(
    [] as ModifiedPatientSummaryList[],
  );
  useEffect(() => {
    if (
      patientDataListRes?.getSummaryListResponse?.filePreparationSectionSummaryList !== undefined
    ) {
      if (
        patientDataListRes?.getSummaryListResponse?.filePreparationSectionSummaryList.length > 0
      ) {
        let patientSummaryItem =
          patientDataListRes?.getSummaryListResponse?.filePreparationSectionSummaryList.map(
            (item: FilePreparationSectionSummaryList) =>
              ({
                id: item?.id,
                summaryText: item?.summaryText,
              } as any),
          );
        setPatientSummaryDataList(patientSummaryItem);
      } else setPatientSummaryDataList([] as ModifiedPatientSummaryList[]);
    } else setPatientSummaryDataList([] as ModifiedPatientSummaryList[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientDataListRes]);

  return (
    <Dialog
      open={activeResetPasswordDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeDialog}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
      fullScreen={fullScreen}
      fullWidth
    >
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            {selectedItem !== undefined ? (
              selectedItem !== "" ? (
                selectedItem === "chiefCompliants" ? (
                  <Typography variant="h5">
                    {t("filePrepPage.viewpresentingcomp", { ns: ["home"] })}
                  </Typography>
                ) : selectedItem === "pastHistory" ? (
                  <Typography variant="h5">
                    {t("filePrepPage.viewpasHisSum", { ns: ["home"] })}
                  </Typography>
                ) : selectedItem === "testsProceduresandInvestigations" ? (
                  <Typography variant="h5">
                    {t("filePrepPage.viewTestSum", { ns: ["home"] })}
                  </Typography>
                ) : selectedItem === "diagnosis" ? (
                  <Typography variant="h5">
                    {t("filePrepPage.viewDiagSum", { ns: ["home"] })}
                  </Typography>
                ) : null
              ) : null
            ) : null}
          </Grid>
          <Grid item>
            <IconButton onClick={() => closeDialog()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box>
          {patientSummaryDataList && patientSummaryDataList.length ? (
            <DataGrid
              rows={patientSummaryDataList}
              columns={patientSummaryDataTableColumns}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              getRowId={(row) => row.id}
            />
          ) : (
            <MWExceptionList />
          )}
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={2}>
          <Grid item>
            <Button variant="outlined" onClick={() => closeDialog()} sx={{ textTransform: "none" }}>
              {t("accountdetail.cancel", { ns: ["home"] })}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
