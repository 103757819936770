import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { Alert, Typography } from "@mui/material";
import PageLayout from "components/PageLayout";
import ExistingMeetingList from "./FilePreparation/DoctorPatientInteraction/ExistingMeetingList";
import QuestionAnswer from "./FilePreparation/DoctorPatientInteraction/QuestionAnswer";

type Props = {
  updatedDataState: any;
};

export default function DoctorPatientInteraction({ updatedDataState }: Props) {
  const [lodingState, setLoadingState] = useState(false);
  const updateDataStateFromChild = async (loadingState: any) => {
    setLoadingState(loadingState);
  };

  useEffect(() => {
    updatedDataState(lodingState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lodingState]);

  const bannermarkup = (
    <Alert severity="info" variant="outlined">
      {/* <AlertTitle>{t("docdashboardpage.title", { ns: ["home"] })!}</AlertTitle> */}
      <Typography>{t("docdashboardpage.banner", { ns: ["home"] })!}</Typography>
    </Alert>
  );

  return (
    <PageLayout>
      {bannermarkup}
      <ExistingMeetingList />
      <QuestionAnswer updatedDataState={updateDataStateFromChild} />
    </PageLayout>
  );
}
