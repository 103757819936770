import React, { useState, useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import MWLoader from "components/MWLoader";
import PageLayout from "components/MWPageLayout";
import MWPageTitle from "components/MWPageTitle";
import { t } from "i18next";
import { submitQueryApi } from "redux/needHelp/API";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import { SubmitQueryModel } from "redux/needHelp/Model";

export default function NeedHelp() {
  const dispatch = useDispatch();
  const [textValue, setTextValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const [submitquerySuccess, setSubmitquerySuccess] = useState("");
  const closeHelpSuccess = () => {
    setSubmitquerySuccess("");
  };
  const closeerror = () => {
    setError("");
  };

  const successDiv = submitquerySuccess ? (
    <Alert onClick={closeHelpSuccess} severity="success">
      <p>{submitquerySuccess}</p>
    </Alert>
  ) : (
    ""
  );
  const errorDiv = error ? (
    <Alert onClick={closeerror} severity="error">
      <p>{error}</p>
    </Alert>
  ) : (
    ""
  );

  const body = {
    queryText: textValue,
  } as SubmitQueryModel;

  const needHelp = () => {
    dispatch(submitQueryApi(body));
  };

  const submitQuery = useSelector((state: AppState) => state.submitQuery);
  useEffect(() => {
    setIsLoading(submitQuery?.loading);
  }, [submitQuery]);

  useEffect(() => {
    if (submitQuery?.SUBMIT_QUERY?.message !== undefined) {
      setSubmitquerySuccess(submitQuery?.SUBMIT_QUERY.message);
      setError("");
    } else {
      setError(submitQuery?.errRes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitQuery]);

  return (
    <Box>
      {isLoading ? <MWLoader /> : null}
      <PageLayout>
        <MWPageTitle title={t("common.needhelp", { ns: ["home"] })!} />
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              spacing={2}
            >
              <Typography variant="h6" gutterBottom>
                {t("helppage.helptitle", { ns: ["home"] })!}
              </Typography>

              <Typography sx={{ color: "#EF2E33" }}>
                {t("helppage.helpdesc", { ns: ["home"] })!}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
            <Card>
              <CardHeader title={t("appointmentpage.desc", { ns: ["home"] })} />
              <CardContent>
                <TextField
                  id="outlined-error"
                  value={textValue}
                  onChange={(e) => setTextValue(e.target.value)}
                  placeholder={t("helppage.descprob", { ns: ["home"] })!}
                  fullWidth
                  multiline
                  minRows={4}
                  maxRows={4}
                  size="small"
                />
                <Box my={2}>{successDiv}</Box>
                <Box my={2}>{errorDiv}</Box>
              </CardContent>
              <CardActions>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      onClick={needHelp}
                      sx={{ textTransform: "none" }}
                    >
                      {t("Workschedulepage.submit", { ns: ["home"] })!}
                    </Button>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </PageLayout>
    </Box>
  );
}
