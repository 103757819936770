import React from "react";
import { t } from "i18next";
import {
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import PageLayout from "components/MWPageLayout";

export default function CodeOfConduct() {
  return (
    <PageLayout>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={2}
      >
        <Card>
          <CardHeader
            title={t("codeofconductpage.title1", { ns: ["home"] })!}
          ></CardHeader>
          <CardContent>
            <Typography>
              {t("codeofconductpage.desc1", { ns: ["home"] })}
            </Typography>
          </CardContent>
        </Card>
        <Card>
          <CardHeader
            title={t("codeofconductpage.title2", { ns: ["home"] })!}
          ></CardHeader>
          <List>
            <ListItem>
              <ListItemText primary={t("codeofconductpage.desc2", { ns: ["home"] })} />
            </ListItem>
          </List>
        </Card>
        <Card>
          <CardHeader
            title={t("codeofconductpage.title3", { ns: ["home"] })!}
          ></CardHeader>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc3", { ns: ["home"] })}
              />
            </ListItem>
          </List>
        </Card>
        <Card>
          <CardHeader
            title={t("codeofconductpage.title4", { ns: ["home"] })}
          ></CardHeader>
          <CardContent>
            <Typography>
              {t("codeofconductpage.desc4", { ns: ["home"] })}
            </Typography>
          </CardContent>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc4-1", { ns: ["home"] })}
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc4-2", { ns: ["home"] })}
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc4-3", { ns: ["home"] })}
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc4-4", { ns: ["home"] })}
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc4-5", { ns: ["home"] })}
              />
            </ListItem>
          </List>
        </Card>
        <Card>
          <CardHeader
            title={t("codeofconductpage.title5", { ns: ["home"] })}
          ></CardHeader>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc5-1", { ns: ["home"] })}
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc5-2", { ns: ["home"] })}
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc5-3", { ns: ["home"] })}
              />
            </ListItem>
          </List>
        </Card>
        <Card>
          <CardHeader
            title={t("codeofconductpage.title6", { ns: ["home"] })}
          ></CardHeader>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc6-1", { ns: ["home"] })}
              />
            </ListItem>
          </List>
        </Card>
        <Card>
          <CardHeader
            title={t("codeofconductpage.title7", { ns: ["home"] })}
          ></CardHeader>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc7-1", { ns: ["home"] })}
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc7-2", { ns: ["home"] })}
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc7-3", { ns: ["home"] })}
              />
            </ListItem>
          </List>
        </Card>
        <Card>
          <CardHeader
            title={t("codeofconductpage.title8", { ns: ["home"] })}
          ></CardHeader>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc8-1", { ns: ["home"] })}
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc8-2", { ns: ["home"] })}
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc8-3", { ns: ["home"] })}
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc8-4", { ns: ["home"] })}
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc8-5", { ns: ["home"] })}
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc8-6", { ns: ["home"] })}
              />
            </ListItem>
          </List>
        </Card>
        <Card>
          <CardHeader
            title={t("codeofconductpage.title9", { ns: ["home"] })!}
          ></CardHeader>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc9", { ns: ["home"] })!}
              />
            </ListItem>
          </List>
        </Card>
        <Card>
          <CardHeader
            title={t("codeofconductpage.title10", { ns: ["home"] })!}
          ></CardHeader>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc10-1", { ns: ["home"] })!}
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc10-2", { ns: ["home"] })!}
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc10-3", { ns: ["home"] })!}
              />
            </ListItem>
          </List>
        </Card>
        <Card>
          <CardHeader
            title={t("codeofconductpage.title11", { ns: ["home"] })!}
          ></CardHeader>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc11", { ns: ["home"] })!}
              />
            </ListItem>
          </List>
        </Card>
        <Card>
          <CardHeader
            title={t("codeofconductpage.title12", { ns: ["home"] })!}
          ></CardHeader>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc12-1", { ns: ["home"] })!}
              />
            </ListItem>
          </List>
          <CardContent>
            <Typography variant="h4" gutterBottom>
              {t("codeofconductpage.title12-1", { ns: ["home"] })!}
            </Typography>
          </CardContent>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc12-2", { ns: ["home"] })!}
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc12-3", { ns: ["home"] })!}
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc12-4", { ns: ["home"] })!}
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc12-5", { ns: ["home"] })!}
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc12-6", { ns: ["home"] })!}
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc12-7", { ns: ["home"] })!}
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc12-8", { ns: ["home"] })!}
              />
            </ListItem>
          </List>
        </Card>
        <Card>
          <CardHeader
            title={t("codeofconductpage.title13", { ns: ["home"] })!}
          ></CardHeader>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc13-1", { ns: ["home"] })!}
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc13-2", { ns: ["home"] })!}
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc13-3", { ns: ["home"] })!}
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc13-4", { ns: ["home"] })!}
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc13-5", { ns: ["home"] })!}
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc13-6", { ns: ["home"] })!}
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc13-7", { ns: ["home"] })!}
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc13-8", { ns: ["home"] })!}
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc13-9", { ns: ["home"] })!}
              />
            </ListItem>
          </List>
        </Card>
        <Card>
          <CardHeader
            title={t("codeofconductpage.title14", { ns: ["home"] })!}
          ></CardHeader>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc14", { ns: ["home"] })!}
              />
            </ListItem>
          </List>
        </Card>
        <Card>
          <CardHeader
            title={t("codeofconductpage.title15", { ns: ["home"] })!}
          ></CardHeader>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc15-1", { ns: ["home"] })!}
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc15-2", { ns: ["home"] })!}
              />
            </ListItem>
          </List>
        </Card>
        <Card>
          <CardHeader
            title={t("codeofconductpage.title16", { ns: ["home"] })!}
          ></CardHeader>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc16-1", { ns: ["home"] })!}
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc16-2", { ns: ["home"] })!}
              />
            </ListItem>
          </List>
        </Card>
        <Card>
          <CardHeader
            title={t("codeofconductpage.title17", { ns: ["home"] })!}
          ></CardHeader>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc17-1", { ns: ["home"] })!}
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc17-2", { ns: ["home"] })!}
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc17-3", { ns: ["home"] })!}
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc17-4", { ns: ["home"] })!}
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc17-5", { ns: ["home"] })!}
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc17-6", { ns: ["home"] })!}
              />
            </ListItem>
          </List>
        </Card>
        <Card>
          <CardHeader
            title={t("codeofconductpage.title18", { ns: ["home"] })!}
          ></CardHeader>
        </Card>
        <Card>
          <CardHeader
            title={t("codeofconductpage.title19", { ns: ["home"] })!}
          ></CardHeader>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc19-1", { ns: ["home"] })!}
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc19-2", { ns: ["home"] })!}
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc19-3", { ns: ["home"] })!}
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc19-4", { ns: ["home"] })!}
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemText
                primary={t("codeofconductpage.desc19-5", { ns: ["home"] })!}
              />
            </ListItem>
          </List>
        </Card>
      </Stack>
    </PageLayout>
  );
}
