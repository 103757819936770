import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { t } from "i18next";
import { Button, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RefreshIcon from "@mui/icons-material/Refresh";
import { AppState } from "redux/store/Store";
import { useDispatch, useSelector } from "react-redux";
import { getTaskListList } from "redux/Task/API";
import { TaskDataList } from "redux/Task/Model";

export default function Task() {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getTaskListList());
  }, [dispatch]);

  const awardTableColumns: GridColDef[] = [
    {
      field: "id",
      headerName: `${t("preCompPage.docu", { ns: ["home"] })!}`,
      minWidth: 150,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            history.push(
              `/taskdetails/${params.row.patientId}/${params.row.caseId}/${params.row.itemIndex}`,
            );
          }}
          sx={{ textTransform: "none" }}
        >
          {
            t("common.view", {
              ns: ["home"],
            })!
          }
        </Button>
      ),
    },
    {
      field: "name",
      headerName: `${t("docdashboardpage.title", { ns: ["home"] })!}`,
      minWidth: 315,
    },
    {
      field: "patientName",
      headerName: `${t("common.patientname", { ns: ["home"] })!}`,
      minWidth: 300,
      flex: 1,
    },
    {
      field: "consultationType",
      headerName: `${t("docdashboardpage.constype", { ns: ["home"] })!}`,
      minWidth: 200,
      flex: 1,
    },
    {
      field: "secondOpinionReasons",
      headerName: `${t("docdashboardpage.secondopinion", { ns: ["home"] })!}`,
      minWidth: 200,
      flex: 1,
    },
  ];

  // Get professional list
  const [taskList, setTaskList] = useState([] as any[]);
  // Call task list store
  const getTaskListRes = useSelector((state: AppState) => state.getTaskListRes);

  useEffect(() => {
    // setIsLoading(getWorkExperienceValueRes?.loading);
    if (getTaskListRes?.getDoctorTaskListRes?.taskDataList !== undefined) {
      let response = getTaskListRes?.getDoctorTaskListRes?.taskDataList.map(
        (item: TaskDataList, index: number) =>
          ({
            id: item?.id,
            itemIndex: index,
            caseId:
              item?.caseId !== undefined ? (item?.caseId !== null ? item?.caseId : null) : null,
            patientId:
              item?.patientId !== undefined
                ? item?.patientId !== null
                  ? item?.patientId
                  : null
                : null,
            patientName:
              item?.patientName !== undefined
                ? item?.patientName !== null
                  ? item?.patientName
                  : ""
                : "",
            name: item?.title !== undefined ? (item?.title !== null ? item.title : "") : "",
            consultationType:
              item?.consultationType !== undefined
                ? item?.consultationType !== null
                  ? item.consultationType
                  : ""
                : "",
            secondOpinionReasons:
              item?.secondOpinionReasons !== undefined
                ? item?.secondOpinionReasons !== null
                  ? item?.secondOpinionReasons
                  : ""
                : "",
            // presentDate:
            //   item?.fromDate !== null
            //     ? dayjs(item?.fromDate).format("DD-MM-YYYY")
            //     : "Not Set",
          } as any),
      );

      setTaskList(response);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTaskListRes]);

  return (
    <Card>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="subtitle1" fontWeight="bold">
                {t("docdashboardpage.tasklist", { ns: ["home"] })}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                startIcon={<RefreshIcon />}
                onClick={() => dispatch(getTaskListList())}
                variant="contained"
                sx={{ textTransform: "none" }}
              >
                {t("common.reload", { ns: ["home"] })!}
              </Button>
            </Grid>
          </Grid>
        }
      ></CardHeader>
      <CardContent>
        {taskList && taskList.length ? (
          <DataGrid
            rows={taskList}
            columns={awardTableColumns}
            hideFooter
            hideFooterPagination
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            getRowId={(row) => row.id}
          />
        ) : (
          <Typography>{`${t("userprofilepage.noprofexp", { ns: ["home"] })!}`},</Typography>
        )}
      </CardContent>
    </Card>
  );
}
