import React, { useState, useEffect } from "react";
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import { AddEmailPhoneBody } from "redux/AddEmailPhone/Model";
import { clearAddEmailPhoneAPIRes, addEmailPhoneAPI } from "redux/AddEmailPhone/API";
import { Transition } from "components/MWDialogTransition";
import { EmailPhoneOTPGenBody } from "redux/AccountDetails/EmailPhoneGenerateOTP/Model";
import {
  clearEmailPhoneOTPGenAPIRes,
  emailPhoneGenerateOTP,
} from "redux/AccountDetails/EmailPhoneGenerateOTP/API";
import { doctorAccountDetailsAPI } from "redux/AccountDetails/DoctorAccDetails/ApiCall";
import MWLoader from "components/MWLoader";

interface Props {
  isEnable: boolean | any;
  dialogUpdateState: boolean | any;
  attachmentType?: string | any;
}
export default function AddEmailPhone({ isEnable, dialogUpdateState, attachmentType }: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation(["home"]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [activeResetPasswordDialog, setActiveResetPasswordDialog] = useState(false);

  const closeDialog = () => {
    setActiveResetPasswordDialog(false);
    dialogUpdateState(false);
    dispatch(clearEmailPhoneOTPGenAPIRes());
    dispatch(clearAddEmailPhoneAPIRes());
  };
  useEffect(() => {
    setActiveResetPasswordDialog(isEnable);
  }, [isEnable]);

  const [phoneNo, setPhoneNo] = useState("");
  const [errorPhoneNo, setErrorPhoneNo] = useState("");

  const [otp, setOTP] = useState("");
  const [oTPError, setOTPError] = useState("");

  const generateOTPBody = {
    email: phoneNo.toLowerCase(),
  } as EmailPhoneOTPGenBody;

  // Email OTP Generation API
  const emailPhoneOtpGeneration = () => {
    if (phoneNo === "") {
      if (phoneNo.includes("@")) {
        setErrorPhoneNo(`${t("common.erroremail", { ns: ["home"] })}`);
      } else {
        setErrorPhoneNo(`${t("accountdetail.enterphnno", { ns: ["home"] })}`);
      }
    } else if (
      new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(phoneNo.toLowerCase()) ===
        false &&
      new RegExp(/^\d{10}$/).test(phoneNo.toLowerCase()) === false
    ) {
      if (phoneNo.includes("@")) {
        setErrorPhoneNo(`${t("common.validemail", { ns: ["home"] })}`);
      } else {
        setErrorPhoneNo(`${t("accountdetail.validphnno", { ns: ["home"] })}`);
      }
    } else {
      setErrorPhoneNo("");
      dispatch(emailPhoneGenerateOTP(generateOTPBody));
    }
  };

  const emailPhoneOTPGenResponse = useSelector((state: AppState) => state.emailPhoneOTPGenRes);

  const [emailPhoneOTPGenLoading, setEmailPhoneOTPGenLoading] = useState(false);
  const [emailPhoneOTPGenMessage, setEmailPhoneOTPGenMessage] = useState("");
  const [emailPhoneOTPGenMessageType, setEmailPhoneOTPGenMessageType] =
    useState<AlertColor>("success");

  useEffect(() => {
    setEmailPhoneOTPGenLoading(emailPhoneOTPGenResponse?.loading);
    if (emailPhoneOTPGenResponse?.successMsg !== "") {
      setEmailPhoneOTPGenMessage(emailPhoneOTPGenResponse?.successMsg);
      setEmailPhoneOTPGenMessageType("success");
    }
    if (emailPhoneOTPGenResponse?.errorMsg !== "") {
      setEmailPhoneOTPGenMessage(emailPhoneOTPGenResponse.errorMsg);
      setEmailPhoneOTPGenMessageType("error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailPhoneOTPGenResponse]);

  const phoneVerificationBody = {
    email: phoneNo.toLowerCase(),
    otp: otp,
  } as AddEmailPhoneBody;

  // Add Email / Phone Number
  const addEmailPhoneNumber = () => {
    if (phoneNo === "" && otp === "") {
      if (phoneNo.includes("@")) {
        setErrorPhoneNo(`${t("common.erroremail", { ns: ["home"] })}`);
      } else {
        setErrorPhoneNo(`${t("accountdetail.enterphnno", { ns: ["home"] })}`);
      }
      setOTPError(`${t("common.enterotp", { ns: ["home"] })}`);
    } else if (phoneNo === "" && otp !== "") {
      if (phoneNo.includes("@")) {
        setErrorPhoneNo(`${t("common.erroremail", { ns: ["home"] })}`);
      } else {
        setErrorPhoneNo(`${t("accountdetail.enterphnno", { ns: ["home"] })}`);
      }
      setOTPError("");
    } else if (phoneNo !== "" && otp === "") {
      setErrorPhoneNo("");
      setOTPError(`${t("common.enterotp", { ns: ["home"] })}`);
    } else {
      setErrorPhoneNo("");
      setOTPError("");
      dispatch(addEmailPhoneAPI(phoneVerificationBody));
    }
  };

  const [addEmailPhoneMessage, setAddEmailPhoneMessage] = useState("");
  const [addEmailPhoneMessageType, setAddEmailPhoneMessageType] = useState<AlertColor>("success");

  const addEmailPhoneResponse = useSelector((state: AppState) => state.addEmailPhoneRes);
  useEffect(() => {
    if (addEmailPhoneResponse?.successMsg !== "") {
      setAddEmailPhoneMessage(addEmailPhoneResponse?.successMsg);
      setAddEmailPhoneMessageType("success");
      dispatch(doctorAccountDetailsAPI());
    }
    if (addEmailPhoneResponse?.errorMsg !== "") {
      setAddEmailPhoneMessage(addEmailPhoneResponse.errorMsg);
      setAddEmailPhoneMessageType("error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addEmailPhoneResponse]);

  return (
    <Dialog
      open={activeResetPasswordDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeDialog}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
      fullScreen={fullScreen}
      fullWidth
    >
      {emailPhoneOTPGenLoading || addEmailPhoneResponse?.loading ? <MWLoader /> : null}
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            {attachmentType === "Email" ? (
              <Typography>{t("accountdetail.newpassword", { ns: ["home"] })}</Typography>
            ) : (
              <Typography>{t("accountdetail.addphn", { ns: ["home"] })}</Typography>
            )}
          </Grid>
          <Grid item>
            <IconButton onClick={() => closeDialog()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
              <TextField
                id="phoneNo"
                label={t("accountdetail.phoneno", { ns: ["home"] })}
                placeholder={t("accountdetail.enterphnno", { ns: ["home"] })!}
                value={phoneNo}
                onChange={(event: any) => setPhoneNo(event.target.value)}
                fullWidth
                size="small"
                error={errorPhoneNo === "" ? false : true}
                helperText={
                  errorPhoneNo === ""
                    ? attachmentType === "Email"
                      ? "Please provide email"
                      : "Please provide phone number"
                    : errorPhoneNo
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Trans>
                <Button
                  variant="outlined"
                  onClick={() => emailPhoneOtpGeneration()}
                  sx={{ textTransform: "none" }}
                  disabled={emailPhoneOTPGenLoading}
                >
                  {t("accountdetail.generateotp", { ns: ["home"] })!}
                </Button>
              </Trans>
            </Grid>
          </Grid>
          {emailPhoneOTPGenMessage !== "" ? (
            <Box my={2}>
              <Alert
                severity={emailPhoneOTPGenMessageType}
                onClose={() => {
                  dispatch(clearEmailPhoneOTPGenAPIRes());
                  setEmailPhoneOTPGenMessage("");
                }}
              >
                {emailPhoneOTPGenMessage}
              </Alert>
            </Box>
          ) : null}

          <TextField
            id="newpassword"
            label={t("otp", { ns: ["home"] })}
            placeholder={t("otp", { ns: ["home"] })!}
            value={otp}
            onChange={(event: any) => setOTP(event.target.value)}
            fullWidth
            size="small"
            error={oTPError === "" ? false : true}
            helperText={oTPError === "" ? "Please provide otp" : oTPError}
            disabled={emailPhoneOTPGenMessage === "" ? true : false}
          />
        </Stack>
        {addEmailPhoneMessage !== "" ? (
          <Box my={2}>
            <Alert
              severity={addEmailPhoneMessageType}
              onClose={() => {
                dispatch(clearAddEmailPhoneAPIRes());
                setAddEmailPhoneMessage("");
              }}
            >
              {addEmailPhoneMessage}
            </Alert>
          </Box>
        ) : null}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={2}>
          <Grid item>
            <Button variant="outlined" onClick={() => closeDialog()} sx={{ textTransform: "none" }}>
              {t("accountdetail.cancel", { ns: ["home"] })}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              disabled={
                addEmailPhoneResponse?.loading || emailPhoneOTPGenMessage === "" ? true : false
              }
              onClick={() => addEmailPhoneNumber()}
              sx={{ textTransform: "none" }}
            >
              {t("add", { ns: ["home"] })}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
