import {
  DEACTIVATE_MEETING_FAIL,
  DEACTIVATE_MEETING_LOADING,
  DEACTIVATE_MEETING_SUCCESS,
  DEACTIVATE_MEETING_API_MSG,
  DEACTIVATE_MEETING_API_RES,
  DeactivateMeetingActionTypes,
} from "./ActionTypes";
import { DeactivateMeetingRes } from "./Model";

export const deactivateMeetingLoadingAction = (
  loading: boolean
): DeactivateMeetingActionTypes => {
  return {
    type: DEACTIVATE_MEETING_LOADING,
    loading: loading,
  };
};

export const deactivateMeetingSuccessAction = (
  deactivateMeetingRes: DeactivateMeetingRes,
  successMsg: string
): DeactivateMeetingActionTypes => {
  return {
    type: DEACTIVATE_MEETING_SUCCESS,
    payload: deactivateMeetingRes,
    successMsg: successMsg,
  };
};

export const deactivateMeetingErrorAction = (
  deactivateMeetingRes: DeactivateMeetingRes,
  errMsg: string,
  status: number
): DeactivateMeetingActionTypes => {
  return {
    type: DEACTIVATE_MEETING_FAIL,
    payload: deactivateMeetingRes,
    errorMsg: errMsg,
    status: status,
  };
};
export const deactivateMeetingAPIMsgAction = (
  deactivateMeetingRes: DeactivateMeetingRes,
  successMsg: string,
  errMsg: string,
  status: number
): DeactivateMeetingActionTypes => {
  return {
    type: DEACTIVATE_MEETING_API_MSG,
    payload: deactivateMeetingRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const deactivateMeetingAPIResClearAction = (
  deactivateMeetingRes: DeactivateMeetingRes,
  successMsg: string,
  errMsg: string,
  status: number
): DeactivateMeetingActionTypes => {
  return {
    type: DEACTIVATE_MEETING_API_RES,
    payload: deactivateMeetingRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
