import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertColor,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Transition } from "components/MWDialogTransition";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ModifiedIdentityProofList } from "../Model";
import MWUploader from "components/MWUploader";
import { AppState } from "redux/store/Store";
import dayjs, { Dayjs } from "dayjs";
import { IdProofTypeList } from "redux/UserProfile/IdentityDetails/GovtIdProofDropdownList /Model";
import { AddDrIdProofBody } from "redux/UserProfile/IdentityDetails/AddDoctorIdProof/Model";
import {
  addDrIdProof,
  clearAddDrIdProofAPIRes,
} from "redux/UserProfile/IdentityDetails/AddDoctorIdProof/API";
import { getDrIdProofList } from "redux/UserProfile/IdentityDetails/GetIdDetailsByDoctor/API";
import { EditDrIdProofBody } from "redux/UserProfile/IdentityDetails/EditDoctorIdProof/Model";
import {
  clearEditDrIdProofAPIRes,
  editDrIdProof,
} from "redux/UserProfile/IdentityDetails/EditDoctorIdProof/API";
import MWLoader from "components/MWLoader";
import { clearImageUploadAPIRes } from "redux/ImageUpload/API";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
export interface Props {
  dialogEnable?: boolean | any;
  dialogUpdateState: boolean | any;
  selectedItem?: ModifiedIdentityProofList | any;
  selectedItemUpdateState: any;
}
export default function UpdateIdentityDetails({
  dialogEnable,
  dialogUpdateState,
  selectedItem,
  selectedItemUpdateState,
}: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation(["home"]);

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [activeResetPasswordDialog, setActiveResetPasswordDialog] = useState(false);

  const closeDialog = () => {
    setActiveResetPasswordDialog(false);
    dialogUpdateState(false);
    setReferenceNumber("");
    setSelectedDrIdProofTypeListType(null);
    dispatch(clearImageUploadAPIRes());
    setImageId("");
    setSelectedDate(dayjs(new Date()));
    selectedItemUpdateState({} as any);
    dispatch(clearAddDrIdProofAPIRes());
    setAddDrDrIdProofTypeListDetailsMsg("");
    dispatch(clearEditDrIdProofAPIRes());
    setEditDrIdProofTypeListDetailsMsg("");
  };
  useEffect(() => {
    setActiveResetPasswordDialog(dialogEnable);
  }, [dialogEnable]);

  const getDrIdProofTypeListRes = useSelector((state: AppState) => state.getDrIdProofTypeListRes);
  const [drIdProofTypeListList, setDrIdProofTypeListTypeList] = useState([] as IdProofTypeList[]);
  const [selectedDrIdProofTypeListType, setSelectedDrIdProofTypeListType] =
    useState<IdProofTypeList | null>(null);
  const selectedDrIdProofTypeListTypeChange = (_event: any, passedVal: IdProofTypeList | any) => {
    setSelectedDrIdProofTypeListType(passedVal);
  };
  const [selectedDrIdProofTypeListTypeErrorMsg, setSelectedDrIdProofTypeListTypeErrorMsg] =
    useState("");

  useEffect(() => {
    if (getDrIdProofTypeListRes?.getDrIdProofTypeListRes?.idProofTypeList !== undefined) {
      if (getDrIdProofTypeListRes?.getDrIdProofTypeListRes?.idProofTypeList.length > 0) {
        setDrIdProofTypeListTypeList(
          getDrIdProofTypeListRes?.getDrIdProofTypeListRes?.idProofTypeList,
        );
      } else setDrIdProofTypeListTypeList([] as IdProofTypeList[]);
    } else setDrIdProofTypeListTypeList([] as IdProofTypeList[]);
  }, [getDrIdProofTypeListRes]);

  const [referenceNumber, setReferenceNumber] = useState("");
  const DOCUMENT_PATTERN = /^[a-zA-Z0-9_]*$/;
  const [referenceNumberErrorMsg, setReferenceNumberErrorMsg] = useState("");

  // from Date
  const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(dayjs(new Date()));
  const handleDateChange = (date: Dayjs | null) => {
    setSelectedDate(date);
  };

  // upload image
  const [imageId, setImageId] = useState("");
  const [imageInfo, setImageInfo] = useState("");
  const [, setDelImg] = useState(false);
  // delete selected image from image uploader
  const delImgAction = (passeVal: boolean) => {
    setDelImg(passeVal);
  };
  const [, setCheckImgUpload] = useState(false);
  // Get PreviewImg Details
  const viewPreviewImageData = (passedVal: any) => {
    setCheckImgUpload(passedVal);
  };
  const updateDataStateFromChild = async (selectedImgId: any) => {
    setImageId(selectedImgId);
  };

  useEffect(() => {
    selectedItem?.identityProofDocValue !== undefined && drIdProofTypeListList.length !== 0
      ? selectedItem?.identityProofDocValue !== null && drIdProofTypeListList.length !== 0
        ? setSelectedDrIdProofTypeListType(
            drIdProofTypeListList.find(
              (o: IdProofTypeList) => o.value === selectedItem?.identityProofDocValue,
            )!,
          )
        : setSelectedDrIdProofTypeListType(null)
      : setSelectedDrIdProofTypeListType(null);

    selectedItem?.identityProofDocNumber !== undefined
      ? selectedItem?.identityProofDocNumber !== ""
        ? setReferenceNumber(selectedItem?.identityProofDocNumber)
        : setReferenceNumber("")
      : setReferenceNumber("");

    if (selectedItem?.validityDateDate !== undefined) {
      let year = selectedItem?.validityDateDate.split("-")[2];
      let month = selectedItem?.validityDateDate.split("-")[1];
      let day = selectedItem?.validityDateDate.split("-")[0];
      let date = year + "-" + month + "-" + day;
      setSelectedDate(dayjs(date));
    }
    if (selectedItem?.validityDateDate === undefined) {
      if (selectedItem?.validityDateDate === null) {
        setSelectedDate(dayjs(new Date()));
      }
    }

    selectedItem?.supportingDocument !== undefined
      ? selectedItem?.supportingDocument !== ""
        ? setImageInfo(selectedItem?.supportingDocument)
        : setImageInfo("")
      : setImageInfo("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, drIdProofTypeListList]);

  const addDrIdProofTypeListDetailsBody = {
    proofTypeId:
      selectedDrIdProofTypeListType?.value !== null ? selectedDrIdProofTypeListType?.value : null,
    idNumber: referenceNumber,
    validityDate:
      selectedDate !== null ? dayjs(selectedDate).utc().format("YYYY-MM-DDTHH:mm:ssZ") : null,
  } as AddDrIdProofBody;

  const submitDrIdProofTypeListDetails = () => {
    if (selectedDrIdProofTypeListType === null && referenceNumber === "") {
      setSelectedDrIdProofTypeListTypeErrorMsg(
        `${t("userprofilepage.plsenter", { ns: ["home"] })!}`,
      );
      setReferenceNumberErrorMsg(`${t("userprofilepage.plsenterinscovamt", { ns: ["home"] })!}`);
    } else if (selectedDrIdProofTypeListType === null && referenceNumber !== "") {
      setSelectedDrIdProofTypeListTypeErrorMsg(
        `${t("userprofilepage.plsenter", { ns: ["home"] })!}`,
      );
      setReferenceNumberErrorMsg("");
    } else if (selectedDrIdProofTypeListType !== null && referenceNumber === "") {
      setSelectedDrIdProofTypeListTypeErrorMsg("");
      setReferenceNumberErrorMsg(`${t("userprofilepage.plsenterinscovamt", { ns: ["home"] })!}`);
    } else {
      setSelectedDrIdProofTypeListTypeErrorMsg("");
      setReferenceNumberErrorMsg("");
      if (selectedItem?.id === undefined) {
        // Add Dr. Education Details
        if (imageId === undefined) {
          dispatch(
            addDrIdProof(
              Object.assign(addDrIdProofTypeListDetailsBody, {
                supportdocId: imageId !== "" ? imageId : null,
              }),
            ),
          );
        } else {
          dispatch(
            addDrIdProof(
              Object.assign(addDrIdProofTypeListDetailsBody, {
                supportdocId: imageId !== "" ? imageId! : null,
              }),
            ),
          );
        }
      } else {
        // Edit Dr. Education Details
        if (imageId === undefined) {
          // When user does not upload image on add work experience
          dispatch(
            editDrIdProof(
              Object.assign(addDrIdProofTypeListDetailsBody, {
                govtproofId: Number(selectedItem?.id!),
                supportdocId: imageId !== "" ? imageId : null,
              } as EditDrIdProofBody),
            ),
          );
        } else {
          dispatch(
            editDrIdProof(
              Object.assign(addDrIdProofTypeListDetailsBody, {
                govtproofId: Number(selectedItem?.id!),
                supportdocId: imageId !== "" ? imageId : null,
              } as EditDrIdProofBody),
            ),
          );
        }
      }
    }
  };

  const addDrIdProofRes = useSelector((state: AppState) => state.addDrIdProofRes);
  const [addDrDrIdProofTypeListDetailsMsg, setAddDrDrIdProofTypeListDetailsMsg] = useState("");
  const [addDrDrIdProofTypeListDetailsMsgType, setAddDrDrIdProofTypeListDetailsMsgType] =
    useState<AlertColor>("success");

  useEffect(() => {
    if (addDrIdProofRes?.successMsg !== "") {
      setAddDrDrIdProofTypeListDetailsMsgType("success");
      setAddDrDrIdProofTypeListDetailsMsg(addDrIdProofRes?.successMsg);
      dispatch(getDrIdProofList());
      closeDialog();
    }
    if (addDrIdProofRes?.errorMsg !== "") {
      setAddDrDrIdProofTypeListDetailsMsgType("error");
      setAddDrDrIdProofTypeListDetailsMsg(addDrIdProofRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addDrIdProofRes]);

  const editDrIdProofRes = useSelector((state: AppState) => state.editDrIdProofRes);
  const [editDrIdProofTypeListDetailsMsg, setEditDrIdProofTypeListDetailsMsg] = useState("");
  const [editDrIdProofTypeListDetailsMsgType, setEditDrIdProofTypeListDetailsMsgType] =
    useState<AlertColor>("success");

  useEffect(() => {
    if (editDrIdProofRes?.successMsg !== "") {
      setEditDrIdProofTypeListDetailsMsgType("success");
      setEditDrIdProofTypeListDetailsMsg(editDrIdProofRes?.successMsg);
      dispatch(getDrIdProofList());
      closeDialog();
    }
    if (editDrIdProofRes?.errorMsg !== "") {
      setEditDrIdProofTypeListDetailsMsgType("error");
      setEditDrIdProofTypeListDetailsMsg(editDrIdProofRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editDrIdProofRes]);

  return (
    <Dialog
      open={activeResetPasswordDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeDialog}
      aria-describedby="alert-dialog-slide-referenceNumber"
      maxWidth="md"
      fullScreen={fullScreen}
      fullWidth
    >
      {addDrIdProofRes?.loading || editDrIdProofRes?.loading ? <MWLoader /> : null}
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            {selectedItem?.id ? (
              <Typography variant="h5">
                {t("userprofilepage.editiddetail", { ns: ["home"] })}
              </Typography>
            ) : (
              <Typography variant="h5">
                {t("userprofilepage.addiddetail", { ns: ["home"] })}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <IconButton onClick={() => closeDialog()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-between"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography variant="body1" style={{ marginBottom: "5px" }}>
                {t("userprofilepage.selid", { ns: ["home"] })}
              </Typography>
              <Autocomplete
                value={selectedDrIdProofTypeListType}
                id="highest-states"
                options={drIdProofTypeListList}
                onChange={(event: any, newValue: any) => {
                  selectedDrIdProofTypeListTypeChange(event, newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={t("userprofilepage.selectidprooftype", { ns: ["home"] })!}
                  />
                )}
                size="small"
                getOptionLabel={(option: any) => option?.label! || ""}
                isOptionEqualToValue={(option: any, value) => option.value === value.value}
              />
              {selectedDrIdProofTypeListTypeErrorMsg !== "" ? (
                <Typography style={{ marginBottom: "5px", color: "#d32f2f" }}>
                  {selectedDrIdProofTypeListTypeErrorMsg}
                </Typography>
              ) : null}
              <Box style={{ marginBottom: "5px", fontWeight: "bold" }}>
                {t("userprofilepage.uploadselid", { ns: ["home"] })}
              </Box>
              <Typography style={{ marginBottom: "5px" }}>
                {t("userprofilepage.refno", { ns: ["home"] })}
              </Typography>
              <TextField
                label={t("userprofilepage.refno", { ns: ["home"] })}
                placeholder={t("userprofilepage.enterrefno", { ns: ["home"] })!}
                value={referenceNumber}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const value = event.target.value;
                  if (value !== "" && !DOCUMENT_PATTERN.test(value)) {
                    return;
                  }
                  setReferenceNumber(event.target.value);
                }}
                type="text"
                fullWidth
                size="small"
                inputProps={{ maxLength: 9, minLength: 9 }}
                required
                error={referenceNumber !== "" && referenceNumber.length !== 9 ? true : false}
              />
              {referenceNumberErrorMsg !== "" ? (
                <Typography style={{ marginBottom: "5px", color: "#d32f2f" }}>
                  {referenceNumberErrorMsg}
                </Typography>
              ) : null}
              <Typography style={{ marginBottom: "5px", marginTop: "5px" }}>
                {t("userprofilepage.validupto", { ns: ["home"] })}
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  disablePast
                  inputFormat="DD/MM/YYYY"
                  value={selectedDate}
                  onChange={handleDateChange}
                  views={["year", "month", "day"]}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      fullWidth
                      inputProps={{ ...params.inputProps }}
                      size="small"
                      variant="outlined"
                    />
                  )}
                  InputAdornmentProps={{ position: "start" }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <MWUploader
                documentType="doctor-govt-id-proof"
                criteria={t("userprofilepage.awarddocsize", { ns: ["home"] })!}
                updatedDataState={updateDataStateFromChild}
                title={t("userprofilepage.uploadsuppdoc", { ns: ["home"] })!}
                visibility={false}
                imageUrl={imageInfo}
                deleteImgAction={delImgAction}
                previewImageData={viewPreviewImageData}
              />
            </Grid>
          </Grid>
        </Stack>
        {addDrDrIdProofTypeListDetailsMsg !== "" ? (
          <Box my={2}>
            <Alert
              severity={addDrDrIdProofTypeListDetailsMsgType}
              onClose={() => {
                dispatch(clearAddDrIdProofAPIRes());
                setAddDrDrIdProofTypeListDetailsMsg("");
              }}
            >
              {addDrDrIdProofTypeListDetailsMsg}
            </Alert>
          </Box>
        ) : null}
        {editDrIdProofTypeListDetailsMsg !== "" ? (
          <Box my={2}>
            <Alert
              severity={editDrIdProofTypeListDetailsMsgType}
              onClose={() => {
                dispatch(clearEditDrIdProofAPIRes());
                setEditDrIdProofTypeListDetailsMsg("");
              }}
            >
              {editDrIdProofTypeListDetailsMsg}
            </Alert>
          </Box>
        ) : null}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={2}>
          <Grid item>
            <Button variant="outlined" onClick={() => closeDialog()} sx={{ textTransform: "none" }}>
              {t("accountdetail.cancel", { ns: ["home"] })}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              disabled={addDrIdProofRes?.loading || editDrIdProofRes?.loading}
              onClick={() => submitDrIdProofTypeListDetails()}
              sx={{ textTransform: "none" }}
            >
              {t("Workschedulepage.submit", { ns: ["home"] })!}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
