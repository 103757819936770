import {
  SET_CREATE_MEETING_FAIL,
  SET_CREATE_MEETING_LOADING,
  SET_CREATE_MEETING_SUCCESS,
  SET_CREATE_MEETING_API_MSG,
  SET_CREATE_MEETING_API_RES,
  CreateMeetingActionTypes,
} from "./ActionTypes";
import { CreateInstantMeetingValue } from "./Model";

export const setDrTeleHealthCredentialsLoadingAction = (
  loading: boolean,
): CreateMeetingActionTypes => {
  return {
    type: SET_CREATE_MEETING_LOADING,
    loading: loading,
  };
};

export const setDrTeleHealthCredentialsSuccessAction = (
  setDrTeleHealthCredentialsRes: CreateInstantMeetingValue,
  successMsg: string,
): CreateMeetingActionTypes => {
  return {
    type: SET_CREATE_MEETING_SUCCESS,
    payload: setDrTeleHealthCredentialsRes,
    successMsg: successMsg,
  };
};

export const setDrTeleHealthCredentialsErrorAction = (
  setDrTeleHealthCredentialsRes: CreateInstantMeetingValue,
  errMsg: string,
  status: number,
): CreateMeetingActionTypes => {
  return {
    type: SET_CREATE_MEETING_FAIL,
    payload: setDrTeleHealthCredentialsRes,
    errorMsg: errMsg,
    status: status,
  };
};
export const setDrTeleHealthCredentialsAPIMsgAction = (
  setDrTeleHealthCredentialsRes: CreateInstantMeetingValue,
  successMsg: string,
  errMsg: string,
  status: number,
): CreateMeetingActionTypes => {
  return {
    type: SET_CREATE_MEETING_API_MSG,
    payload: setDrTeleHealthCredentialsRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const setDrTeleHealthCredentialsAPIResClearAction = (
  setDrTeleHealthCredentialsRes: CreateInstantMeetingValue,
  successMsg: string,
  errMsg: string,
  status: number,
): CreateMeetingActionTypes => {
  return {
    type: SET_CREATE_MEETING_API_RES,
    payload: setDrTeleHealthCredentialsRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
