import {
  SET_SCHEDULE_MEETING_FAIL,
  SET_SCHEDULE_MEETING_LOADING,
  SET_SCHEDULE_MEETING_SUCCESS,
  SET_SCHEDULE_MEETING_API_MSG,
  SET_SCHEDULE_MEETING_API_RES,
  ScheduleMeetingActionTypes,
} from "./ActionTypes";
import { CreateScheduleMeetingValue } from "./Model";

export const setDrTeleHealthCredentialsLoadingAction = (
  loading: boolean,
): ScheduleMeetingActionTypes => {
  return {
    type: SET_SCHEDULE_MEETING_LOADING,
    loading: loading,
  };
};

export const setDrTeleHealthCredentialsSuccessAction = (
  setDrTeleHealthCredentialsRes: CreateScheduleMeetingValue,
  successMsg: string,
): ScheduleMeetingActionTypes => {
  return {
    type: SET_SCHEDULE_MEETING_SUCCESS,
    payload: setDrTeleHealthCredentialsRes,
    successMsg: successMsg,
  };
};

export const setDrTeleHealthCredentialsErrorAction = (
  setDrTeleHealthCredentialsRes: CreateScheduleMeetingValue,
  errMsg: string,
  status: number,
): ScheduleMeetingActionTypes => {
  return {
    type: SET_SCHEDULE_MEETING_FAIL,
    payload: setDrTeleHealthCredentialsRes,
    errorMsg: errMsg,
    status: status,
  };
};
export const setDrTeleHealthCredentialsAPIMsgAction = (
  setDrTeleHealthCredentialsRes: CreateScheduleMeetingValue,
  successMsg: string,
  errMsg: string,
  status: number,
): ScheduleMeetingActionTypes => {
  return {
    type: SET_SCHEDULE_MEETING_API_MSG,
    payload: setDrTeleHealthCredentialsRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const setDrTeleHealthCredentialsAPIResClearAction = (
  setDrTeleHealthCredentialsRes: CreateScheduleMeetingValue,
  successMsg: string,
  errMsg: string,
  status: number,
): ScheduleMeetingActionTypes => {
  return {
    type: SET_SCHEDULE_MEETING_API_RES,
    payload: setDrTeleHealthCredentialsRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
