import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertColor,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Transition } from "components/MWDialogTransition";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import { Result } from "redux/UserProfile/Agreement/AgreementTypeList/Model";
import {
  clearGenerateDoctorAgreementListAPIRes,
  getAgreementTypeList,
} from "redux/UserProfile/Agreement/GenerateAgreement/API";
import { GenerateAgreementBody } from "redux/UserProfile/Agreement/GenerateAgreement/Model";
import { getAgreement } from "redux/UserProfile/Agreement/AgreementList/API";
import { ModifiedAgreementTypeList } from "./Model";
import { GetDoctorDetailsRes } from "redux/jwtDoctorLogIn/doctorLoginDetails/Model";
import MWLoader from "components/MWLoader";

export interface Props {
  dialogEnable?: boolean | any;
  dialogUpdateState: boolean | any;
}
export default function AddAgreement({ dialogEnable, dialogUpdateState }: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation(["home"]);

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [activeResetPasswordDialog, setActiveResetPasswordDialog] = useState(false);

  const closeDialog = () => {
    setActiveResetPasswordDialog(false);
    dialogUpdateState(false);
    setSelectedAgreement(null);
    setSelectAgreementError("");
    dispatch(clearGenerateDoctorAgreementListAPIRes());
    setGenerateAgreementMsg("");
  };
  useEffect(() => {
    setActiveResetPasswordDialog(dialogEnable);
  }, [dialogEnable]);

  let userDetails = JSON.parse(localStorage.getItem("loginDetails")!) as GetDoctorDetailsRes;

  const getAgreementTypeListRes = useSelector((state: AppState) => state.getAgreementTypeListRes);
  const [agreementTypeList, setAgreementTypeList] = useState([] as ModifiedAgreementTypeList[]);
  useEffect(() => {
    if (getAgreementTypeListRes?.getDoctorAgreementTypeListRes?.results !== undefined) {
      if (getAgreementTypeListRes?.getDoctorAgreementTypeListRes?.results.length > 0) {
        let agreementTypeListItem =
          getAgreementTypeListRes?.getDoctorAgreementTypeListRes?.results.map(
            (item: Result) =>
              ({
                label: item?.name,
                value: item?.id,
              } as ModifiedAgreementTypeList),
          );
        setAgreementTypeList(agreementTypeListItem);
      } else setAgreementTypeList([] as ModifiedAgreementTypeList[]);
    } else setAgreementTypeList([] as ModifiedAgreementTypeList[]);
  }, [getAgreementTypeListRes]);

  const [selectedAgreement, setSelectedAgreement] = useState<ModifiedAgreementTypeList | null>(
    null,
  );
  const selectedAgreementChange = (_event: any, passedVal: ModifiedAgreementTypeList | any) => {
    setSelectedAgreement(passedVal);
  };
  const [selectedAgreementError, setSelectAgreementError] = useState("");

  const generateAgreementBody = {
    onboardReqId: String(userDetails?.onboardingrequestId!),
  }; // as AddWorkExperienceBody;

  const submitEducationalDetails = () => {
    if (selectedAgreement !== null) {
      setSelectAgreementError("");
      dispatch(
        getAgreementTypeList(
          Object.assign(generateAgreementBody, {
            templateId: selectedAgreement?.value !== null ? String(selectedAgreement.value) : null,
          } as GenerateAgreementBody),
        ),
      );
    } else {
      setSelectAgreementError(`${t("userprofilepage.selagreementError", { ns: ["home"] })!}`);
    }
  };

  const generateAgreementRes = useSelector((state: AppState) => state.generateAgreementRes);
  const [generateAgreementMsg, setGenerateAgreementMsg] = useState("");
  const [generateAgreementMsgType, setGenerateAgreementMsgType] = useState<AlertColor>("success");

  useEffect(() => {
    if (generateAgreementRes?.successMsg !== "") {
      setGenerateAgreementMsgType("success");
      setGenerateAgreementMsg(generateAgreementRes?.successMsg);
      dispatch(getAgreement());
      closeDialog();
    }
    if (generateAgreementRes?.errorMsg !== "") {
      setGenerateAgreementMsgType("error");
      setGenerateAgreementMsg(generateAgreementRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateAgreementRes]);

  return (
    <Dialog
      open={activeResetPasswordDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeDialog}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
      fullScreen={fullScreen}
      fullWidth
    >
      {generateAgreementRes?.loading ? <MWLoader /> : null}
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <Typography variant="h5">
              {t("userprofilepage.addagreement", { ns: ["home"] })}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={() => closeDialog()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-between"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography variant="body1" style={{ marginBottom: "5px" }}>
                {t("userprofilepage.selagreement", { ns: ["home"] })!}
              </Typography>
              <Autocomplete
                value={selectedAgreement}
                id="highest-states"
                options={agreementTypeList}
                onChange={(event: any, newValue: any) => {
                  selectedAgreementChange(event, newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={t("userprofilepage.selDegName", { ns: ["home"] })!}
                    fullWidth
                  />
                )}
                size="small"
                getOptionLabel={(option: any) => option?.label! || ""}
                isOptionEqualToValue={(option: any, value) => option.value === value.value}
              />
              {selectedAgreementError !== "" ? (
                <Typography style={{ marginBottom: "5px", color: "#d32f2f" }}>
                  {selectedAgreementError}
                </Typography>
              ) : null}
            </Grid>
          </Grid>
        </Stack>
        {generateAgreementMsg !== "" ? (
          <Box my={2}>
            <Alert
              severity={generateAgreementMsgType}
              onClose={() => {
                dispatch(clearGenerateDoctorAgreementListAPIRes());
                setGenerateAgreementMsg("");
              }}
            >
              {generateAgreementMsg}
            </Alert>
          </Box>
        ) : null}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={2}>
          <Grid item>
            <Button variant="outlined" onClick={() => closeDialog()} sx={{ textTransform: "none" }}>
              {t("accountdetail.cancel", { ns: ["home"] })}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => submitEducationalDetails()}
              sx={{ textTransform: "none" }}
              disabled={generateAgreementRes?.loading}
            >
              {t("userprofilepage.generateagree", { ns: ["home"] })}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
