import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { Box, Button, Card, CardContent, CardHeader, Grid, IconButton } from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";
import RefreshIcon from "@mui/icons-material/Refresh";
import EditIcon from "@mui/icons-material/Edit";
import MWExceptionList from "components/MWExceptionList";
import dayjs from "dayjs";
import { t } from "i18next";
import { AppState } from "redux/store/Store";
import { prescriptionListApi } from "../../../redux/FinalOpinion/PrescriptionTable/GetPrescriptionList/API";
import { ModifiedPrescriptionList } from "./Model";
import {
  GetPrescriptionListBody,
  PrescriptionDetail,
} from "redux/FinalOpinion/PrescriptionTable/GetPrescriptionList/Model";
import DocumentViewer from "components/DocumentViewer";
import AddPrescription from "../AddPrescription";

type Props = {
  caseId?: string | any;
};

export default function PrescriptionTable({ caseId }: Props) {
  const dispatch = useDispatch();
  const [prescriptionId, setPrescriptionId] = useState("");
  // Initiate get Prescription List API on page render
  useEffect(() => {
    dispatch(prescriptionListApi({ caseId: caseId } as GetPrescriptionListBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Call getPrescriptionList store
  const prescriptionListRes = useSelector((state: AppState) => state.prescriptionListRes);
  const [prescriptionList, setPrescriptionList] = useState([] as ModifiedPrescriptionList[]);

  useEffect(() => {
    if (prescriptionListRes?.getPrescriptionListRes?.prescriptionDetails !== undefined) {
      if (prescriptionListRes?.getPrescriptionListRes?.prescriptionDetails.length > 0) {
        let prescriptionListItem =
          prescriptionListRes?.getPrescriptionListRes?.prescriptionDetails.map(
            (element: PrescriptionDetail) => ({
              id: element.id,
              supportingDocument:
                element?.prescriptionFile !== undefined
                  ? element?.prescriptionFile !== null
                    ? element?.prescriptionFile?.document !== undefined
                      ? element?.prescriptionFile?.document
                      : null
                    : null
                  : null,
              // description: element?.,
              prescribedDate:
                element?.createDate !== undefined
                  ? dayjs(element.createDate).format("DD-MM-YYYY")
                  : "Not Set",
            }),
          );
        setPrescriptionList(prescriptionListItem);
      } else {
        setPrescriptionList([] as ModifiedPrescriptionList[]);
      }
    } else {
      setPrescriptionList([] as ModifiedPrescriptionList[]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prescriptionListRes]);

  const columns: GridColDef[] = [
    {
      field: "document",
      headerName: `${t("appointmentpage.doc", { ns: ["home"] })!}`,
      width: 80,
      align: "left",
      renderCell: (params: GridCellParams) => (
        <Button
          onClick={() => {
            setViewPrescriptionDocumentDialog(true);
            setEditItem(params.row);
          }}
          variant="outlined"
          sx={{ textTransform: "none" }}
        >
          <ArticleIcon />
        </Button>
      ),
    },
    {
      field: "prescribDate",
      headerName: `${t("filePrepPage.preDate", { ns: ["home"] })!}`,
      type: "string",
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "id",
      headerName: `${t("userprofilepage.edit", { ns: ["home"] })!}`,
      minWidth: 60,
      align: "left",
      renderCell: (params: GridCellParams) => (
        <Button
          onClick={() => {
            setViewEditPrescriptionDialog(true);
            setPrescriptionId(String(params.row.id));
          }}
          variant="outlined"
          sx={{ textTransform: "none" }}
        >
          <EditIcon />
        </Button>
      ),
    },
  ];
  const [viewPrescriptionDocumentDialog, setViewPrescriptionDocumentDialog] = useState(false);
  const updateViewPrescriptionDocumentDialogFromChild = (passedVal: boolean) => {
    setViewPrescriptionDocumentDialog(passedVal);
  };

  const [editItem, setEditItem] = useState({} as any);
  const selectedItemUpdateState = (passedVal: any) => {
    setEditItem(passedVal);
  };

  const [viewEditPrescriptionDialog, setViewEditPrescriptionDialog] = useState(false);
  const updateViewEditPrescriptionDialogFromChild = (passedVal: boolean) => {
    setViewEditPrescriptionDialog(passedVal);
    if (!passedVal) {
      setPrescriptionId("");
    }
  };

  return (
    <Box>
      <Card>
        <CardHeader
          title={
            <Grid item container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>{t("filePrepPage.preTable", { ns: ["home"] })!}</Grid>
                  <Grid item>
                    <IconButton
                      onClick={() =>
                        dispatch(
                          prescriptionListApi({
                            caseId: caseId,
                          } as GetPrescriptionListBody),
                        )
                      }
                    >
                      <RefreshIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
        ></CardHeader>
        <CardContent>
          {prescriptionList.length > 0 ? (
            <DataGrid
              rows={prescriptionList}
              columns={columns}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )}
        </CardContent>
      </Card>
      {viewPrescriptionDocumentDialog ? (
        <DocumentViewer
          dialogEnable={viewPrescriptionDocumentDialog}
          dialogUpdateState={updateViewPrescriptionDocumentDialogFromChild}
          selectedItem={editItem}
          selectedItemUpdateState={selectedItemUpdateState}
        />
      ) : null}
      {viewEditPrescriptionDialog ? (
        <AddPrescription
          itemId={caseId}
          prescriptionId={prescriptionId}
          addPrescriptionDialogEnable={viewEditPrescriptionDialog}
          addPrescriptionDialogUpdateState={updateViewEditPrescriptionDialogFromChild}
        />
      ) : null}
    </Box>
  );
}
