import {
  CreateMeetingActionTypes,
  SET_CREATE_MEETING_FAIL,
  SET_CREATE_MEETING_LOADING,
  SET_CREATE_MEETING_SUCCESS,
  CreateMeetingState,
  SET_CREATE_MEETING_API_MSG,
  SET_CREATE_MEETING_API_RES,
} from "./ActionTypes";
import { CreateInstantMeetingValue } from "./Model";

const initialStateEditPosts: CreateMeetingState = {
  loading: false,
  setCreateMeetingRes: {} as CreateInstantMeetingValue,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const createInstantMeetingReducer = (
  state = initialStateEditPosts,
  action: CreateMeetingActionTypes,
): CreateMeetingState => {
  switch (action.type) {
    case SET_CREATE_MEETING_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case SET_CREATE_MEETING_SUCCESS:
      return {
        ...state,
        setCreateMeetingRes: action.payload,
        successMsg: action.successMsg,
      };
    case SET_CREATE_MEETING_FAIL:
      return {
        ...state,
        setCreateMeetingRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case SET_CREATE_MEETING_API_MSG:
      return {
        ...state,
        setCreateMeetingRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case SET_CREATE_MEETING_API_RES:
      return {
        ...state,
        setCreateMeetingRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
