import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/store/Store";
import MWTextField from "../../../../components/MWTextField";
import MWLoader from "../../../../components/MWLoader";
import CloseIcon from "@mui/icons-material/Close";
import {
  clearAddSummaryListAPIRes,
  filePeparationDoctorAddSummarySubmit,
  updateAddSummaryListAPIResMsg,
} from "redux/FilePreparation/doctorSummary/addSummaryList/ApiCall";
import { addSummaryListSubmitBody } from "redux/FilePreparation/doctorSummary/addSummaryList/Model";
import { GetSummeryListSubmitBody } from "redux/FilePreparation/doctorSummary/getSummaryList/Model";
import { getDoctorSummaryList } from "redux/FilePreparation/doctorSummary/getSummaryList/ApiCall";
import { GetDoctorDetailsRes } from "redux/jwtDoctorLogIn/doctorLoginDetails/Model";
import { t } from "i18next";

interface Props {
  dialogEnable?: boolean | any;
  dialogUpdateState: boolean | any;
  docTypeName?: string;
  itemId?: number | any;
  type?: string | any;
  selectedItem?: any;
  selectedItemUpdateState: any;
}

export default function AddSummaryData({
  dialogEnable,
  dialogUpdateState,
  docTypeName,
  type,
  selectedItem,
  selectedItemUpdateState,
}: Props) {
  const dispatch = useDispatch();

  let userDetails = JSON.parse(localStorage.getItem("loginDetails")!) as GetDoctorDetailsRes;

  const DoctorID = userDetails !== undefined ? userDetails?.doctorId : null;
  const { caseid } = useParams() as {
    caseid: string;
  };
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleClose = () => {
    setDialogOpen(false);
    dialogUpdateState(false);
    setHealthProblem("");
    setHealthProblemError("");
    dispatch(updateAddSummaryListAPIResMsg());
    dispatch(clearAddSummaryListAPIRes());
    selectedItemUpdateState({} as any);
  };
  const [healthProblem, setHealthProblem] = useState("");

  const [fieldDisabled, setFieldDisabled] = useState(false);
  // enable dialog
  useEffect(() => {
    dialogEnable !== undefined ? setDialogOpen(dialogEnable) : setDialogOpen(false);
  }, [dialogEnable]);
  // Set Selected item in the corresponding filed which are passed from the table
  useEffect(() => {
    "id" in selectedItem ? setFieldDisabled(true) : setFieldDisabled(false);
    selectedItem !== undefined
      ? selectedItem.summaryText !== ""
        ? setHealthProblem(selectedItem.summaryText)
        : setHealthProblem("")
      : setHealthProblem("");
  }, [selectedItem]);
  const [healthProblemError, setHealthProblemError] = useState("");

  const addDoctorSummaryDataBody = {
    doctorId: String(DoctorID),
    caseId: caseid,
    secondOpinionUiSection: type,
    summaryText: healthProblem,
  } as addSummaryListSubmitBody;

  const submitPatientData = () => {
    if (healthProblem === "") {
      setHealthProblemError(`${t("common.pleaseSummaryText", { ns: ["home"] })!}`);
    } else {
      setHealthProblemError("");
      dispatch(filePeparationDoctorAddSummarySubmit(addDoctorSummaryDataBody));
    }
  };
  const addDoctorSummaryResValue = useSelector((state: AppState) => state.addDoctorSummaryList);

  const [addSummaryMsgType, setAddSummaryMsgType] = useState<AlertColor>("success");
  const [addPatientDataErrorMsg, setDeletePatientDataErrorMsg] = useState("");
  const getDoctorSummaryDetails = {
    caseId: caseid,
    secondOpinionUiSection: type,
  } as GetSummeryListSubmitBody;
  useEffect(() => {
    if (addDoctorSummaryResValue?.addSummaryListResponse?.message !== undefined) {
      dispatch(getDoctorSummaryList(getDoctorSummaryDetails));
      handleClose();
    } else if (addDoctorSummaryResValue?.errorMsg !== "") {
      setDeletePatientDataErrorMsg(addDoctorSummaryResValue?.errorMsg);
      setAddSummaryMsgType("error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addDoctorSummaryResValue]);

  return (
    <Dialog open={dialogOpen} onClose={handleClose} fullWidth maxWidth="lg">
      {addDoctorSummaryResValue?.loading ? <MWLoader /> : null}
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item>
            {!fieldDisabled
              ? `${t("common.add", { ns: ["home"] })}`
              : `${t("common.view", { ns: ["home"] })}`}{" "}
            {t("common.dataFor", { ns: ["home"] })}{" "}
            {t(`filePreparationTab.${docTypeName}`, { ns: ["home"] })}{" "}
            {t("common.details", { ns: ["home"] })}
          </Grid>
          <Grid item>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <Typography style={{ marginBottom: "5px" }}>
              {!fieldDisabled
                ? `${t("common.add", { ns: ["home"] })}`
                : `${t("common.view", { ns: ["home"] })}`}{" "}
              {t(`filePreparationTab.${docTypeName}`, { ns: ["home"] })}{" "}
              {t("common.details", { ns: ["home"] })}
            </Typography>
            <MWTextField
              placeholder={`${t("common.pleaseEnter", {
                ns: ["home"],
              })} ${t(`filePreparationTab.${docTypeName}`, {
                ns: ["home"],
              })} ${t("common.summaryText", { ns: ["home"] })}`}
              value={healthProblem}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setHealthProblem(event.target.value)
              }
              disabled={fieldDisabled}
              multiline
              rows={8}
              fullWidth
            />
            {healthProblemError !== "" ? (
              <Typography sx={{ color: "#c62828" }}>{healthProblemError}</Typography>
            ) : null}
          </Grid>
        </Grid>
        {addPatientDataErrorMsg !== "" ? (
          <Box py={2}>
            <Alert
              severity={addSummaryMsgType}
              onClose={() => dispatch(updateAddSummaryListAPIResMsg())}
            >
              {addPatientDataErrorMsg}
            </Alert>
          </Box>
        ) : null}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant={fieldDisabled ? "contained" : "outlined"}
          onClick={() => handleClose()}
          sx={{ textTransform: "none" }}
        >
          {fieldDisabled
            ? `${t("appointmentpage.close", { ns: ["home"] })}`
            : `${t("accountdetail.cancel", { ns: ["home"] })}`}
        </Button>
        {!fieldDisabled ? (
          <Button
            variant="contained"
            onClick={() => submitPatientData()}
            sx={{ textTransform: "none" }}
          >
            {t("Workschedulepage.submit", { ns: ["home"] })}
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
}
