import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {
  Alert,
  AlertColor,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Transition } from "components/MWDialogTransition";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { t } from "i18next";
import MWUploader from "components/MWUploader";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { getChiefComplaintList } from "redux/FilePreparation/ApiCall";
import { AppState } from "redux/store/Store";
import {
  addPatientData,
  clearAddPatientDataAPIRes,
} from "redux/FilePreparation/AddPatientDataList/API";
import { addPatientDataBody } from "redux/FilePreparation/AddPatientDataList/Model";
import { getFinalOpinionDetails } from "../../../redux/FinalOpinion/FinalOpinionTable/GetFinalOpinionList/API";
import MWLoader from "components/MWLoader";

type Props = {
  itemId?: string | any;
  patientId?: string | any;
  addFinalOpinionDetailsEnable?: boolean | any;
  addFinalOpinionDetailsDialogUpdateState?: boolean | any;
};

export default function AddFinalOpinionDetails({
  itemId,
  addFinalOpinionDetailsEnable,
  addFinalOpinionDetailsDialogUpdateState,
  patientId,
}: Props) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [dialogState, setDialogState] = useState(false);
  const closeDialog = () => {
    addFinalOpinionDetailsDialogUpdateState(false);
    setDialogState(false);
    setDescription("");
    setImageId("");
    setSelectedDate(dayjs(new Date()));
    setSelectedToDate(null);
    dispatch(clearAddPatientDataAPIRes());
    setAddDrSignatureMsg("");
  };

  const [description, setDescription] = useState("");

  // from Date
  const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(dayjs(new Date()));
  const handleDateChange = (date: Dayjs | null) => {
    setSelectedDate(date);
  };

  // to Date
  const [toDate, setSelectedToDate] = React.useState<Dayjs | null>(null);
  const handleToDateChange = (date: Dayjs | null) => {
    setSelectedToDate(date);
  };
  const [startEndDateError, setStartEndDateError] = useState("");
  useEffect(() => {
    setDialogState(addFinalOpinionDetailsEnable);
  }, [addFinalOpinionDetailsEnable, dialogState]);

  const getSecondOpinionBody = {
    caseId: itemId,
    secondOpinionUiSection: "finalOpinion",
  };
  // upload image
  const [imageId, setImageId] = useState("");
  const [imageInfo] = useState("");
  const [, setDelImg] = useState(false);
  // delete selected image from image uploader
  const delImgAction = (passeVal: boolean) => {
    setDelImg(passeVal);
  };
  const [, setCheckImgUpload] = useState(false);
  // Get PreviewImg Details
  const viewPreviewImageData = (passedVal: any) => {
    setCheckImgUpload(passedVal);
  };
  const updateDataStateFromChild = async (selectedImgId: any) => {
    setImageId(selectedImgId);
  };

  const [finalOpinionDescriptionError, setFinalOpinionDescriptionError] = useState("");

  // Final Opinion Body
  const secondOpinionBody = {
    patientId: patientId,
    caseId: itemId,
    secondOpinionUiSection: "finalOpinion",
    description: description,
    patientDocumentId: imageId !== "" ? Number(imageId) : null,
    startDate:
      selectedDate !== null ? dayjs(selectedDate).utc().format("YYYY-MM-DDTHH:mm:ssZ") : null,
    endDate: toDate !== null ? dayjs(toDate).utc().format("YYYY-MM-DDTHH:mm:ssZ") : null,
  } as addPatientDataBody;

  // Final Opinion Submit
  const secondOpinionSubmit = () => {
    if (description === "") {
      setFinalOpinionDescriptionError(`${t("common.plsAddDesc", { ns: ["home"] })!}`);
    } else if ((new Date(String(selectedDate)) > new Date(String(toDate)) || (new Date(String(toDate)) < new Date(String(selectedDate))))) {
      setStartEndDateError(`${t("appointmentpage.datevalid", { ns: ["home"] })!}`);
    }else {
      setFinalOpinionDescriptionError("");
      dispatch(addPatientData(secondOpinionBody));
    }
  };

  // Call Add final opinion store
  const addPatientDataListRes = useSelector((state: AppState) => state.addPatientDataListRes);
  const [addDrSignatureMsg, setAddDrSignatureMsg] = useState("");
  const [addDrSignatureMsgType, setAddDrSignatureMsgType] = useState<AlertColor>("success");

  useEffect(() => {
    if (addPatientDataListRes?.successMsg !== "") {
      dispatch(getChiefComplaintList(getSecondOpinionBody));
      dispatch(getFinalOpinionDetails(getSecondOpinionBody));
      setAddDrSignatureMsgType("success");
      setAddDrSignatureMsg(addPatientDataListRes?.successMsg);
      closeDialog();
    }
    if (addPatientDataListRes?.errorMsg !== "") {
      setAddDrSignatureMsgType("error");
      setAddDrSignatureMsg(addPatientDataListRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPatientDataListRes]);

  return (
    <Dialog
      open={dialogState}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeDialog}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
      fullScreen={fullScreen}
      fullWidth
    >
      {addPatientDataListRes?.loading ? <MWLoader /> : null}
      <DialogTitle id="customized-dialog-title">
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>{t("appointmentpage.finalOpDoc", { ns: ["home"] })!}</Grid>
          <Grid item>
            <IconButton aria-label="close" onClick={() => closeDialog()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-between"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography style={{ marginBottom: "5px" }}>
                {t("appointmentpage.desc", { ns: ["home"] })!}
              </Typography>
              <TextField
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                label={t("appointmentpage.finalOpDesc", { ns: ["home"] })!}
                type="text"
                placeholder={t("appointmentpage.enterfinalOpDesc", { ns: ["home"] })!}
                id="finalOpinionDescriptionID"
                fullWidth
                multiline
                rows={4}
              />
              {finalOpinionDescriptionError !== "" ? (
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <ErrorOutlineIcon style={{ color: "#d32f2f" }} />
                  </Grid>
                  <Grid item>
                    <Typography variant="body1" style={{ color: "#d32f2f" }}>
                      {finalOpinionDescriptionError}
                    </Typography>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <MWUploader
                documentType="patient-image"
                criteria={t("userprofilepage.awarddocsize", { ns: ["home"] })!}
                updatedDataState={updateDataStateFromChild}
                title={t("userprofilepage.uploadsuppdoc", { ns: ["home"] })!}
                visibility={false}
                imageUrl={imageInfo}
                deleteImgAction={delImgAction}
                previewImageData={viewPreviewImageData}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography>{t("userprofilepage.startdate", { ns: ["home"] })!}</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="DD/MM/YYYY"
                  value={selectedDate}
                  onChange={handleDateChange}
                  views={["year", "month", "day"]}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      fullWidth
                      inputProps={{ ...params.inputProps }}
                      size="small"
                      variant="outlined"
                    />
                  )}
                  InputAdornmentProps={{ position: "start" }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography>{t("appointmentpage.enddate", { ns: ["home"] })!}</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="DD/MM/YYYY"
                  value={toDate}
                  onChange={handleToDateChange}
                  views={["year", "month", "day"]}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      fullWidth
                      inputProps={{ ...params.inputProps }}
                      size="small"
                      variant="outlined"
                    />
                  )}
                  InputAdornmentProps={{ position: "start" }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          {startEndDateError !== "" ? (
                <Typography style={{ marginBottom: "5px", color: "#d32f2f", alignItems:"center", marginLeft:"20px" }}>
                  {startEndDateError}
                </Typography>
              ) : null}
        </Stack>
        {addDrSignatureMsg !== "" ? (
          <Box my={2}>
            <Alert
              severity={addDrSignatureMsgType}
              onClose={() => {
                dispatch(clearAddPatientDataAPIRes());
                setAddDrSignatureMsg("");
              }}
            >
              {addDrSignatureMsg}
            </Alert>
          </Box>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={2}>
          <Grid item>
            <Button variant="outlined" onClick={() => closeDialog()} sx={{ textTransform: "none" }}>
              {t("accountdetail.cancel", { ns: ["home"] })}
            </Button>
          </Grid>
          <Grid item>
            <Button
              autoFocus
              variant="contained"
              color="primary"
              onClick={secondOpinionSubmit}
              sx={{ textTransform: "none" }}
            >
              {t("Workschedulepage.submit", { ns: ["home"] })!}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
