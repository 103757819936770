import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardHeader,
  Grid,
  CardContent,
  AlertColor,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import { t } from "i18next";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import ArticleIcon from "@mui/icons-material/Article";
import { AgreementDetail } from "redux/UserProfile/Agreement/AgreementList/Model";
import { ModifiedAgreementList } from "./Model";
import DocumentViewer from "components/DocumentViewer";
import { getAgreement } from "redux/UserProfile/Agreement/AgreementList/API";
import { getAgreementTypeList } from "redux/UserProfile/Agreement/AgreementTypeList/API";
import AddAgreement from "./AddAgreement";
import { GetDoctorDetailsRes } from "redux/jwtDoctorLogIn/doctorLoginDetails/Model";
import {
  clearSignDoctorAgreementListAPIRes,
  signAgreementList,
} from "redux/UserProfile/Agreement/SignAgrementApi/API";
import MWSnackbar from "components/MWSnackbar";

export interface Props {
  agreementLoading: boolean | any;
}

export default function Agreement({ agreementLoading }: Props) {
  const dispatch = useDispatch();
  let userDetails = JSON.parse(localStorage.getItem("loginDetails")!) as GetDoctorDetailsRes;
  useEffect(() => {
    dispatch(getAgreement());
    dispatch(getAgreementTypeList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [agreementList, setAgreementList] = useState([] as ModifiedAgreementList[]);

  const agreementTableColumns: GridColDef[] = [
    {
      field: "supportingDocument",
      headerName: `${t("appointmentpage.doc", { ns: ["home"] })}`,
      minWidth: 80,
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<ArticleIcon />}
          variant="outlined"
          disabled={params.row.supportingDocument === null ? true : false}
          onClick={() => {
            setViewLicenseDocumentDialog(true);
            setEditItem(params.row);
          }}
          sx={{ textTransform: "none" }}
        >
          {
            t("common.view", {
              ns: ["home"],
            })!
          }
        </Button>
      ),
    },
    {
      field: "name",
      headerName: `${t("appointmentpage.name", { ns: ["home"] })}`,
      minWidth: 200,
      flex: 1,
    },
  ];

  // call agreement list store
  const getAgreementRes = useSelector((state: AppState) => state.getAgreementRes);

  useEffect(() => {
    agreementLoading(getAgreementRes?.loading);
    if (getAgreementRes?.getDoctorAgreementRes?.agreementDetails !== undefined) {
      let agreementListItem = getAgreementRes?.getDoctorAgreementRes?.agreementDetails.map(
        (item: AgreementDetail) =>
          ({
            id: item?.id,
            name: item?.template?.name !== undefined ? item?.template?.name : "",
            supportingDocument:
              item?.agreementFile?.document !== undefined ? item?.agreementFile?.document : null,
          } as ModifiedAgreementList),
      );
      setAgreementList(agreementListItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAgreementRes]);

  const [editItem, setEditItem] = useState({} as ModifiedAgreementList);
  const selectedItemUpdateState = (passedVal: any) => {
    setEditItem(passedVal);
  };

  const [addAgreementDialog, setAddAgreementDialog] = useState(false);
  const updateAddAgreementDialogValFromChild = (passedVal: boolean) => {
    setAddAgreementDialog(passedVal);
  };
  const [viewLicenseDocumentDialog, setViewLicenseDocumentDialog] = useState(false);
  const updateViewLicenseDocumentDialogDialogValFromChild = (passedVal: boolean) => {
    setViewLicenseDocumentDialog(passedVal);
  };

  // call sign agreement list store
  const signAgreementRes = useSelector((state: AppState) => state.signAgreementRes);

  const [signAgreementMsg, setSignAgreementMsg] = useState("");
  const [signAgreementMsgType, setSignAgreementMsgType] = useState<AlertColor>("success");

  useEffect(() => {
    agreementLoading(signAgreementRes?.loading);
    if (signAgreementRes?.successMsg !== "") {
      setSignAgreementMsgType("success");
      setSignAgreementMsg(signAgreementRes?.successMsg);
      dispatch(getAgreement());
    }
    if (signAgreementRes?.errorMsg !== "") {
      setSignAgreementMsgType("error");
      setSignAgreementMsg(signAgreementRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signAgreementRes]);

  const checkAlertClose = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearSignDoctorAgreementListAPIRes());
      setSignAgreementMsg("");
    }
  };

  return (
    <Box>
      <Card>
        <CardHeader
          title={
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="subtitle1" fontWeight="bold">
                  {t("userprofilepage.addagreelist", { ns: ["home"] })}
                </Typography>
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <Button
                      onClick={() => setAddAgreementDialog(true)}
                      variant="contained"
                      sx={{ textTransform: "none" }}
                    >
                      {t("userprofilepage.addagreedetail", { ns: ["home"] })!}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      sx={{ textTransform: "none" }}
                      disabled={signAgreementRes?.loading}
                      onClick={() =>
                        dispatch(
                          signAgreementList({
                            onboardReqId: String(userDetails?.onboardingrequestId!),
                          }),
                        )
                      }
                    >
                      {t("userprofilepage.signagree", { ns: ["home"] })!}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          {agreementList && agreementList.length ? (
            <DataGrid
              rows={agreementList}
              columns={agreementTableColumns}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              getRowId={(row) => row.id}
            />
          ) : (
            <Typography variant="subtitle1">No data found</Typography>
          )}
        </CardContent>
      </Card>
      {addAgreementDialog ? (
        <AddAgreement
          dialogEnable={addAgreementDialog}
          dialogUpdateState={updateAddAgreementDialogValFromChild}
        />
      ) : null}
      {viewLicenseDocumentDialog ? (
        <DocumentViewer
          dialogEnable={viewLicenseDocumentDialog}
          dialogUpdateState={updateViewLicenseDocumentDialogDialogValFromChild}
          selectedItem={editItem}
          selectedItemUpdateState={selectedItemUpdateState}
        />
      ) : null}
      <MWSnackbar msg={signAgreementMsg} type={signAgreementMsgType} alertClose={checkAlertClose} />
    </Box>
  );
}
