import { DeletePatientCaseRes } from "./Model";
export const DELETE_PATIENT_CASE_LOADING =
  "DELETE_PATIENT_CASE_LOADING";
export const DELETE_PATIENT_CASE_SUCCESS =
  "DELETE_PATIENT_CASE_SUCCESS";
export const DELETE_PATIENT_CASE_FAIL =
  "DELETE_PATIENT_CASE_FAIL";
export const DELETE_PATIENT_CASE_DATA =
  "DELETE_PATIENT_CASE_DATA";
export const DELETE_PATIENT_CASE_UPDATE_API_MSG =
  "DELETE_PATIENT_CASE_UPDATE_API_MSG";
export const DELETE_PATIENT_CASE_UPDATE_API_RES =
  "DELETE_PATIENT_CASE_UPDATE_API_RES";

export interface DeletePatientCaseState {
  deletePatientCaseRes: DeletePatientCaseRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface DeletePatientCaseLoading {
  type: typeof DELETE_PATIENT_CASE_LOADING;
  loading: boolean;
}
export interface DeletePatientCaseSuccess {
  type: typeof DELETE_PATIENT_CASE_SUCCESS;
  payload: DeletePatientCaseRes;
  successMsg: string;
}
export interface DeletePatientCaseFail {
  type: typeof DELETE_PATIENT_CASE_FAIL;
  payload: DeletePatientCaseRes;
  errorMsg: string;
  status: number;
}
export interface DeletePatientCaseUpdateAPIMsg {
  type: typeof DELETE_PATIENT_CASE_UPDATE_API_MSG;
  payload: DeletePatientCaseRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface DeletePatientCaseUpdateAPIRes {
  type: typeof DELETE_PATIENT_CASE_UPDATE_API_RES;
  payload: DeletePatientCaseRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type DeletePatientCaseActionTypes =
  | DeletePatientCaseLoading
  | DeletePatientCaseSuccess
  | DeletePatientCaseFail
  | DeletePatientCaseUpdateAPIMsg
  | DeletePatientCaseUpdateAPIRes;
