import React, { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import { Box, Grid, Link, Typography } from "@mui/material";
import LoginScreen from "../../components/image/featureimage-doctor.png";
import logo from "../../components/image/logo/cloud-doctor-with-caption.png";
import MWSelectLang, { MobileSelectLang } from "../../components/MWSelectLang";
import { t } from "i18next";
import ApiUrl from "../../URL";

interface OnboardingLayoutProps {
  children?: ReactNode;
}
const OnboardingLayout: FC<OnboardingLayoutProps> = ({ children }) => {
  return (
    <Grid container item direction="row" justifyContent="space-between" sx={{ height: "100vh" }}>
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
        <Link underline="none" href={ApiUrl?.baseURL}>
          <img src={logo} alt="Logo" height="72" />
        </Link>
        <Box
          sx={{
            display: { xs: "none", sm: "flex", md: "flex", lg: "flex", xl: "flex" },
          }}
        >
          <MWSelectLang />
        </Box>
        <Box
          sx={{
            display: {
              xs: "flex",
              sm: "flex",
              md: "none",
              lg: "none",
              xl: "none",
            },
          }}
        >
          <MobileSelectLang />
        </Box>
      </Grid>
      <Grid xs={12} container item>
        <Grid
          md={6}
          container
          item
          alignItems="center"
          justifyContent="center"
          sx={{
            display: { xs: "none", md: "block" },
          }}
        >
          <Box>
            <img
              src={LoginScreen}
              alt=""
              width="100%"
              height="100%"
              style={{
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
          </Box>
        </Grid>
        <Grid xs={12} md={6} container item alignItems="center" justifyContent="center">
          <Grid container item alignItems="center" justifyContent="center">
            <Grid container item xs={11} sm={7} lg={7}>
              <Box>
                <Typography color="textPrimary" variant="h5" display="inline">
                  <b>{t("home.welcome", { ns: ["home"] })} Cloud Doctor</b>
                </Typography>
                <Typography variant="subtitle2">{t("home.subtitle", { ns: ["home"] })}</Typography>
              </Box>
              {children}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item direction="column" justifyContent="center" xs={12}>
        <Grid container item direction="row" justifyContent="space-between" alignItems="flex-start">
          <Typography variant="caption">© {t("common.copyright", { ns: ["home"] })}</Typography>
          <Typography variant="caption">
            <Link
              underline="none"
              href={`${ApiUrl?.baseURL}/termsofuse/`}
              target="_blank"
              rel="noreferrer"
            >
              {t("home.termofuse", { ns: ["home"] })}
            </Link>
            &nbsp;&nbsp;
            <Link
              underline="none"
              href={`${ApiUrl?.baseURL}/privacystatement/`}
              target="_blank"
              rel="noreferrer"
            >
              {t("home.privacypolicy", { ns: ["home"] })}
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

OnboardingLayout.propTypes = {
  children: PropTypes.node,
};

export default OnboardingLayout;
