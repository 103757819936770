import React from "react";
import { Stack } from "@mui/material";
import FinalOpinionTable from "./FinalOpinionTable";
import { useParams } from "react-router-dom";
import PrescriptionTable from "./PrescriptionTable";

export default function FinalOpinion() {
  const { caseid } = useParams() as {
    caseid: string;
  };
  const { patientid } = useParams() as {
    patientid: string;
  };
  return (
    <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2} my={2}>
      <FinalOpinionTable caseId={caseid} patientId={patientid} />
      <PrescriptionTable caseId={caseid} />
    </Stack>
  );
}
