import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import { Box, Button, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import ArticleIcon from "@mui/icons-material/Article";
import EditIcon from "@mui/icons-material/Edit";
import MWExceptionList from "components/MWExceptionList";
import { getDoctorEducationDetails } from "redux/GetEducationList/API";
import { DegreeList } from "redux/GetEducationList/Model";
import { getDoctorDegreeList } from "redux/DegreeListDropdown/API";
import { AppState } from "redux/store/Store";
import dayjs from "dayjs";
import { ModifiedDegreeList } from "./Model";
import AddDoctorEducationDetails from "./AddDoctorEducationDetails";
import DocumentViewer from "components/DocumentViewer";
export interface Props {
  educationListLoading: boolean | any;
}
export default function DoctorEducationalPage({ educationListLoading }: Props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDoctorEducationDetails());
    dispatch(getDoctorDegreeList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [doctorEducationDetails, setDoctorEducationDetails] = useState([] as ModifiedDegreeList[]);
  const getDoctorEducationDetailsRes = useSelector(
    (state: AppState) => state.getDoctorEducationDetailsRes,
  );
  useEffect(() => {
    educationListLoading(getDoctorEducationDetailsRes?.loading);
    if (getDoctorEducationDetailsRes?.getDoctorEducationDetailsRes?.degreeList !== undefined) {
      if (getDoctorEducationDetailsRes?.getDoctorEducationDetailsRes?.degreeList.length > 0) {
        let degreeListItem =
          getDoctorEducationDetailsRes?.getDoctorEducationDetailsRes?.degreeList.map(
            (element: DegreeList) => ({
              id: element?.id,
              name: element?.name,
              degree:
                element?.degree?.displayValue !== undefined
                  ? element?.degree?.displayValue
                  : "Not Set",
              degreeVal: element?.degree?.id !== undefined ? element?.degree?.id : 0,
              description: element?.description !== undefined ? element?.description : "",
              fromDate:
                element?.fromDate !== undefined
                  ? dayjs(element?.fromDate).format("DD-MM-YYYY")
                  : "Not Set",
              toDate:
                element?.toDate !== undefined
                  ? dayjs(element?.toDate).format("DD-MM-YYYY")
                  : "Not Set",
              supportingDocument:
                element?.supportingDocuments !== undefined
                  ? element?.supportingDocuments.length > 0
                    ? element.supportingDocuments[0]?.userFile?.document !== undefined
                      ? element.supportingDocuments[0]?.userFile?.document
                      : ""
                    : ""
                  : "",
            }),
          );
        setDoctorEducationDetails(degreeListItem);
      } else setDoctorEducationDetails([] as ModifiedDegreeList[]);
    } else setDoctorEducationDetails([] as ModifiedDegreeList[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDoctorEducationDetailsRes]);

  const [editItem, setEditItem] = useState({} as ModifiedDegreeList);
  const selectedItemUpdateState = (passedVal: any) => {
    setEditItem(passedVal);
  };

  const columns: GridColDef[] = [
    {
      field: "supportingDocument",
      headerName: `${t("appointmentpage.doc", { ns: ["home"] })!}`,
      width: 80,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<ArticleIcon />}
          variant="outlined"
          disabled={params.row.supportingDocument === "" ? true : false}
          onClick={() => {
            setViewEducationDocumentDialog(true);
            setEditItem(params.row);
          }}
        />
      ),
    },
    {
      field: "name",
      headerName: `${t("userprofilepage.instname", { ns: ["home"] })}`,
      minWidth: 200,
      flex: 1,
    },
    {
      field: "degree",
      headerName: `${t("userprofilepage.degname", { ns: ["home"] })}`,
      width: 100,
    },
    {
      field: "fromDate",
      headerName: `${t("userprofilepage.startdate", { ns: ["home"] })!}`,
      minWidth: 100,
      flex: 1,
    },
    {
      field: "toDate",
      headerName: `${t("appointmentpage.enddate", { ns: ["home"] })!}`,
      minWidth: 100,
      flex: 1,
    },
    {
      field: "description",
      headerName: `${t("appointmentpage.desc", { ns: ["home"] })!}`,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "id",
      headerName: `${t("common.view", { ns: ["home"] })!}`,
      width: 150,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<EditIcon />}
          variant="outlined"
          onClick={() => {
            setAddEducationDetailsDialog(true);
            setEditItem(params.row);
          }}
          sx={{ textTransform: "none" }}
        >
          {t("userprofilepage.edit", { ns: ["home"] })!}
        </Button>
      ),
    },
  ];

  const [addEducationDetailsDialog, setAddEducationDetailsDialog] = useState(false);
  const updateAddEducationDetailsDialogValFromChild = (passedVal: boolean) => {
    setAddEducationDetailsDialog(passedVal);
  };

  const [viewEducationDocumentDialog, setViewEducationDocumentDialog] = useState(false);
  const updateViewEducationDocumentDialogValFromChild = (passedVal: boolean) => {
    setViewEducationDocumentDialog(passedVal);
  };

  return (
    <Box>
      <Card>
        <CardHeader
          title={
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="subtitle1" fontWeight="bold">
                  {t("userprofilepage.edudetails", { ns: ["home"] })}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  sx={{ textTransform: "none" }}
                  onClick={() => setAddEducationDetailsDialog(true)}
                >
                  {t("userprofilepage.addedudetail", { ns: ["home"] })!}
                </Button>
              </Grid>
            </Grid>
          }
        ></CardHeader>
        <CardContent>
          {doctorEducationDetails.length > 0 ? (
            <DataGrid
              rows={doctorEducationDetails}
              columns={columns}
              pageSize={10}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )}
        </CardContent>
      </Card>
      {addEducationDetailsDialog ? (
        <AddDoctorEducationDetails
          dialogEnable={addEducationDetailsDialog}
          dialogUpdateState={updateAddEducationDetailsDialogValFromChild}
          selectedItem={editItem}
          selectedItemUpdateState={selectedItemUpdateState}
        />
      ) : null}
      {viewEducationDocumentDialog ? (
        <DocumentViewer
          dialogEnable={viewEducationDocumentDialog}
          dialogUpdateState={updateViewEducationDocumentDialogValFromChild}
          selectedItem={editItem}
          selectedItemUpdateState={selectedItemUpdateState}
        />
      ) : null}
    </Box>
  );
}
