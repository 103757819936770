import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import { useTranslation } from "react-i18next";
import ResetPassword from "./ResetPassword";
import { doctorAccountDetailsAPI } from "redux/AccountDetails/DoctorAccDetails/ApiCall";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import AddEmailPhone from "./AddEmailPhone";
import { GetDoctorDetailsRes } from "redux/jwtDoctorLogIn/doctorLoginDetails/Model";
import { getSignatureDetails } from "redux/AccountDetails/DoctorSignature/GetDoctorSignature/ApiCall";

export default function AccountDetailsLayout() {
  const { t } = useTranslation(["home"]);
  const dispatch = useDispatch();
  const docAccDetails = useSelector((state: AppState) => state.doctorAccDetails);

  const [resetPassDialogActive, setResetPassDialogActive] = useState(false);
  const updateResetPassDialogActiveValFromChild = (passedVal: boolean) => {
    setResetPassDialogActive(passedVal);
  };

  const [addEmailPhoneDialogActive, setAddEmailPhoneDialogActive] = useState(false);
  const [addEmailPhone, setAddEmailPhone] = useState("");
  const updateAddEmailPhoneDialogActiveValFromChild = (passedVal: boolean) => {
    setAddEmailPhoneDialogActive(passedVal);
    setAddEmailPhone("");
  };

  let userDetails = JSON.parse(localStorage.getItem("loginDetails")!) as GetDoctorDetailsRes;

  useEffect(() => {
    dispatch(getSignatureDetails({ doctorId: userDetails?.doctorId! }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Card>
        <CardHeader
          title={
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={() => dispatch(doctorAccountDetailsAPI())}
                  disabled={docAccDetails?.loading}
                  sx={{ textTransform: "none" }}
                >
                  {t("common.reload", { ns: ["home"] })!}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={() => setResetPassDialogActive(true)}
                  sx={{ textTransform: "none" }}
                >
                  {t("accountdetail.resetpassword", { ns: ["home"] })!}
                </Button>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  First Name:
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <Typography variant="h6">
                  {docAccDetails?.doctorAccountDetailsRes?.userDetails?.firstName !== undefined
                    ? docAccDetails?.doctorAccountDetailsRes?.userDetails?.firstName
                    : null}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Divider />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Last Name:
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <Typography variant="h6">
                  {docAccDetails?.doctorAccountDetailsRes?.userDetails?.lastName !== undefined
                    ? docAccDetails?.doctorAccountDetailsRes?.userDetails?.lastName
                    : null}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Divider />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Email:
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <Typography variant="h6">
                  {docAccDetails?.doctorAccountDetailsRes?.userDetails?.email !== undefined ? (
                    docAccDetails?.doctorAccountDetailsRes?.userDetails?.email !== "" ? (
                      docAccDetails?.doctorAccountDetailsRes?.userDetails?.email
                    ) : (
                      <Button
                        variant="outlined"
                        sx={{ textTransform: "none" }}
                        startIcon={<EmailIcon />}
                        onClick={() => {
                          setAddEmailPhoneDialogActive(true);
                          setAddEmailPhone("Email");
                        }}
                      >
                        Add Email
                      </Button>
                    )
                  ) : null}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        </CardContent>
      </Card>
      <ResetPassword
        isEnable={resetPassDialogActive}
        dialogUpdateState={updateResetPassDialogActiveValFromChild}
      />
      <AddEmailPhone
        isEnable={addEmailPhoneDialogActive}
        dialogUpdateState={updateAddEmailPhoneDialogActiveValFromChild}
        attachmentType={addEmailPhone}
      />
    </Box>
  );
}
