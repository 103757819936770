import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Alert,
  AlertColor,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import { EmailOTPGenBody } from "redux/emailOtpGeneration/Model";
import { clearEmailOtpGenAPIRes, emailOtpGenerationApi } from "redux/emailOtpGeneration/API";
import { clearEmailOTPSignUpAPIRes, emailOtpSignUp } from "redux/emailOtpSignup/API";
import { EmailOtpSignUpBody } from "redux/emailOtpSignup/Model";
import OnboardingLayout from "./OnboardingLayout";
import MWSnackbar from "components/MWSnackbar";

export default function SignUp() {
  const { t } = useTranslation(["home"]);
  const dispatch = useDispatch();

  const history = useHistory();

  const [buttonText, setButtonText] = useState(`${t("loginpage.sendotp", { ns: ["home"] })}`);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [emailOTPGenLoading, setEmailOTPGenLoading] = useState(false);

  const [otp, setOTP] = useState("");
  const [otpError, setOTPError] = useState("");

  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");

  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");

  const [disableSignUpForm, setDisableSignUpForm] = useState(true);

  const [genOTPMessageType, setGenOTPMessageType] = useState<AlertColor>("success");
  const [genOTPMessage, setGenOTPMessage] = useState("");

  const [emailOTPSignUpLoading, setEmailOTPSignUpLoading] = useState(false);
  const [emailOTPSignUpMessageType, setEmailOTPSignUpMessageType] = useState<AlertColor>("success");
  const [emailOTPSignUpSuccessMessage, setEmailOTPSignUpSuccessMessage] = useState("");
  const [emailOTPSignUpErrorMessage, setEmailOTPSignUpErrorMessage] = useState("");

  interface State {
    password: string;
    showPassword: boolean;
  }
  const [password, setPass] = React.useState<State>({
    password: "",
    showPassword: false,
  });
  const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setPass({ ...password, [prop]: event.target.value });
  };
  const handleClickShowPassword = () => {
    setPass({ ...password, showPassword: !password.showPassword });
  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const generateOTPBody = {
    email: email.toLowerCase(),
  } as EmailOTPGenBody;

  const generateOTP = () => {
    if (email === "") {
      setEmailError(`${t("common.erroremail", { ns: ["home"] })}`);
    } else if (
      new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email.toLowerCase()) === false
    ) {
      setEmailError(`${t("common.validemail", { ns: ["home"] })}`);
    } else {
      dispatch(emailOtpGenerationApi(generateOTPBody));
    }
  };
  const emailOtpGen = useSelector((state: AppState) => state.emailOtpGeneration);
  useEffect(() => {
    setEmailOTPGenLoading(emailOtpGen?.loading);
    if (emailOtpGen?.emailOTPGenRes?.message !== undefined) {
      setGenOTPMessageType("success");
      setGenOTPMessage(emailOtpGen?.emailOTPGenRes?.message);
      setDisableSignUpForm(false);
      setButtonText(`${t("loginpage.resendotp", { ns: ["home"] })}`);
    }
    if (emailOtpGen?.errorMsg !== "") {
      setGenOTPMessageType("success");
      setGenOTPMessage(emailOtpGen?.errorMsg);
      setButtonText(`${t("loginpage.sendotp", { ns: ["home"] })}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailOtpGen]);

  const signUpBody = {
    email: email.toLowerCase(),
    password: password.password,
    first_name: firstName,
    otp: otp,
    last_name: lastName,
  } as EmailOtpSignUpBody;

  //handle button click of Signup form
  const handleSignUp = () => {
    if (email === "") {
      setEmailError(`${t("common.erroremail", { ns: ["home"] })}`);
    } else if (
      new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email.toLowerCase()) === false &&
      new RegExp(/^[0]?[6789]\d{9}$/).test(email) === false
    ) {
      setEmailError(`${t("common.validemail", { ns: ["home"] })}`);
    } else if (otp === "" && firstName === "" && lastName === "") {
      setEmailError("");
      setOTPError(`${t("common.enterotp", { ns: ["home"] })}`);
      setFirstNameError(`${t("common.enterfirstname", { ns: ["home"] })}`);
      setLastNameError(`${t("common.enterlastname", { ns: ["home"] })}`);
    } else if (otp === "") {
      setEmailError("");
      setOTPError(`${t("common.enterotp", { ns: ["home"] })}`);
    } else if (firstName === "" && lastName === "") {
      setEmailError("");
      setOTPError("");
      setFirstNameError(`${t("common.enterfirstname", { ns: ["home"] })}`);
      setLastNameError(`${t("common.enterlastname", { ns: ["home"] })}`);
    } else if (firstName === "") {
      setEmailError("");
      setOTPError("");
      setFirstNameError(`${t("common.enterfirstname", { ns: ["home"] })}`);
    } else if (lastName === "") {
      setEmailError("");
      setOTPError("");
      setFirstNameError("");
      setLastNameError(`${t("common.enterlastname", { ns: ["home"] })}`);
    } else {
      dispatch(emailOtpSignUp(signUpBody));
    }
  };
  const emailOTPSignUpResponse = useSelector((state: AppState) => state.emailOTPSignUpRes);

  useEffect(() => {
    setEmailOTPSignUpLoading(emailOTPSignUpResponse?.loading);
    if (emailOTPSignUpResponse?.emailOTPSignUpRes?.message !== undefined) {
      setEmailOTPSignUpMessageType("success");
      setEmailOTPSignUpSuccessMessage(emailOTPSignUpResponse?.emailOTPSignUpRes?.message);
      setEmailError("");
      setOTPError("");
      setFirstNameError("");
      setLastNameError("");
    }
    if (emailOTPSignUpResponse?.errorMsg !== "") {
      setEmailOTPSignUpMessageType("error");
      setEmailOTPSignUpErrorMessage(emailOTPSignUpResponse?.errorMsg);
      setEmailError("");
      setOTPError("");
      setFirstNameError("");
      setLastNameError("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailOTPSignUpResponse]);

  const checkAlertClose = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearEmailOTPSignUpAPIRes());
      dispatch(clearEmailOtpGenAPIRes());
      setEmailOTPSignUpSuccessMessage("");
      history.push("/signin");
    }
  };

  return (
    <Box>
      <Container maxWidth="xl">
        <OnboardingLayout>
          <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
            <Divider sx={{ width: "100%" }} />
            <TextField
              id="emailFieldID"
              value={email}
              label={t("common.emailorphno", { ns: ["home"] })}
              placeholder={t("home.verifyemail", { ns: ["home"] })!}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(event.target.value);
                setEmailError("");
              }}
              fullWidth
              onKeyPress={(event: any) => {
                if (event.key === "Enter") {
                  generateOTP();
                }
              }}
              error={emailError !== "" ? true : false}
              helperText={
                emailError === "" ? (
                  <Box>{t("common.emailHelperText", { ns: ["home"] })!}</Box>
                ) : (
                  emailError
                )
              }
            />
            <Button
              fullWidth
              variant="contained"
              size="large"
              onClick={() => generateOTP()}
              sx={{ textTransform: "none" }}
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid item>{buttonText}</Grid>
                {emailOTPGenLoading ? (
                  <Grid item>
                    <CircularProgress size={20} style={{ color: "#ffffff" }} />
                  </Grid>
                ) : null}
              </Grid>
            </Button>
            {genOTPMessage !== "" ? (
              <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Alert
                    severity={genOTPMessageType}
                    onClose={() => {
                      dispatch(clearEmailOtpGenAPIRes());
                      setGenOTPMessage("");
                    }}
                  >
                    {genOTPMessage}
                  </Alert>
                </Grid>
              </Grid>
            ) : null}
            <TextField
              id="otpID"
              value={otp}
              label={t("otp", { ns: ["home"] })}
              placeholder={t("common.enterotp", { ns: ["home"] })!}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setOTP(event.target.value);
                setOTPError("");
              }}
              onKeyPress={(event: any) => {
                if (event.key === "Enter") {
                  handleSignUp();
                }
              }}
              disabled={disableSignUpForm}
              fullWidth
              error={otpError !== "" ? true : false}
              helperText={
                emailError === "" ? (
                  <Box>{t("common.emailHelperText", { ns: ["home"] })!}</Box>
                ) : (
                  emailError
                )
              }
            />
            <Grid container>
              <Grid item xs={6}>
                <TextField
                  id="firstNameID"
                  value={firstName}
                  label={t("common.firstname", { ns: ["home"] })}
                  placeholder={t("common.enterfirstname", { ns: ["home"] })!}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFirstName(event.target.value);
                    setFirstNameError("");
                  }}
                  onKeyPress={(event: any) => {
                    if (event.key === "Enter") {
                      handleSignUp();
                    }
                  }}
                  disabled={disableSignUpForm}
                  fullWidth
                  error={firstNameError !== "" ? true : false}
                  helperText={
                    firstNameError === "" ? (
                      <Box>{t("common.firstNameHelperText", { ns: ["home"] })!}</Box>
                    ) : (
                      firstNameError
                    )
                  }
                  style={{ paddingRight: "5px" }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="lastNameID"
                  value={lastName}
                  label={t("common.lastname", { ns: ["home"] })}
                  placeholder={t("common.enterlastname", { ns: ["home"] })!}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setLastName(event.target.value);
                    setLastNameError("");
                  }}
                  onKeyPress={(event: any) => {
                    if (event.key === "Enter") {
                      handleSignUp();
                    }
                  }}
                  disabled={disableSignUpForm}
                  fullWidth
                  error={lastNameError !== "" ? true : false}
                  helperText={
                    lastNameError === "" ? (
                      <Box>{t("common.lastNameHelperText", { ns: ["home"] })!}</Box>
                    ) : (
                      lastNameError
                    )
                  }
                  style={{ paddingLeft: "5px" }}
                />
              </Grid>
            </Grid>
            <FormControl sx={{ my: 2 }} fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                {t("common.passwordText", { ns: ["home"] })}
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                placeholder={t("home.enterpassword", { ns: ["home"] })!}
                label={t("home.passoptional", { ns: ["home"] })}
                type={
                  password.showPassword
                    ? `${t("home.text", { ns: ["home"] })}`
                    : `${t("password", { ns: ["home"] })}`
                }
                value={password.password}
                onChange={handleChange("password")}
                disabled={disableSignUpForm}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      disabled={disableSignUpForm}
                      edge="end"
                    >
                      {password.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText>{t("common.passwordHelperText", { ns: ["home"] })}</FormHelperText>
            </FormControl>
            <Button
              fullWidth
              variant="contained"
              size="large"
              type="submit"
              disabled={disableSignUpForm || emailOTPSignUpLoading}
              onClick={() => handleSignUp()}
              sx={{ textTransform: "none" }}
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid item>{t("home.register", { ns: ["home"] })!}</Grid>
                {emailOTPSignUpLoading ? (
                  <Grid item>
                    <CircularProgress size={20} style={{ color: "#ffffff" }} />
                  </Grid>
                ) : null}
              </Grid>
            </Button>
            {emailOTPSignUpErrorMessage !== "" ? (
              <Alert
                severity={emailOTPSignUpMessageType}
                onClose={() => {
                  dispatch(clearEmailOTPSignUpAPIRes());
                  setEmailOTPSignUpErrorMessage("");
                }}
              >
                {emailOTPSignUpErrorMessage}
              </Alert>
            ) : null}
            <Divider />
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Typography fontWeight="bold">{t("home.olduser", { ns: ["home"] })}</Typography>
              </Grid>
              <Grid item>
                <Button variant="contained" href="/signin" sx={{ textTransform: "none" }}>
                  {t("common.login", { ns: ["home"] })!}
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </OnboardingLayout>
      </Container>
      <MWSnackbar
        msg={emailOTPSignUpSuccessMessage}
        type={emailOTPSignUpMessageType}
        alertClose={checkAlertClose}
      />
    </Box>
  );
}
