import React, { useState, useEffect } from "react";
import { Box, Typography, Button, CardContent, Card, CardHeader, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import { t } from "i18next";
import ArticleIcon from "@mui/icons-material/Article";
import EditIcon from "@mui/icons-material/Edit";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import dayjs from "dayjs";
import DocumentViewer from "components/DocumentViewer";
import { getLegalDetails } from "redux/UserProfile/LegalDetails/LegalDetails/GetLegalDetailsByDoctor/API";
import { getLegalTypeList } from "redux/UserProfile/LegalDetails/LegalDetails/LegalTypeList/API";
import { LegalList } from "redux/UserProfile/LegalDetails/LegalDetails/GetLegalDetailsByDoctor/Model";
import UpdateLegalDetails from "./UpdateLegalDetails";
import { ModifiedLegalDetails } from "./Model";
import MWExceptionList from "components/MWExceptionList";
export interface Props {
  legalDetailsListLoading: boolean | any;
}

export default function LegalDetailsList({ legalDetailsListLoading }: Props) {
  const [editItem, setEditItem] = useState({} as ModifiedLegalDetails);
  const selectedItemUpdateState = (passedVal: any) => {
    setEditItem(passedVal);
  };

  const workExpTableColumns: GridColDef[] = [
    {
      field: "supportingDocument",
      headerName: `${t("appointmentpage.doc", { ns: ["home"] })}`,
      minWidth: 130,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<ArticleIcon />}
          variant="outlined"
          disabled={params.row.supportingDocument === "" ? true : false}
          onClick={() => {
            setViewEducationDocumentDialog(true);
            setEditItem(params.row);
          }}
          sx={{ textTransform: "none" }}
        >
          {
            t("common.view", {
              ns: ["home"],
            })!
          }
        </Button>
      ),
    },
    {
      field: "casePendingAt",
      headerName: `${t("userprofilepage.casependingat", { ns: ["home"] })}`,
      minWidth: 130,
    },
    {
      field: "casePending",
      headerName: `${t("userprofilepage.pendingcase", { ns: ["home"] })}`,
      minWidth: 110,
    },
    {
      field: "deathCase",
      headerName: `${t("userprofilepage.deathreason", { ns: ["home"] })}`,
      minWidth: 210,
    },
    {
      field: "startDate",
      headerName: `${t("userprofilepage.pendingfrom", { ns: ["home"] })}`,
      minWidth: 150,
    },
    {
      field: "endDate",
      headerName: `${t("userprofilepage.pendingtill", { ns: ["home"] })}`,
      minWidth: 120,
    },
    {
      field: "tableNme",
      headerName: `${t("appointmentpage.desc", { ns: ["home"] })}`,
      minWidth: 200,
      flex: 1,
    },
    {
      field: "id",
      headerName: `${t("userprofilepage.edit", { ns: ["home"] })}`,
      minWidth: 150,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<EditIcon />}
          variant="outlined"
          onClick={() => {
            setUpdateLegalDetailsDialog(true);
            setEditItem(params.row);
          }}
          sx={{ textTransform: "none" }}
        >
          {
            t("userprofilepage.edit", {
              ns: ["home"],
            })!
          }
        </Button>
      ),
    },
  ];

  const [legalDetailsList, setLegalDetailsList] = useState<any[]>([]);

  // Call legal details list store
  const getLegalDetailsRes = useSelector((state: AppState) => state.getLegalDetailsRes);

  useEffect(() => {
    legalDetailsListLoading(getLegalDetailsRes?.loading);
    if (getLegalDetailsRes?.getDoctorLegalDetailsRes?.LegalList !== undefined) {
      let legalDetailsItem = getLegalDetailsRes?.getDoctorLegalDetailsRes?.LegalList.map(
        (item: LegalList) => ({
          id: item?.id,
          supportingDocument:
            item?.supportingDocuments !== undefined
              ? item?.supportingDocuments !== null
                ? item?.supportingDocuments[0]?.userFile?.document
                : null
              : null,
          tableNme: item?.description !== undefined ? item?.description.substring(0, 30) : "",
          name: item?.description !== undefined ? item?.description : "",
          casePendingAt:
            item?.legalType !== undefined
              ? item?.legalType !== null
                ? item?.legalType?.label
                : ""
              : "",
          casePendingVal:
            item?.legalType !== undefined
              ? item?.legalType !== null
                ? item?.legalType?.value
                : null
              : null,
          startDate:
            item?.fromDate !== null ? dayjs(item?.fromDate).format("DD-MM-YYYY") : "Not Set",
          endDate: item?.toDate !== null ? dayjs(item?.toDate).format("DD-MM-YYYY") : "Not Set",
          casePending: item?.isCasePending ? "yes" : "No",
          deathCase: item?.isDeathDueToNegligence ? "Yes" : "No",
        }),
      );

      setLegalDetailsList(legalDetailsItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getLegalDetailsRes]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLegalTypeList());
    dispatch(getLegalDetails());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [updateLegalDetailsDialog, setUpdateLegalDetailsDialog] = useState(false);
  const updateLegalDetailsDialogValFromChild = (passedVal: boolean) => {
    setUpdateLegalDetailsDialog(passedVal);
  };

  const [viewEducationDocumentDialog, setViewEducationDocumentDialog] = useState(false);
  const updateViewEducationDocumentDialogValFromChild = (passedVal: boolean) => {
    setViewEducationDocumentDialog(passedVal);
  };

  const actualPageMarkup = (
    <Box>
      <Card>
        <CardHeader
          title={
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="subtitle1" fontWeight="bold">
                  {t("userprofilepage.legaldetail", { ns: ["home"] })}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => setUpdateLegalDetailsDialog(true)}
                  variant="contained"
                  sx={{ textTransform: "none" }}
                >
                  {t("userprofilepage.addlegaldetail", { ns: ["home"] })!}
                </Button>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          {legalDetailsList.length > 0 ? (
            <DataGrid
              rows={legalDetailsList}
              columns={workExpTableColumns}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              getRowId={(row) => row.id}
            />
          ) : (
            <MWExceptionList />
          )}
        </CardContent>
      </Card>
    </Box>
  );
  return (
    <Box>
      {actualPageMarkup}
      {updateLegalDetailsDialog ? (
        <UpdateLegalDetails
          dialogEnable={updateLegalDetailsDialog}
          dialogUpdateState={updateLegalDetailsDialogValFromChild}
          selectedItem={editItem}
          selectedItemUpdateState={selectedItemUpdateState}
        />
      ) : null}
      {viewEducationDocumentDialog ? (
        <DocumentViewer
          dialogEnable={viewEducationDocumentDialog}
          dialogUpdateState={updateViewEducationDocumentDialogValFromChild}
          selectedItem={editItem}
          selectedItemUpdateState={selectedItemUpdateState}
        />
      ) : null}
    </Box>
  );
}
