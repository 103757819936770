import React, { useEffect, useState } from "react";
import { NavLink, BrowserRouter as Router } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  AppBar,
  Avatar,
  Box,
  Divider,
  Drawer,
  Grid,
  Button,
  Popover,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Toolbar,
  Stack,
  IconButton,
} from "@mui/material";
import { DrawerList, NavigationList } from "./components/DrawerList";
import ComponentRouter from "./routes";
import MWLoader from "./components/MWLoader";
import MWSelectLang, { MobileSelectLang } from "./components/MWSelectLang";
import { AppState } from "redux/store/Store";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "redux/logOut/API";
import { GetDoctorDetailsRes } from "redux/jwtDoctorLogIn/doctorLoginDetails/Model";
import { useTranslation } from "react-i18next";
import AppLogo from "./components/image/cloud-doctor-with-caption.png";
import { LogOutBody } from "redux/logOut/Model";
import MoreIcon from "@mui/icons-material/MoreVert";
import MenuIcon from "@mui/icons-material/Menu";

const drawerWidth = 240;
interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  windows?: () => Window;
}

export default function App(props: Props) {
  const { windows } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const loadingMarkup = isLoading ? <MWLoader /> : null;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const Bearer = localStorage.getItem("access");
  const refresh = localStorage.getItem("refresh");

  let userDetails = JSON.parse(localStorage.getItem("loginDetails")!) as GetDoctorDetailsRes;

  const logOutBody = {
    refresh_token: refresh!,
  } as LogOutBody;

  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logOut(logOutBody));
  };
  const logOutRes = useSelector((state: AppState) => state.logOutRes);
  useEffect(() => {
    logOutRes?.loading ? setIsLoading(true) : setIsLoading(false);
  }, [logOutRes]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const drawer = (
    <Stack
      direction="column"
      justifyContent="space-between"
      alignItems="flex-start"
      spacing={2}
      py={1}
      sx={{
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        style={{
          width: "100%",
        }}
      >
        <Toolbar />
        <List
          style={{
            width: "100%",
          }}
          dense
        >
          {DrawerList.slice(0, 1).map((item) => {
            return (
              <ListItem
                component={NavLink}
                activeClassName="Mui-selected"
                to={item.path}
                key={item.name}
                disablePadding
              >
                <ListItemButton sx={{ py: 0, minHeight: 32 }}>
                  <ListItemIcon sx={{ minWidth: 40 }}>{item.icon}</ListItemIcon>
                  <ListItemText
                    primary={t(`common.${item.name}`, { ns: ["home"] })!}
                    primaryTypographyProps={{
                      fontSize: 14,
                      fontWeight: "medium",
                    }}
                  ></ListItemText>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
        <Divider sx={{ bgcolor: "#fedbd4" }} />
        {userDetails?.onboardingstatus === "approved" ? (
          <List
            style={{
              textDecoration: "none",
              width: "100%",
            }}
          >
            {DrawerList.slice(1, 5).map((item) => {
              return (
                <ListItem
                  component={NavLink}
                  activeClassName="Mui-selected"
                  to={item.path}
                  key={item.name}
                  disablePadding
                >
                  <ListItemButton sx={{ py: 0, minHeight: 32 }}>
                    <ListItemIcon sx={{ minWidth: 40 }}>{item.icon}</ListItemIcon>
                    <ListItemText
                      primary={t(`common.${item.name}`, { ns: ["home"] })!}
                      primaryTypographyProps={{
                        fontSize: 14,
                        fontWeight: "medium",
                      }}
                    ></ListItemText>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        ) : null}
      </Stack>
      <Box
        style={{
          textDecoration: "none",
          width: "100%",
        }}
      >
        <Divider sx={{ bgcolor: "#fedbd4" }} />
        <List
          style={{
            textDecoration: "none",
            width: "100%",
          }}
        >
          {DrawerList.slice(-3, -1).map((item) => {
            return (
              <ListItem
                component={NavLink}
                activeClassName="Mui-selected"
                to={item.path}
                key={item.name}
                disablePadding
              >
                <ListItemButton sx={{ py: 0, minHeight: 32 }}>
                  <ListItemIcon sx={{ minWidth: 40 }}>{item.icon}</ListItemIcon>
                  <ListItemText
                    primary={t(`common.${item.name}`, { ns: ["home"] })!}
                    primaryTypographyProps={{
                      fontSize: 14,
                      fontWeight: "medium",
                    }}
                  ></ListItemText>
                </ListItemButton>
              </ListItem>
            );
          })}
          {DrawerList.slice(-1).map((item) => {
            return (
              <ListItem key={item.name} disablePadding>
                <ListItemButton onClick={() => handleLogout()} sx={{ py: 0, minHeight: 32 }}>
                  <ListItemIcon sx={{ minWidth: 40 }}>{item.icon}</ListItemIcon>
                  <ListItemText
                    primary={t(`common.${item.name}`, { ns: ["home"] })!}
                    primaryTypographyProps={{
                      fontSize: 14,
                      fontWeight: "medium",
                    }}
                  ></ListItemText>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Stack>
  );
  const container = windows !== undefined ? () => windows().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <Router>
        {Bearer ? (
          <AppBar
            position="fixed"
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              backgroundColor: "#ffffff",
            }}
          >
            <Toolbar>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    <Grid item>
                      <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ display: { sm: "none" } }}
                      >
                        <MenuIcon sx={{ color: "#757575" }} />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <Link
                        href={
                          userDetails?.onboardingstatus === "approved"
                            ? "/dashboard"
                            : "/userprofile"
                        }
                        to={""}
                      >
                        <img
                          alt="cloud-doctor logo"
                          src={AppLogo}
                          style={{
                            height: 30,
                          }}
                        />
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                    <Grid item>
                      <Box
                        sx={{
                          display: { xs: "none", sm: "flex", md: "flex", lg: "flex", xl: "flex" },
                        }}
                      >
                        <MWSelectLang isMenu />
                      </Box>
                      <Box
                        sx={{
                          display: {
                            xs: "flex",
                            sm: "flex",
                            md: "flex",
                            lg: "none",
                            xl: "none",
                          },
                        }}
                      >
                        <MobileSelectLang />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box sx={{ display: { xs: "none", md: "flex" } }}>
                        <Button
                          sx={{ marginTop: 0.5 }}
                          size="small"
                          aria-describedby={id}
                          onClick={handleClick}
                        >
                          <Grid
                            container
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            spacing={0.5}
                          >
                            <Grid item>
                              <Avatar />
                            </Grid>
                            <Grid item>
                              <Grid
                                container
                                direction="column"
                                alignItems="flex-start"
                                justifyContent="flex-start"
                              >
                                <Grid item>
                                  <Typography
                                    sx={{ textTransform: "capitalize" }}
                                    variant="subtitle2"
                                    noWrap
                                  >
                                    {userDetails?.user?.first_name !== undefined &&
                                    userDetails?.user?.last_name !== undefined
                                      ? userDetails?.user?.first_name +
                                        " " +
                                        userDetails?.user?.last_name
                                      : null}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography
                                    variant="caption"
                                    noWrap
                                    style={{
                                      textTransform: "lowercase",
                                    }}
                                  >
                                    {userDetails?.user?.email !== undefined
                                      ? userDetails?.user?.email
                                      : null}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box sx={{ display: { xs: "flex", md: "none" } }}>
                        <IconButton
                          size="large"
                          aria-label="show more"
                          aria-haspopup="true"
                          onClick={handleClick}
                          sx={{ color: "#757575" }}
                        >
                          <MoreIcon />
                        </IconButton>
                      </Box>
                    </Grid>
                  </Grid>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <List dense>
                      <ListItem>
                        <Box sx={{ display: { xs: "block", md: "none" } }}>
                          <ListItemText
                            primary={
                              userDetails?.user?.first_name !== undefined &&
                              userDetails?.user?.last_name !== undefined
                                ? userDetails?.user?.first_name + " " + userDetails?.user?.last_name
                                : null
                            }
                            secondary={
                              userDetails?.user?.email !== undefined
                                ? userDetails?.user?.email
                                : null
                            }
                          />
                        </Box>
                      </ListItem>
                      {NavigationList.map((item: any) => {
                        return (
                          <ListItem disablePadding>
                            <ListItemButton
                              component={Link}
                              to={item.path}
                              key={item.name}
                              style={{
                                textDecoration: "none",
                              }}
                              onClick={() => handleClose()}
                            >
                              <ListItemText>
                                {t(`common.${item.name}`, { ns: ["home"] })}
                              </ListItemText>
                            </ListItemButton>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Popover>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        ) : null}

        {Bearer ? (
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
          >
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: "block", sm: "none" },
                "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
              }}
            >
              {drawer}
            </Drawer>
            <Drawer
              variant="permanent"
              sx={{
                display: { xs: "none", sm: "block" },
                "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
              }}
              open
            >
              {drawer}
            </Drawer>
          </Box>
        ) : null}
        <Box component="main" sx={{ flexGrow: 1 }}>
          {Bearer ? <Toolbar /> : null}
          {Bearer ? (
            <>
              {loadingMarkup}
              <ComponentRouter />
            </>
          ) : (
            <ComponentRouter />
          )}
        </Box>
      </Router>
    </Box>
  );
}
