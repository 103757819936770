import React, { useState, useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import MWExceptionList from "components/MWExceptionList";
// import DoctorLoader from "./DoctorLoader";
import { getDoctorListForCase } from "redux/AddDoctorTeam/DoctorListByPatientCase/API";
import { getPaymentLinkApi } from "redux/AddDoctorTeam/GetPaymentLink/Api";
import {
  clearDrAppointmentDecisionAPIRes,
  drAppointmentDecision,
} from "redux/AddDoctorTeam/doctorAcceptAppointment/API";
import { deleteSelectedDoctorApi } from "redux/AddDoctorTeam/DeleteSelectedDoctor/Api";
import MWSnackbar from "components/MWSnackbar";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function AddDoctorTeam() {
  const dispatch = useDispatch();
  const { caseid } = useParams() as {
    caseid: string;
  };
  const [toSignin] = useState(false);
  const [selectedDoctorId, setSelectedDoctorId] = useState("");
  const [doctorName, setDoctorName] = useState("");
  const [, setIsLoading] = useState(false);
  // const loadingMarkup = <DoctorLoader />;
  const [open, setOpen] = React.useState(false);
  const [apptArray, setApptGridArray] = useState<any[]>([]);

  const handleClickOpen = (doctorId: any, doctorName: any) => {
    setOpen(true);
    setDoctorName(doctorName);
    setSelectedDoctorId(doctorId);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const closeDoctorDeleteSuccess = () => {
    setDoctorDeleteSuccess("");
    setDoctorDeleteError("");
  };
  const [doctorDeleteSuccess, setDoctorDeleteSuccess] = useState("");
  const deleteDoctorsuccessDiv = doctorDeleteSuccess ? (
    <Alert onClose={closeDoctorDeleteSuccess} icon={<CheckIcon />} severity="success">
      <p>{doctorDeleteSuccess}</p>
    </Alert>
  ) : (
    ""
  );

  const [doctorDeleteError, setDoctorDeleteError] = useState("");
  const deleteDoctorErrorDiv = doctorDeleteError ? (
    <Alert onClose={closeDoctorDeleteSuccess} severity="error">
      <p>{doctorDeleteError}</p>
    </Alert>
  ) : (
    ""
  );

  // Call Accept Doctor Appoinment store
  const drAppointmentDecisionRes = useSelector((state: AppState) => state.drAppointmentDecisionRes);
  const [drAppointmentDecisionMsg, setDrAppointmentDecisionMsg] = useState("");
  const [drAppointmentDecisionMsgType, setDrAppointmentDecisionMsgType] =
    useState<AlertColor>("success");

  useEffect(() => {
    if (drAppointmentDecisionRes?.successMsg !== "") {
      setDrAppointmentDecisionMsgType("success");
      setDrAppointmentDecisionMsg(drAppointmentDecisionRes?.successMsg);
    }
    if (drAppointmentDecisionRes?.errorMsg !== "") {
      setDrAppointmentDecisionMsgType("error");
      setDrAppointmentDecisionMsg(drAppointmentDecisionRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drAppointmentDecisionRes]);

  const closeModifyDrAppointmentDecision = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(getDoctorListForCase(caseid));
      dispatch(clearDrAppointmentDecisionAPIRes());
    }
  };

  const [doctorTeamType, setDoctorTeamType] = useState("");
  const selectionTypeBody = {
    caseId: caseid,
  };
  //Selection Type GET API
  const getPaymentLinkValueFromStore = useSelector((state: AppState) => state.DoctorpaymentLink);
  // Get accept reject  API

  const acceptBookingBody = {
    caseId: caseid,
    requestStatus: Number(1),
  };
  const rejectBookingBody = {
    caseId: caseid,
    requestStatus: Number(-1),
  };
  const acceptBooking = () => {
    dispatch(drAppointmentDecision(acceptBookingBody));
    dispatch(getDoctorListForCase(caseid));
  };
  const rejectBooking = () => {
    dispatch(drAppointmentDecision(rejectBookingBody));
    dispatch(getDoctorListForCase(caseid));
  };

  // Call getSelectedDoc List Store
  const selectedDocListValueFromStore = useSelector(
    (state: AppState) => state.doctorListForcasevalue.doctorListForPatientCaseRes,
  );
  const getSelectedDocList = useSelector(
    (state: AppState) =>
      state.doctorListForcasevalue?.doctorListForPatientCaseRes?.patientCaseDoctorlist,
  );
  useEffect(() => {
    if (selectedDocListValueFromStore?.patientCaseDoctorlist !== undefined) {
      let dataPointArr = selectedDocListValueFromStore?.patientCaseDoctorlist.map(
        (element: any) => ({
          id: element.id,
          doctorName: element.firstname + " " + element.lastname,
          speciality: element.speciality,
          requestStatus: element.requestStatus,
        }),
      );
      setApptGridArray(dataPointArr);
    } else {
      setApptGridArray([] as any[]);
    }
  }, [selectedDocListValueFromStore]);

  const columns: GridColDef[] = [
    {
      field: "doctorName",
      headerName: `${t("common.docName", { ns: ["home"] })}`,
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "speciality",
      headerName: `${t("appointmentpage.speciality", { ns: ["home"] })}`,
      type: "string",
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "requestStatus",
      headerName: `${t("appointmentpage.status", { ns: ["home"] })}`,
      sortable: false,
      width: 100,
      align: "left",
      flex: 1,
    },
    {
      field: "accept",
      headerName: `${t("appointmentpage.accept", { ns: ["home"] })}`,
      width: 100,
      align: "left",
      flex: 1,
      renderCell: (_params: GridCellParams) => (
        <Button
          startIcon={<CheckIcon />}
          onClick={() => {
            acceptBooking();
          }}
          variant="outlined"
          sx={{ textTransform: "none" }}
        >
          {t("appointmentpage.accept", { ns: ["home"] })}
        </Button>
      ),
    },
    {
      field: "reject",
      headerName: `${t("appointmentpage.reject", { ns: ["home"] })}`,
      width: 100,
      align: "left",
      flex: 1,
      renderCell: (_params: GridCellParams) => (
        <Button
          startIcon={<CloseIcon />}
          onClick={() => {
            rejectBooking();
          }}
          variant="contained"
          sx={{ textTransform: "none" }}
        >
          {t("appointmentpage.reject", { ns: ["home"] })}
        </Button>
      ),
    },
    {
      field: "id",
      headerName: `${t("appointmentpage.del", { ns: ["home"] })}`,
      width: 100,
      align: "left",
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<DeleteIcon />}
          onClick={() => {
            handleClickOpen(params.row.id, doctorName);
          }}
          variant="outlined"
          sx={{ textTransform: "none" }}
        ></Button>
      ),
    },
  ];

  //const QuestionListValue: any[] = getSelectedDocListValue;

  // const getSelectedDoc = () => {
  //   dispatch(getDoctorListForCase(caseid));
  // };

  // Delete Doctor Body
  const doctorDeleteBody = {
    caseId: caseid,
    doctorId: selectedDoctorId,
  };

  // doctor Delete submit
  // Call Accept Doctor Appoinment store
  const deleteSelectedDoctorValue = useSelector((state: AppState) => state.deleteSelectedDoctor);
  const deleteDoctorAPI = () => {
    dispatch(deleteSelectedDoctorApi(doctorDeleteBody));
  };

  useEffect(() => {
    dispatch(getDoctorListForCase(caseid));
    dispatch(getPaymentLinkApi(selectionTypeBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsLoading(deleteSelectedDoctorValue?.loading);
    deleteSelectedDoctorValue?.loading ? setIsLoading(true) : setIsLoading(false);
    if (deleteSelectedDoctorValue?.deleteSelectedDoctorRes?.message !== undefined) {
      if (deleteSelectedDoctorValue?.deleteSelectedDoctorRes?.message !== "") {
        setDoctorDeleteSuccess(deleteSelectedDoctorValue?.deleteSelectedDoctorRes?.message);
        handleClose();
        dispatch(getDoctorListForCase(caseid));
      }
    }
    if (deleteSelectedDoctorValue?.errRes !== "") {
      setDoctorDeleteError(deleteSelectedDoctorValue?.errRes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSelectedDoctorValue]);

  useEffect(() => {
    if (getPaymentLinkValueFromStore !== undefined) {
      const doctorTeamTypeList = getPaymentLinkValueFromStore.getPaymentLinkRes.doctorTeamType;
      setDoctorTeamType(doctorTeamTypeList);
    }
    setIsLoading(getPaymentLinkValueFromStore?.loading);
    getPaymentLinkValueFromStore?.loading ? setIsLoading(true) : setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPaymentLinkValueFromStore]);

  const selectionOptionMarkup = (
    <Box p={2}>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={2}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography>{t("appointmentpage.docseltype", { ns: ["home"] })}</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Alert severity="error" variant="outlined">
            {doctorTeamType}
          </Alert>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Card>
            <CardHeader title={t("appointmentpage.seldoclist", { ns: ["home"] })}>
              {/* <Button
                icon={RefreshMinor}
                primary={false}
                onClick={getSelectedDoc}
              >
                {t("appointmentpage.reloaddoclist", { ns: ["home"] })!}
              </Button> */}
            </CardHeader>
            <CardContent>
              {getSelectedDocList && getSelectedDocList.length ? (
                <DataGrid
                  rows={apptArray}
                  columns={columns}
                  hideFooter
                  hideFooterPagination
                  disableSelectionOnClick
                  experimentalFeatures={{ newEditingApi: true }}
                  autoHeight
                  components={{ Toolbar: GridToolbar }}
                  componentsProps={{
                    toolbar: {
                      csvOptions: { disableToolbarButton: true },
                      printOptions: { disableToolbarButton: true },
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 250 },
                    },
                  }}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                />
              ) : (
                <MWExceptionList />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
  const deleteDoctorModal = (
    <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        {t("docTeamPage.delDoc", { ns: ["home"] })}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Typography gutterBottom>{t("common.wantDelDoctor", { ns: ["home"] })!}</Typography>
        <div>
          <Box p={1}>{deleteDoctorsuccessDiv}</Box>
        </div>
        <div>
          <Box p={1}>{deleteDoctorErrorDiv}</Box>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t("filePrepPage.no", { ns: ["home"] })!}</Button>
        <Button onClick={deleteDoctorAPI}>{t("filePrepPage.yes", { ns: ["home"] })!}</Button>
      </DialogActions>
    </BootstrapDialog>
  );

  return (
    <Box>
      {toSignin ? <Redirect to="/signin" /> : null}
      {/* {loadingMarkup} */}
      {selectionOptionMarkup}
      {deleteDoctorModal}
      <MWSnackbar
        msg={drAppointmentDecisionMsg}
        type={drAppointmentDecisionMsgType}
        alertClose={closeModifyDrAppointmentDecision}
      />
    </Box>
  );
}
