import React, { useState, useEffect } from "react";
import { Box, Typography, Button, CardContent, Card, CardHeader, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import { t } from "i18next";
import ArticleIcon from "@mui/icons-material/Article";
import EditIcon from "@mui/icons-material/Edit";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import dayjs from "dayjs";
import DocumentViewer from "components/DocumentViewer";
import { getDrIdProofList } from "redux/UserProfile/IdentityDetails/GetIdDetailsByDoctor/API";
import { ProofList } from "redux/UserProfile/IdentityDetails/GetIdDetailsByDoctor/Model";
import { ModifiedIdentityProofList } from "./Model";
import UpdateIdentityDetails from "./UpdateLegalDetails";
import { getDrIdProofTypeList } from "redux/UserProfile/IdentityDetails/GovtIdProofDropdownList /API";

export interface Props {
  identityDetailsLoading: boolean | any;
}

export default function IdentityDetails({ identityDetailsLoading }: Props) {
  const [editItem, setEditItem] = useState({} as ModifiedIdentityProofList);
  const selectedItemUpdateState = (passedVal: any) => {
    setEditItem(passedVal);
  };

  const identityProofTableColumns: GridColDef[] = [
    {
      field: "supportingDocument",
      headerName: `${t("userprofilepage.suppdoc", { ns: ["home"] })}`,
      minWidth: 150,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<ArticleIcon />}
          variant="outlined"
          disabled={params.row.supportingDocument === "" ? true : false}
          onClick={() => {
            setViewDrIdProofDocumentDialog(true);
            setEditItem(params.row);
          }}
          sx={{ textTransform: "none" }}
        >
          {
            t("common.view", {
              ns: ["home"],
            })!
          }
        </Button>
      ),
    },
    {
      field: "identityProofDocName",
      headerName: `${t("userprofilepage.idname", { ns: ["home"] })}`,
      minWidth: 130,
      flex: 1,
    },
    {
      field: "validityDateDate",
      headerName: `${t("userprofilepage.validupto", { ns: ["home"] })}`,
      minWidth: 110,
    },
    {
      field: "identityProofDocNumber",
      headerName: `${t("userprofilepage.refno", { ns: ["home"] })}`,
      minWidth: 210,
    },
    {
      field: "id",
      headerName: `${t("userprofilepage.edit", { ns: ["home"] })}`,
      minWidth: 90,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<EditIcon />}
          variant="outlined"
          onClick={() => {
            setUpdateDrIdProofDetailsDialog(true);
            setEditItem(params.row);
          }}
          sx={{ textTransform: "none" }}
        >
          {
            t("userprofilepage.edit", {
              ns: ["home"],
            })!
          }
        </Button>
      ),
    },
  ];

  const [drIdProofTypeListList, setDrIdProofTypeListList] = useState(
    [] as ModifiedIdentityProofList[],
  );

  // Call id proof list store
  const getDrIdProofListRes = useSelector((state: AppState) => state.getDrIdProofListRes);

  useEffect(() => {
    identityDetailsLoading(getDrIdProofListRes?.loading);
    if (getDrIdProofListRes?.getDrIdProofListRes?.proofList !== undefined) {
      let drIdProofItem = getDrIdProofListRes?.getDrIdProofListRes?.proofList.map(
        (item: ProofList) =>
          ({
            id: item?.id,
            supportingDocument:
              item?.supportingDocuments !== undefined
                ? item?.supportingDocuments !== null
                  ? item?.supportingDocuments?.document
                  : null
                : null,
            identityProofDocName:
              item?.idType !== undefined ? (item?.idType !== null ? item?.idType?.label : "") : "",
            identityProofDocValue:
              item?.idType !== undefined
                ? item?.idType !== null
                  ? item?.idType?.value
                  : null
                : null,
            identityProofDocNumber: item?.idNumber !== undefined ? item?.idNumber : "",
            validityDateDate:
              item?.validityDate !== undefined
                ? item?.validityDate !== null
                  ? dayjs(item?.validityDate).format("DD-MM-YYYY")
                  : "Not Set"
                : "Not Set",
          } as ModifiedIdentityProofList),
      );

      setDrIdProofTypeListList(drIdProofItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDrIdProofListRes]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDrIdProofTypeList());
    dispatch(getDrIdProofList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [updateDrIdProofDetailsDialog, setUpdateDrIdProofDetailsDialog] = useState(false);
  const updateDrIdProofDetailsDialogValFromChild = (passedVal: boolean) => {
    setUpdateDrIdProofDetailsDialog(passedVal);
  };

  const [viewDrIdProofDocumentDialog, setViewDrIdProofDocumentDialog] = useState(false);
  const updateViewDrIdProofDocumentDialogValFromChild = (passedVal: boolean) => {
    setViewDrIdProofDocumentDialog(passedVal);
  };

  return (
    <Box>
      <Card>
        <CardHeader
          title={
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="subtitle1" fontWeight="bold">
                  {t("userprofilepage.iddetail", { ns: ["home"] })}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => setUpdateDrIdProofDetailsDialog(true)}
                  variant="contained"
                  sx={{ textTransform: "none" }}
                >
                  {t("userprofilepage.addiddetail", { ns: ["home"] })!}
                </Button>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          {drIdProofTypeListList && drIdProofTypeListList.length ? (
            <DataGrid
              rows={drIdProofTypeListList}
              columns={identityProofTableColumns}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              getRowId={(row) => row.id}
            />
          ) : (
            <Typography>{`${t("userprofilepage.noidavail", { ns: ["home"] })!}`}</Typography>
          )}
        </CardContent>
      </Card>
      {updateDrIdProofDetailsDialog ? (
        <UpdateIdentityDetails
          dialogEnable={updateDrIdProofDetailsDialog}
          dialogUpdateState={updateDrIdProofDetailsDialogValFromChild}
          selectedItem={editItem}
          selectedItemUpdateState={selectedItemUpdateState}
        />
      ) : null}
      {viewDrIdProofDocumentDialog ? (
        <DocumentViewer
          dialogEnable={viewDrIdProofDocumentDialog}
          dialogUpdateState={updateViewDrIdProofDocumentDialogValFromChild}
          selectedItem={editItem}
          selectedItemUpdateState={selectedItemUpdateState}
        />
      ) : null}
    </Box>
  );
}
