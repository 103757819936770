import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  IconButton,
  Divider,
  AlertColor,
  Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/store/Store";
import MWLoader from "../../../../components/MWLoader";
import {
  clearDeleteSummaryListAPIRes,
  deleteSummaryList,
  updateDeleteSummaryListAPIResMsg,
} from "redux/FilePreparation/doctorSummary/deleteSummaryList/ApiCall";
import { DeleteSummaryListSubmitBody } from "redux/FilePreparation/doctorSummary/deleteSummaryList/Model";
import { GetSummeryListSubmitBody } from "redux/FilePreparation/doctorSummary/getSummaryList/Model";
import { getDoctorSummaryList } from "redux/FilePreparation/doctorSummary/getSummaryList/ApiCall";
import { t } from "i18next";
interface Props {
  dialogEnable?: boolean | any;
  dialogUpdateState: boolean | any;
  itemId?: number | any;
  type?: string | any;
  docTypeName?: string;
}

export default function DeleteSummaryData({
  dialogEnable,
  dialogUpdateState,
  itemId,
  type,
  docTypeName,
}: Props) {
  const dispatch = useDispatch();
  const { caseid } = useParams() as {
    caseid: string;
  };
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleClose = () => {
    setDialogOpen(false);
    dialogUpdateState(false);
    dispatch(updateDeleteSummaryListAPIResMsg());
    dispatch(clearDeleteSummaryListAPIRes());
  };
  useEffect(() => {
    dialogEnable !== undefined ? setDialogOpen(dialogEnable) : setDialogOpen(false);
  }, [dialogEnable]);
  const [delItemid, setDeleteItemId] = useState(0);
  useEffect(() => {
    itemId !== undefined ? setDeleteItemId(itemId) : setDeleteItemId(0);
  }, [itemId]);

  const deleteSummaryDateBody = {
    caseId: caseid,
    patientSectionSummaryId: delItemid,
  } as unknown as DeleteSummaryListSubmitBody;

  const deleteItem = () => {
    dispatch(deleteSummaryList(deleteSummaryDateBody));
  };

  const deletePatientDataListRes = useSelector((state: AppState) => state.deleteDoctorSummaryList);

  const [deletePatientDataMsgType, setDeletePatientDataMsgType] = useState<AlertColor>("success");
  const [deletePatientDataErrorMsg, setDeletePatientDataErrorMsg] = useState("");

  const getDoctorSummaryDetails = {
    caseId: caseid,
    secondOpinionUiSection: type,
  } as GetSummeryListSubmitBody;

  useEffect(() => {
    if (deletePatientDataListRes?.successMsg !== "") {
      dispatch(getDoctorSummaryList(getDoctorSummaryDetails));
      handleClose();
    } else if (deletePatientDataListRes?.errorMsg !== "") {
      setDeletePatientDataErrorMsg(deletePatientDataListRes?.errorMsg);
      setDeletePatientDataMsgType("error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletePatientDataListRes]);

  return (
    <Dialog open={dialogOpen} fullWidth>
      {deletePatientDataListRes?.loading ? <MWLoader /> : null}
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            {t("appointmentpage.del", { ns: ["home"] })}{" "}
            {t(`filePreparationTab.${docTypeName}`, { ns: ["home"] })}
          </Grid>
          <Grid item>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>{t("common.wantDeleteThis", { ns: ["home"] })!} ?</DialogContent>
      <Divider />
      {deletePatientDataErrorMsg !== "" ? (
        <Alert severity={deletePatientDataMsgType} onClose={() => {}}>
          {deletePatientDataErrorMsg}
        </Alert>
      ) : null}
      <DialogActions>
        <Button variant="outlined" onClick={() => handleClose()} sx={{ textTransform: "none" }}>
          {t("common.no", { ns: ["home"] })!}
        </Button>
        <Button
          variant="contained"
          onClick={() => deleteItem()}
          disabled={deletePatientDataListRes?.loading}
          sx={{ textTransform: "none" }}
        >
          {t("filePrepPage.yes", { ns: ["home"] })!}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
