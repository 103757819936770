import { DeactivateMeetingRes } from "./Model";
export const DEACTIVATE_MEETING_LOADING =
  "DEACTIVATE_MEETING_LOADING";
export const DEACTIVATE_MEETING_SUCCESS =
  "DEACTIVATE_MEETING_SUCCESS";
export const DEACTIVATE_MEETING_FAIL =
  "DEACTIVATE_MEETING_FAIL";
export const DEACTIVATE_MEETING_API_MSG =
  "DEACTIVATE_MEETING_API_MSG";
export const DEACTIVATE_MEETING_API_RES =
  "DEACTIVATE_MEETING_API_RES";

export interface DeactivateMeetingState {
  deactivateMeetingRes: DeactivateMeetingRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface DeactivateMeetingLoading {
  type: typeof DEACTIVATE_MEETING_LOADING;
  loading: boolean;
}
export interface DeactivateMeetingSuccess {
  type: typeof DEACTIVATE_MEETING_SUCCESS;
  payload: DeactivateMeetingRes;
  successMsg: string;
}
export interface DeactivateMeetingFail {
  type: typeof DEACTIVATE_MEETING_FAIL;
  payload: DeactivateMeetingRes;
  errorMsg: string;
  status: number;
}
export interface DeactivateMeetingAPIMsg {
  type: typeof DEACTIVATE_MEETING_API_MSG;
  payload: DeactivateMeetingRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface DeactivateMeetingAPIRes {
  type: typeof DEACTIVATE_MEETING_API_RES;
  payload: DeactivateMeetingRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type DeactivateMeetingActionTypes =
  | DeactivateMeetingLoading
  | DeactivateMeetingSuccess
  | DeactivateMeetingFail
  | DeactivateMeetingAPIMsg
  | DeactivateMeetingAPIRes;
