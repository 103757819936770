import React from "react";
import DoctorEducationalPage from "./EducationalDetails/EducationDetails/DoctorEducationalPage";
import EducationalSummary from "./EducationalDetails/EducationalSummary";
import { Stack } from "@mui/material";
export interface Props {
  educationDetailsLoading: boolean | any;
}
export default function DoctorEducationalDetails({ educationDetailsLoading }: Props) {
  const updateEducationSummaryLoadingValueFromChild = (passedVal: boolean) => {
    educationDetailsLoading(passedVal);
  };
  const updateEducationListLoadingValueFromChild = (passedVal: boolean) => {
    educationDetailsLoading(passedVal);
  };
  return (
    <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
      <EducationalSummary educationSummaryLoading={updateEducationSummaryLoadingValueFromChild} />
      <DoctorEducationalPage educationListLoading={updateEducationListLoadingValueFromChild} />
    </Stack>
  );
}
