import React from "react";
import { Button, Container, Divider, Grid, Stack, Typography } from "@mui/material";
import LoginWithPassword from "./LoginWithPassword";
import OnboardingLayout from "./OnboardingLayout";
import { t } from "i18next";

export default function Login() {
  return (
    <Container maxWidth="xl">
      <OnboardingLayout>
        <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
          <Divider />
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Typography color="textPrimary" variant="body1" display="inline">
                <b>{t("home.newuser", { ns: ["home"] })} </b>
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="secondary"
                href="/signup"
                sx={{ textTransform: "none" }}
              >
                {t("home.register", { ns: ["home"] })!}
              </Button>
            </Grid>
          </Grid>
          <LoginWithPassword />
        </Stack>
      </OnboardingLayout>
    </Container>
  );
}
