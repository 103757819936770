import React, { useState, useEffect } from "react";
import { Alert, Box, Card, CardHeader, Divider, Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import { getPaymentLinkApi } from "redux/AddDoctorTeam/GetPaymentLink/Api";
import MWLoader from "components/MWLoader";

export default function AppoinmentBooking() {
  const { caseid } = useParams() as {
    caseid: string;
  };
  const dispatch = useDispatch();
  const [consultationType, setConsultationType] = useState("");
  const [doctorTeamType, setDoctorTeamType] = useState("");
  const [responseTimeType, setResponseTimeType] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const loadingMarkup = isLoading ? <MWLoader /> : null;

  const selectionTypeBody = {
    caseId: caseid,
  };

  useEffect(() => {
    dispatch(getPaymentLinkApi(selectionTypeBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Selection Type GET API
  const getPaymentLinkValueFromStore = useSelector((state: AppState) => state.DoctorpaymentLink);
  useEffect(() => {
    if (getPaymentLinkValueFromStore !== undefined) {
      const doctorTeamTypeList =
        getPaymentLinkValueFromStore.getPaymentLinkRes.doctorTeamType === ""
          ? "Not Selected"
          : getPaymentLinkValueFromStore.getPaymentLinkRes.doctorTeamType;
      setDoctorTeamType(doctorTeamTypeList);

      const doctorConsultationType =
        getPaymentLinkValueFromStore.getPaymentLinkRes.consultationType === ""
          ? "Not Selected"
          : getPaymentLinkValueFromStore.getPaymentLinkRes.consultationType;
      setConsultationType(doctorConsultationType);

      const doctorResponseTimeType =
        getPaymentLinkValueFromStore.getPaymentLinkRes.responseTimeType === ""
          ? "Not Selected"
          : getPaymentLinkValueFromStore.getPaymentLinkRes.responseTimeType;
      setResponseTimeType(doctorResponseTimeType);
    }
    setIsLoading(getPaymentLinkValueFromStore?.loading);
    getPaymentLinkValueFromStore?.loading ? setIsLoading(true) : setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPaymentLinkValueFromStore]);

  const selectionOptionMarkup = (
    <Card>
      <Box p={2}>
        <CardHeader title={t("appointmentpage.selopt", { ns: ["home"] })!} />
        <Box pt={4}>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item>
              <Typography variant="h6" gutterBottom>
                {t("docdashboardpage.constype", { ns: ["home"] })!}
              </Typography>
              <Divider />
              <Box mt={2}>
                <Alert variant="outlined" severity="error">
                  {consultationType}
                </Alert>
              </Box>
            </Grid>

            <Grid item>
              <Typography variant="h6" gutterBottom>
                {t("appointmentpage.resptime", { ns: ["home"] })!}
              </Typography>
              <Divider />
              <Box mt={2}>
                <Alert variant="outlined" severity="error">
                  {responseTimeType}
                </Alert>
              </Box>
            </Grid>

            <Grid item>
              <Typography variant="h6" gutterBottom>
                {t("appointmentpage.docseltype", { ns: ["home"] })!}
              </Typography>
              <Divider />
              <Box mt={2}>
                <Alert variant="outlined" severity="error">
                  {doctorTeamType}
                </Alert>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Card>
  );

  return (
    <Box sx={{ py: 2 }}>
      {loadingMarkup}
      {selectionOptionMarkup}
    </Box>
  );
}
