import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { Box, Button, Card, CardContent, CardHeader, Grid, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ArticleIcon from "@mui/icons-material/Article";
import RefreshIcon from "@mui/icons-material/Refresh";
import MWExceptionList from "components/MWExceptionList";
import { useState } from "react";
import { AppState } from "redux/store/Store";
import { t } from "i18next";
import dayjs from "dayjs";
import DeleteFinalOpinion from "../DeleteFinalOpinion";
import AddPrescription from "../AddPrescription";
import AddFinalOpinionDetails from "../AddFinalOpinionDetails";
import { getFinalOpinionDetails } from "../../../redux/FinalOpinion/FinalOpinionTable/GetFinalOpinionList/API";
import DocumentViewer from "components/DocumentViewer";
import { ModifiedFinalOpinion } from "./Model";
import { PatientDetailList } from "../../../redux/FinalOpinion/FinalOpinionTable/GetFinalOpinionList/Model";

type Props = {
  caseId?: string | any;
  patientId?: string | any;
};

export default function FinalOpinionTable({ caseId, patientId }: Props) {
  const dispatch = useDispatch();
  const [finalOpinionList, setFinalOpinionList] = useState<ModifiedFinalOpinion[]>(
    [] as ModifiedFinalOpinion[],
  );
  const getFinalOpinionListRes = useSelector(
    (state: AppState) => state.getFinalOpinionListResValue,
  );

  const getSecondOpinionBody = {
    caseId: caseId,
    secondOpinionUiSection: "finalOpinion",
  };

  React.useEffect(() => {
    dispatch(getFinalOpinionDetails(getSecondOpinionBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  React.useEffect(() => {
    if (getFinalOpinionListRes?.getFinalOpinionListRes?.patientDetailList !== undefined) {
      if (getFinalOpinionListRes?.getFinalOpinionListRes?.patientDetailList.length > 0) {
        let finalOpinionListItem =
          getFinalOpinionListRes?.getFinalOpinionListRes?.patientDetailList.map(
            (element: PatientDetailList) =>
              ({
                id: element.id,
                supportingDocument:
                  element?.patientDocument !== undefined
                    ? element?.patientDocument !== null
                      ? element?.patientDocument?.document !== undefined
                        ? element?.patientDocument?.document
                        : null
                      : null
                    : null,
                description: element.description,
                prescribeDate:
                  element?.startDate !== null
                    ? dayjs(element.startDate).format("DD-MM-YYYY")
                    : "Not Set",
              } as ModifiedFinalOpinion),
          );
        setFinalOpinionList(finalOpinionListItem);
      } else setFinalOpinionList([] as ModifiedFinalOpinion[]);
    } else setFinalOpinionList([] as ModifiedFinalOpinion[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFinalOpinionListRes]);

  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [showAddPrescriptionDialog, setShowAddPrescriptionDialog] = React.useState(false);
  const [showAddFinalOpinionDialog, setShowAddFinalOpinionDialog] = React.useState(false);
  const [patientDetailsId, setPatientDetailsId] = React.useState("");
  const deletePrescription = (id: string) => {
    setShowDeleteDialog(true);
    setPatientDetailsId(id);
  };

  const updateAddPatientDialog = (passedVal: boolean) => {
    setShowDeleteDialog(passedVal);
  };

  const addPrescriptionDialogState = () => {
    setShowAddPrescriptionDialog(true);
  };

  const updateAddPrescriptionDialog = (passedVal: boolean) => {
    setShowAddPrescriptionDialog(passedVal);
  };

  const handleFinalOpinionDialog = () => {
    setShowAddFinalOpinionDialog(true);
  };

  const updateAddFinalOpinionDialog = (passedVal: boolean) => {
    setShowAddFinalOpinionDialog(passedVal);
  };

  const columns: GridColDef[] = [
    {
      field: "supportingDocuments",
      headerName: `${t("appointmentpage.doc", { ns: ["home"] })!}`,
      width: 100,
      align: "left",
      renderCell: (params: GridCellParams) => (
        <Button
          onClick={() => {
            setViewFinalOpinionDocumentDialog(true);
            setEditItem(params.row);
          }}
          variant="outlined"
          disabled={params.row.supportingDocuments === null ? true : false}
          sx={{ textTransform: "none" }}
        >
          <ArticleIcon />
        </Button>
      ),
    },
    {
      field: "description",
      headerName: `${t("appointmentpage.desc", { ns: ["home"] })!}`,
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "prescribeDate",
      headerName: `${t("filePrepPage.preDate", { ns: ["home"] })!}`,
      type: "string",
      width: 120,
      align: "left",
    },
    {
      field: "id",
      headerName: `${t("appointmentpage.del", { ns: ["home"] })!}`,
      width: 100,
      align: "left",
      renderCell: (params: GridCellParams) => (
        <Button
          onClick={() => {
            deletePrescription(params.row.id);
          }}
          variant="outlined"
          sx={{ textTransform: "none" }}
        >
          <DeleteIcon />
        </Button>
      ),
    },
  ];
  const reloadAction = () => {
    dispatch(getFinalOpinionDetails(getSecondOpinionBody));
  };

  const [viewFinalOpinionDocumentDialog, setViewFinalOpinionDocumentDialog] = useState(false);
  const updateViewFinalOpinionDocumentDialogFromChild = (passedVal: boolean) => {
    setViewFinalOpinionDocumentDialog(passedVal);
  };

  const [editItem, setEditItem] = useState({} as any);
  const selectedItemUpdateState = (passedVal: any) => {
    setEditItem(passedVal);
  };

  const finalOpinionTable = (
    <Card>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item>{t("appointmentpage.finalopinion", { ns: ["home"] })!}</Grid>
                <Grid item>
                  <IconButton onClick={() => reloadAction()}>
                    <RefreshIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={addPrescriptionDialogState}
                    sx={{ textTransform: "none" }}
                  >
                    {t("appointmentpage.addpres", { ns: ["home"] })!}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={handleFinalOpinionDialog}
                    sx={{ textTransform: "none" }}
                  >
                    {t("appointmentpage.addfinaldetail", { ns: ["home"] })!}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      ></CardHeader>
      <CardContent>
        {finalOpinionList.length > 0 ? (
          <DataGrid
            rows={finalOpinionList}
            columns={columns}
            hideFooter
            hideFooterPagination
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );
  return (
    <Box>
      {finalOpinionTable}
      {viewFinalOpinionDocumentDialog ? (
        <DocumentViewer
          dialogEnable={viewFinalOpinionDocumentDialog}
          dialogUpdateState={updateViewFinalOpinionDocumentDialogFromChild}
          selectedItem={editItem}
          selectedItemUpdateState={selectedItemUpdateState}
        />
      ) : null}
      {showDeleteDialog ? (
        <DeleteFinalOpinion
          itemId={caseId}
          patientDetailsID={patientDetailsId}
          deleteDialogEnable={showDeleteDialog}
          deleteDialogUpdateState={updateAddPatientDialog}
        />
      ) : null}
      {showAddPrescriptionDialog ? (
        <AddPrescription
          itemId={caseId}
          addPrescriptionDialogEnable={showAddPrescriptionDialog}
          addPrescriptionDialogUpdateState={updateAddPrescriptionDialog}
        />
      ) : null}
      {showAddFinalOpinionDialog ? (
        <AddFinalOpinionDetails
          itemId={caseId}
          patientId={patientId}
          addFinalOpinionDetailsEnable={showAddFinalOpinionDialog}
          addFinalOpinionDetailsDialogUpdateState={updateAddFinalOpinionDialog}
        />
      ) : null}
    </Box>
  );
}
