import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MWUploader from "components/MWUploader";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import { AddSignatureBody } from "redux/AccountDetails/DoctorSignature/AddSignatureApi/Model";
import { GetDoctorDetailsRes } from "redux/jwtDoctorLogIn/doctorLoginDetails/Model";
import {
  addSignatureDetails,
  clearAddDoctorSignatureDetailsAPIRes,
} from "redux/AccountDetails/DoctorSignature/AddSignatureApi/API";
import { GetDoctorSignatureBody } from "redux/AccountDetails/DoctorSignature/GetDoctorSignature/Model";
import { getSignatureDetails } from "redux/AccountDetails/DoctorSignature/GetDoctorSignature/ApiCall";

export default function Signature() {
  const { t } = useTranslation(["home"]);
  const dispatch = useDispatch();
  const getSignatureDetailsRes = useSelector((state: AppState) => state.getSignatureDetailsRes);
  // upload image
  const [imageId, setImageId] = useState("");
  const [imageInfo] = useState("");
  // delete selected image from image uploader
  const delImgAction = (_passeVal: boolean) => {};
  const [checkImgUpload, setCheckImgUpload] = useState(true);
  // Get PreviewImg Details
  const viewPreviewImageData = (passedVal: any) => {
    setCheckImgUpload(passedVal);
  };
  const updateDataStateFromChild = async (selectedImgId: any) => {
    selectedImgId !== undefined ? setImageId(String(selectedImgId)) : setImageId("");
  };
  useEffect(() => {}, [imageId, checkImgUpload]);

  const [signatureUploadErrorMsg, setSignatureUploadErrorMsg] = useState("");

  let userDetails = JSON.parse(localStorage.getItem("loginDetails")!) as GetDoctorDetailsRes;

  const addSignaturePayload = {
    doctorId: userDetails?.doctorId,
    signatureId: imageId,
  } as AddSignatureBody;

  const submitDoctorSignature = () => {
    if (imageId !== "") {
      setSignatureUploadErrorMsg("");
      dispatch(addSignatureDetails(addSignaturePayload));
    } else {
      setSignatureUploadErrorMsg(t("accountdetail.notSelImg", { ns: ["home"] })!);
    }
  };
  const addSignatureDetailsRes = useSelector((state: AppState) => state.addSignatureDetailsRes);
  const addDrSignatureRes = useSelector((state: AppState) => state.addSignatureDetailsRes);
  const [addDrSignatureMsg, setAddDrSignatureMsg] = useState("");
  const [addDrSignatureMsgType, setAddDrSignatureMsgType] = useState<AlertColor>("success");

  useEffect(() => {
    if (addSignatureDetailsRes?.successMsg !== "") {
      setAddDrSignatureMsgType("success");
      setAddDrSignatureMsg(addSignatureDetailsRes?.successMsg);
      dispatch(
        getSignatureDetails({
          doctorId: userDetails?.doctorId!,
        } as GetDoctorSignatureBody),
      );
    }
    if (addSignatureDetailsRes?.errorMsg !== "") {
      setAddDrSignatureMsgType("error");
      setAddDrSignatureMsg(addSignatureDetailsRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addSignatureDetailsRes]);
  return (
    <Box>
      <Card>
        <CardHeader
          title={
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {t("accountdetail.signature", { ns: ["home"] })!}
            </Typography>
          }
        />
        <CardContent>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box style={{ border: "1px #DE3618 solid", borderRadius: "3px" }} mb={2}>
                <MWUploader
                  documentType="doctorSignature"
                  criteria={t("userprofilepage.awarddocsize", { ns: ["home"] })!}
                  updatedDataState={updateDataStateFromChild}
                  title={t("accountdetail.doctorsig", { ns: ["home"] })!}
                  visibility={false}
                  imageUrl={imageInfo}
                  deleteImgAction={delImgAction}
                  previewImageData={viewPreviewImageData}
                />
              </Box>
              {signatureUploadErrorMsg !== "" ? (
                <Box my={2}>
                  <Alert
                    severity={"error"}
                    onClose={() => {
                      setSignatureUploadErrorMsg("");
                    }}
                  >
                    {signatureUploadErrorMsg}
                  </Alert>
                </Box>
              ) : null}

              <Button
                variant="contained"
                onClick={() => submitDoctorSignature()}
                sx={{ textTransform: "none" }}
                disabled={checkImgUpload || addDrSignatureRes?.loading}
              >
                {t("accountdetail.addsig", { ns: ["home"] })!}
              </Button>
              {addDrSignatureMsg !== "" ? (
                <Box my={2}>
                  <Alert
                    severity={addDrSignatureMsgType}
                    onClose={() => {
                      dispatch(clearAddDoctorSignatureDetailsAPIRes());
                      setAddDrSignatureMsg("");
                    }}
                  >
                    {addDrSignatureMsg}
                  </Alert>
                </Box>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box
                style={{
                  border: "1px #DE3618 solid",
                  borderRadius: "3px",
                  minHeight: "260px",
                  padding: "5px",
                }}
              >
                {getSignatureDetailsRes?.getDoctorSignatureDetailsRes?.Signature
                  ?.doctorSignature !== undefined ? (
                  getSignatureDetailsRes?.getDoctorSignatureDetailsRes?.Signature
                    ?.doctorSignature !== null ? (
                    <img
                      src={
                        getSignatureDetailsRes?.getDoctorSignatureDetailsRes?.Signature
                          ?.doctorSignature?.document
                      }
                      alt=""
                      width="100%"
                      height="200px"
                    />
                  ) : null
                ) : null}
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}
