import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
} from "@mui/material";
import MWExceptionList from "components/MWExceptionList";
import { t } from "i18next";
import {
  CreateScheduleMeetingApi,
  clearScheduleMeetingAPIRes,
} from "redux/CreateMeeting/createScheduleMeeting/API";
import {
  CreateInstantMeetingApi,
  clearCreateMeetingAPIRes,
} from "redux/CreateMeeting/createInstantmeeting/API";
import { useDispatch, useSelector } from "react-redux";
import { DesktopDateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { meetingListApi } from "redux/PatientDoctorInteraction/MeetingList/API";
import { MeetingListBody } from "redux/PatientDoctorInteraction/MeetingList/Model";
import { AppState } from "redux/store/Store";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import NoMeetingRoomIcon from "@mui/icons-material/NoMeetingRoom";
import ComputerIcon from "@mui/icons-material/Computer";
import { ModifiedMeetingList } from "./Model";
import { GetDoctorDetailsRes } from "redux/jwtDoctorLogIn/doctorLoginDetails/Model";
import { CreateScheduleMeetingBody } from "redux/CreateMeeting/createScheduleMeeting/Model";
import { CreateInstantMeetingBody } from "redux/CreateMeeting/createInstantmeeting/Model";
import {
  clearDeactivateMeetingAPIRes,
  deactivateMeeting,
} from "redux/Telehealth/DeactivateMeeting/API";
import { DeactivateMeetingBody } from "redux/Telehealth/DeactivateMeeting/Model";

export default function ExistingMeetingList() {
  const dispatch = useDispatch();
  const [isScheduleMeeting, setIsScheduleMeeting] = useState(false);
  const [isCreateMeeting, setIsCreateMeeting] = useState(false);
  const [meetingList, setMeetingList] = useState([] as any[]);

  const meetingListApiValue = useSelector((state: AppState) => state.meetingListValue);
  const meetingListTableColumns: GridColDef[] = [
    {
      field: "startMeetingUrl",
      headerName: `${t("telehealthpage.startMeeting", { ns: ["home"] })}`,
      width: 200,
      align: "center",
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<MeetingRoomIcon />}
          variant="outlined"
          onClick={() => {
            window.open(params.row.startMeetingUrl);
          }}
        >
          {t("telehealthpage.startMeeting", { ns: ["home"] })}
        </Button>
      ),
    },
    {
      field: "id",
      headerName: `${t("telehealthpage.meetingId", { ns: ["home"] })}`,
      width: 100,
    },
    {
      field: "displayName",
      headerName: `${t("appointmentpage.appoinmentname", { ns: ["home"] })!}`,
      width: 200,
      flex: 1,
    },
    {
      field: "appointmentDate",
      headerName: `${t("telehealthpage.appointmentDateTime", { ns: ["home"] })}`,
      width: 200,
    },
    {
      field: "dateCreated",
      headerName: `${t("appointmentpage.createdate", { ns: ["home"] })}`,
      width: 200,
    },
    {
      field: "joinMeetingUrl",
      headerName: `${t("telehealthpage.joinMeeting", { ns: ["home"] })}`,
      width: 220,
      align: "center",
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<ComputerIcon />}
          variant="outlined"
          onClick={() => {
            window.open(params.row.joinMeetingUrl);
          }}
        >
          {t("telehealthpage.joinMeeting", { ns: ["home"] })}
        </Button>
      ),
    },
    {
      field: "isActive",
      headerName: `Deactivate Meeting`,
      width: 300,
      align: "center",
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<NoMeetingRoomIcon />}
          variant="outlined"
          disabled={deactivateMeetingRes?.loading}
          onClick={() => {
            dispatch(
              deactivateMeeting({
                teleHealthAppointmentId: String(params.row.id),
              } as DeactivateMeetingBody),
            );
          }}
        >
          {t("telehealthpage.deactivateMeeting", { ns: ["home"] })}
        </Button>
      ),
    },
  ];

  const [selectedDate, setSelectedDate] = React.useState<Date | null>(new Date());
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  let userDetails = JSON.parse(localStorage.getItem("loginDetails")!) as GetDoctorDetailsRes;

  const DoctorID = userDetails !== undefined ? userDetails?.doctorId : null;

  const { caseid } = useParams() as {
    caseid: string;
  };

  // Schedule Meeting Body
  const scheduleMeetingBody = {
    doctorId: String(DoctorID)!,
    caseId: String(caseid),
    starttime:
      selectedDate !== null
        ? String(dayjs(selectedDate).format("YYYY-MM-DDTHH:mm:ss.SSS")) + "Z"
        : null,
  } as CreateScheduleMeetingBody;

  const meetingListBody = {
    caseId: caseid,
  } as MeetingListBody;

  // Schedule Meeting API
  const scheduleMeeting = () => {
    setIsScheduleMeeting(true);
    dispatch(CreateScheduleMeetingApi(scheduleMeetingBody));
  };

  // Create Meeting Body
  const createMeetingBody = {
    doctorId: String(DoctorID)!,
    caseId: String(caseid),
    starttime:
      selectedDate !== null
        ? String(dayjs(selectedDate).format("YYYY-MM-DDTHH:mm:ss.SSS")) + "Z"
        : null,
  } as CreateInstantMeetingBody;

  // Create Meeting API
  const createMeeting = () => {
    setIsCreateMeeting(true);
    dispatch(CreateInstantMeetingApi(createMeetingBody));
  };

  const roloadMeetingList = () => {
    dispatch(meetingListApi(meetingListBody));
  };

  useEffect(() => {
    dispatch(meetingListApi(meetingListBody));
    // eslint-disable-next-line
  }, []);
  const [createScheduleMeetingMsg, setCreateScheduleMeetingMsg] = useState("");
  const [createScheduleMeetingMsgType, setCreateScheduleMeetingMsgType] =
    useState<AlertColor>("success");

  const createScheduleMeetingRes = useSelector((state: AppState) => state.createScheduleMeeting);
  useEffect(() => {
    if (createScheduleMeetingRes?.successMsg !== "") {
      setCreateScheduleMeetingMsgType("success");
      setCreateScheduleMeetingMsg(createInstantMeetingRes?.successMsg);
      dispatch(meetingListApi(meetingListBody));
    }
    if (createScheduleMeetingRes?.errorMsg !== "") {
      setCreateScheduleMeetingMsgType("error");
      setCreateScheduleMeetingMsg(createInstantMeetingRes?.successMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createScheduleMeetingRes]);

  const createInstantMeetingRes = useSelector((state: AppState) => state.createInstantMeeting);
  useEffect(() => {
    if (createInstantMeetingRes?.successMsg !== "") {
      setCreateScheduleMeetingMsgType("success");
      setCreateScheduleMeetingMsg(createInstantMeetingRes?.successMsg);
      dispatch(meetingListApi(meetingListBody));
    }
    if (createInstantMeetingRes?.errorMsg !== "") {
      setCreateScheduleMeetingMsgType("error");
      setCreateScheduleMeetingMsg(createInstantMeetingRes?.successMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createInstantMeetingRes]);

  const deactivateMeetingRes = useSelector((state: AppState) => state.deactivateMeetingRes);
  const [deactivateMeetingMsg, setDeactivateMeetingMsg] = useState("");
  const [deactivateMeetingMsgType, setDeactivateMeetingMsgType] = useState<AlertColor>("success");
  useEffect(() => {
    if (deactivateMeetingRes?.successMsg !== "") {
      setDeactivateMeetingMsgType("success");
      setDeactivateMeetingMsg(deactivateMeetingRes?.successMsg);
      dispatch(meetingListApi(meetingListBody));
    }
    if (deactivateMeetingRes?.errorMsg !== "") {
      setDeactivateMeetingMsgType("error");
      setDeactivateMeetingMsg(deactivateMeetingRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deactivateMeetingRes]);

  useEffect(() => {
    if (meetingListApiValue?.meetingListResponse?.meetingList !== undefined) {
      let allMeetingListTableValues = meetingListApiValue?.meetingListResponse?.meetingList.map(
        (element: ModifiedMeetingList) => ({
          startMeetingUrl: element.startMeetingUrl !== "" ? element.startMeetingUrl : "",
          id: element.id,
          displayName: element.displayName !== "" ? element.displayName : "",
          appointmentDate:
            element.appointmentDate !== ""
              ? dayjs(element.appointmentDate).format("MM-DD-YYYY")
              : "",
          dateCreated:
            element.dateCreated !== "" ? dayjs(element.dateCreated).format("MM-DD-YYYY") : "",
          joinMeetingUrl: element.joinMeetingUrl !== "" ? element.joinMeetingUrl : "",
          isActive: element.isActive === true ? element.isActive : false,
          teleHealthAppointmentId: "",
          teleHealthAppointmentPwd: "",
          teleHealthApp: 0,
        }),
      );
      setMeetingList(allMeetingListTableValues);
    } else setMeetingList([] as ModifiedMeetingList[]);
    // eslint-disable-next-line
  }, [meetingListApiValue]);

  const appointmentTableBody = (
    <Card>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>{t("appointmentpage.existmeetdetail", { ns: ["home"] })!}</Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDateTimePicker
                      inputFormat="DD/MM/YYYY HH:mm"
                      value={selectedDate}
                      onChange={(date) => handleDateChange(date)}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                            readOnly: true,
                          }}
                          size="small"
                          variant="outlined"
                          sx={{
                            svg: { color: "#DE3618" },
                            input: { color: "#DE3618" },
                            label: { color: "#DE3618" },
                          }}
                        />
                      )}
                      InputAdornmentProps={{ position: "end" }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item>
                  <Button variant="outlined" disabled={isScheduleMeeting} onClick={scheduleMeeting}>
                    {t("appointmentpage.schmeet", { ns: ["home"] })!}
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" disabled={isCreateMeeting} onClick={createMeeting}>
                    {t("appointmentpage.createmeet", { ns: ["home"] })!}
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="outlined" onClick={roloadMeetingList}>
                    {t("telehealthpage.reloadmeetlist", { ns: ["home"] })!}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      ></CardHeader>
      <CardContent>
        {createScheduleMeetingMsg !== "" ? (
          <Box my={2}>
            <Alert
              severity={createScheduleMeetingMsgType}
              onClose={() => {
                dispatch(clearCreateMeetingAPIRes());
                dispatch(clearScheduleMeetingAPIRes());
                setCreateScheduleMeetingMsg("");
              }}
            >
              {createScheduleMeetingMsg}
            </Alert>
          </Box>
        ) : null}
        {deactivateMeetingMsg !== "" ? (
          <Box my={2}>
            <Alert
              severity={deactivateMeetingMsgType}
              onClose={() => {
                dispatch(clearDeactivateMeetingAPIRes());
                setDeactivateMeetingMsg("");
              }}
            >
              {deactivateMeetingMsg}
            </Alert>
          </Box>
        ) : null}
        {meetingListApiValue?.meetingListResponse?.meetingList !== undefined &&
        meetingListApiValue?.meetingListResponse?.meetingList.length > 0 ? (
          <DataGrid
            rows={meetingList}
            columns={meetingListTableColumns}
            hideFooter
            hideFooterPagination
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            getRowId={(row) => row?.id}
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  return <>{appointmentTableBody}</>;
}
