import { Calendar, dayjsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import dayjs from "dayjs";
import PageLayout from "components/MWPageLayout";
import MWPageTitle from "components/MWPageTitle";
import { t } from "i18next";
import { Card, CardContent } from "@mui/material";
import React from "react";

const localizer = dayjsLocalizer(dayjs);

export default function DoctorCalendar() {
  const events: any[] = [
    {
      title: "Event 1",
      start: dayjs().add(1, "day").toDate(),
      end: dayjs().add(2, "days").toDate(),
    },
    {
      title: "Event 2",
      start: dayjs().add(3, "days").toDate(),
      end: dayjs().add(4, "days").toDate(),
    },
  ];

  return (
    <PageLayout>
      <MWPageTitle title={t("Workschedulepage.schedule", { ns: ["home"] })!} />
      <Card>
        <CardContent>
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ minHeight: "500px", maxHeight: "2160px" }}
          />
        </CardContent>
      </Card>
    </PageLayout>
  );
}
