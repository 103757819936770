import axios from "axios";
import { Dispatch } from "redux";
import apiURL from "../../../URL";
import { setupInterceptorsTo } from "../../authentication/Interceptors";
import {
  setDrTeleHealthCredentialsLoadingAction,
  setDrTeleHealthCredentialsSuccessAction,
  setDrTeleHealthCredentialsErrorAction,
  setDrTeleHealthCredentialsAPIMsgAction,
  setDrTeleHealthCredentialsAPIResClearAction,
} from "./Action";
import { ScheduleMeetingActionTypes } from "./ActionTypes";
import { CreateScheduleMeetingValue, CreateScheduleMeetingBody } from "./Model";
import { headers } from "components/Utility";

let apiRes = {} as CreateScheduleMeetingValue;
export const CreateScheduleMeetingApi = (payload: CreateScheduleMeetingBody) => {
  return function (dispatch: Dispatch<ScheduleMeetingActionTypes>) {
    dispatch(setDrTeleHealthCredentialsLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(apiURL.apiURL + `/telehealthapp/api/telehealth/createScheduleMeeting`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(setDrTeleHealthCredentialsLoadingAction(false));
        dispatch(
          setDrTeleHealthCredentialsSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Schedule meeting has been created successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(setDrTeleHealthCredentialsLoadingAction(false));
        dispatch(
          setDrTeleHealthCredentialsErrorAction(
            {} as CreateScheduleMeetingValue,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateScheduleMeetingAPIResMsg = () => {
  return function (dispatch: Dispatch<ScheduleMeetingActionTypes>) {
    dispatch(
      setDrTeleHealthCredentialsAPIMsgAction(apiRes as CreateScheduleMeetingValue, "", "", 0),
    );
  };
};

export const clearScheduleMeetingAPIRes = () => {
  return function (dispatch: Dispatch<ScheduleMeetingActionTypes>) {
    dispatch(
      setDrTeleHealthCredentialsAPIResClearAction({} as CreateScheduleMeetingValue, "", "", 0),
    );
  };
};
