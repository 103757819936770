import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertColor,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Transition } from "components/MWDialogTransition";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ModifiedLegalDetails } from "../Model";
import MWUploader from "components/MWUploader";
import { AppState } from "redux/store/Store";
import { LegalTypeList } from "redux/UserProfile/LegalDetails/LegalDetails/LegalTypeList/Model";
import dayjs, { Dayjs } from "dayjs";
import { AddLegalDetailsBody } from "redux/UserProfile/LegalDetails/LegalDetails/AddLegalDetailsApi/Model";
import {
  addDrLegalDetails,
  clearAddDrLegalDetailsAPIRes,
} from "redux/UserProfile/LegalDetails/LegalDetails/AddLegalDetailsApi/API";
import {
  clearEditDrLegalDetailsAPIRes,
  editDrLegalDetails,
} from "redux/UserProfile/LegalDetails/LegalDetails/EditLegalDetailsApi/API";
import { EditLegalDetailsBody } from "redux/UserProfile/LegalDetails/LegalDetails/EditLegalDetailsApi/Model";
import { getLegalDetails } from "redux/UserProfile/LegalDetails/LegalDetails/GetLegalDetailsByDoctor/API";
import MWLoader from "components/MWLoader";
import { clearImageUploadAPIRes } from "redux/ImageUpload/API";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
export interface Props {
  dialogEnable?: boolean | any;
  dialogUpdateState: boolean | any;
  selectedItem?: ModifiedLegalDetails | any;
  selectedItemUpdateState: any;
}
export default function AddDoctorLegalDetails({
  dialogEnable,
  dialogUpdateState,
  selectedItem,
  selectedItemUpdateState,
}: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation(["home"]);

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [activeResetPasswordDialog, setActiveResetPasswordDialog] = useState(false);

  const closeDialog = () => {
    setActiveResetPasswordDialog(false);
    dialogUpdateState(false);
    setDescription("");
    setSelectedLegalType(null);
    dispatch(clearImageUploadAPIRes());
    setImageId("");
    setSelectedDate(dayjs(new Date()));
    setSelectedEndDate(dayjs(new Date()));
    selectedItemUpdateState({} as any);
    dispatch(clearAddDrLegalDetailsAPIRes());
    setAddDrLegalDetailsMsg("");
    dispatch(clearEditDrLegalDetailsAPIRes());
    setEditLegalDetailsMsg("");
  };
  useEffect(() => {
    setActiveResetPasswordDialog(dialogEnable);
  }, [dialogEnable]);

  const getLegalTypeListRes = useSelector((state: AppState) => state.getLegalTypeListRes);
  const [legalTypeList, setLegalTypeList] = useState([] as LegalTypeList[]);
  const [selectedLegalType, setSelectedLegalType] = useState<LegalTypeList | null>(null);
  const selectedLegalTypeChange = (_event: any, passedVal: LegalTypeList | any) => {
    setSelectedLegalType(passedVal);
  };
  useEffect(() => {
    if (getLegalTypeListRes?.getDoctorLegalTypeListRes?.legalTypeList !== undefined) {
      if (getLegalTypeListRes?.getDoctorLegalTypeListRes?.legalTypeList.length > 0) {
        setLegalTypeList(getLegalTypeListRes?.getDoctorLegalTypeListRes?.legalTypeList);
      } else setLegalTypeList([] as LegalTypeList[]);
    } else setLegalTypeList([] as LegalTypeList[]);
  }, [getLegalTypeListRes]);

  const [pendingCaseCheck, setPendingCaseCheck] = React.useState("No");
  const handlePendingCaseCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPendingCaseCheck((event.target as HTMLInputElement).value);
  };

  const [deathCaseCheck, setDeathCaseCheck] = React.useState("No");
  const handleDeathCaseCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeathCaseCheck((event.target as HTMLInputElement).value);
  };

  const [description, setDescription] = useState("");
  const descriptionChange = (event: string) => {
    setDescription(event);
  };

  // from Date
  const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(dayjs(new Date()));
  const handleDateChange = (date: Dayjs | null) => {
    setSelectedDate(date);
  };

  // to Date
  const [endDate, setSelectedEndDate] = React.useState<Dayjs | null>(null);
  const handleToDateChange = (date: Dayjs | null) => {
    setSelectedEndDate(date);
  };
  const [startEndDateError, setStartEndDateError] = useState("");
  // upload image
  const [imageId, setImageId] = useState("");
  const [imageInfo, setImageInfo] = useState("");
  const [, setDelImg] = useState(false);
  // delete selected image from image uploader
  const delImgAction = (passeVal: boolean) => {
    setDelImg(passeVal);
  };
  const [, setCheckImgUpload] = useState(false);
  // Get PreviewImg Details
  const viewPreviewImageData = (passedVal: any) => {
    setCheckImgUpload(passedVal);
  };
  const updateDataStateFromChild = async (selectedImgId: any) => {
    setImageId(selectedImgId);
  };

  useEffect(() => {
    selectedItem?.casePending === "Yes" ? setPendingCaseCheck("Yes") : setPendingCaseCheck("No");
    selectedItem?.deathCase === "Yes" ? setDeathCaseCheck("Yes") : setDeathCaseCheck("No");
    selectedItem?.name !== undefined ? setDescription(selectedItem?.name) : setDescription("");

    selectedItem?.casePendingVal !== undefined && legalTypeList.length !== 0
      ? selectedItem?.casePendingVal !== "" && legalTypeList.length !== 0
        ? setSelectedLegalType(
            legalTypeList.find((o: LegalTypeList) => o.value === selectedItem?.casePendingVal)!,
          )
        : setSelectedLegalType(null)
      : setSelectedLegalType(null);

    if (selectedItem?.startDate !== undefined) {
      let year = selectedItem?.startDate.split("-")[2];
      let month = selectedItem?.startDate.split("-")[1];
      let day = selectedItem?.startDate.split("-")[0];
      let date = year + "-" + month + "-" + day;
      setSelectedDate(dayjs(date));
    }
    if (selectedItem?.startDate === undefined) {
      setSelectedDate(dayjs(new Date()));
    }

    if (selectedItem?.endDate !== undefined) {
      let year = selectedItem?.endDate.split("-")[2];
      let month = selectedItem?.endDate.split("-")[1];
      let day = selectedItem?.endDate.split("-")[0];
      let date = year + "-" + month + "-" + day;
      setSelectedEndDate(dayjs(date));
    }
    if (selectedItem?.endDate === undefined) {
      setSelectedEndDate(null);
    }

    selectedItem?.supportingDocument !== undefined
      ? selectedItem?.supportingDocument !== ""
        ? setImageInfo(selectedItem?.supportingDocument)
        : setImageInfo("")
      : setImageInfo("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, legalTypeList]);

  const addLegalDetailsBody = {
    isCasePending: pendingCaseCheck,
    isDeathDueToNegligence: deathCaseCheck,
    description: description,
    fromDate: dayjs(selectedDate).utc().format("YYYY-MM-DDTHH:mm:ssZ"),
  } as AddLegalDetailsBody;

  const submitLegalDetails = () => {
    if ((new Date(String(selectedDate)) > new Date(String(endDate)) || (new Date(String(endDate)) < new Date(String(selectedDate))))) {
      setStartEndDateError(`${t("appointmentpage.datevalid", { ns: ["home"] })!}`);
    }
    else {
      setStartEndDateError("");
    

    if (selectedItem?.id === undefined) {
      // Add Dr. legal Details
      if (imageId === undefined) {
      //   dispatch(
      //     addDrLegalDetails(
      //       Object.assign(addLegalDetailsBody, {
      //         legaltypeId: selectedLegalType?.value !== null ? selectedLegalType?.value! : null,
      //         toDate: endDate !== null ? dayjs(endDate).utc().format("YYYY-MM-DDTHH:mm:ssZ") : null,
      //         supportdocId: imageId !== "" ? imageId : null,
      //       }),
      //     ),
      //   );
      // }  
      if ((new Date(String(selectedDate)) > new Date(String(endDate)) || (new Date(String(endDate)) < new Date(String(selectedDate))))) {
        setStartEndDateError(`${t("appointmentpage.datevalid", { ns: ["home"] })!}`);
      } 
      else{
        setStartEndDateError("");
                dispatch(
          addDrLegalDetails(
            Object.assign(addLegalDetailsBody, {
              legaltypeId: selectedLegalType?.value !== null ? selectedLegalType?.value! : null,
              toDate: endDate !== null ? dayjs(endDate).utc().format("YYYY-MM-DDTHH:mm:ssZ") : null,
              supportdocId: imageId !== "" ? imageId : null,
            }),
          ),
        );
      }  
    }
      else {
        dispatch(
          addDrLegalDetails(
            Object.assign(addLegalDetailsBody, {
              legaltypeId: selectedLegalType?.value !== null ? selectedLegalType?.value! : null,
              toDate: endDate !== null ? dayjs(endDate).utc().format("YYYY-MM-DDTHH:mm:ssZ") : null,
              supportdocId: imageId !== "" ? imageId! : null,
            }),
          ),
        );
      }
      // dispatch(addDoctorLegalDetails(addLegalDetailsBody));
    } else {
      // Edit Dr. Education Details

      if (imageId === undefined) {
        // When user does not upload image on add work experience

        dispatch(
          editDrLegalDetails(
            Object.assign(addLegalDetailsBody, {
              legalDetailsId: Number(selectedItem?.id!),
              legaltypeId: selectedLegalType?.value !== null ? selectedLegalType?.value! : null,
              toDate: endDate !== null ? dayjs(endDate).utc().format("YYYY-MM-DDTHH:mm:ssZ") : null,
              supportdocId: imageId !== "" ? imageId : null,
            } as EditLegalDetailsBody),
          ),
        );
      } else {
        dispatch(
          editDrLegalDetails(
            Object.assign(addLegalDetailsBody, {
              legalDetailsId: Number(selectedItem?.id!),
              legaltypeId: selectedLegalType?.value !== null ? selectedLegalType?.value! : null,
              toDate: endDate !== null ? dayjs(endDate).utc().format("YYYY-MM-DDTHH:mm:ssZ") : null,
              supportdocId: imageId !== "" ? imageId : null,
            } as EditLegalDetailsBody),
          ),
        );
      }
    }
  }
  };

  const addDrLegalDetailsRes = useSelector((state: AppState) => state.addDrLegalDetailsRes);
  const [addDrLegalDetailsMsg, setAddDrLegalDetailsMsg] = useState("");
  const [addDrLegalDetailsMsgType, setAddDrLegalDetailsMsgType] = useState<AlertColor>("success");

  useEffect(() => {
    if (addDrLegalDetailsRes?.successMsg !== "") {
      setAddDrLegalDetailsMsgType("success");
      setAddDrLegalDetailsMsg(addDrLegalDetailsRes?.successMsg);
      dispatch(getLegalDetails());
      closeDialog();
    }
    if (addDrLegalDetailsRes?.errorMsg !== "") {
      setAddDrLegalDetailsMsgType("error");
      setAddDrLegalDetailsMsg(addDrLegalDetailsRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addDrLegalDetailsRes]);

  const editDrLegalDetailsRes = useSelector((state: AppState) => state.editDrLegalDetailsRes);
  const [editLegalDetailsMsg, setEditLegalDetailsMsg] = useState("");
  const [editLegalDetailsMsgType, setEditLegalDetailsMsgType] = useState<AlertColor>("success");

  useEffect(() => {
    if (editDrLegalDetailsRes?.successMsg !== "") {
      setEditLegalDetailsMsgType("success");
      setEditLegalDetailsMsg(editDrLegalDetailsRes?.successMsg);
      dispatch(getLegalDetails());
      closeDialog();
    }
    if (editDrLegalDetailsRes?.errorMsg !== "") {
      setEditLegalDetailsMsgType("error");
      setEditLegalDetailsMsg(editDrLegalDetailsRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editDrLegalDetailsRes]);

  return (
    <Dialog
      open={activeResetPasswordDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeDialog}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
      fullScreen={fullScreen}
      fullWidth
    >
      {addDrLegalDetailsRes?.loading || editDrLegalDetailsRes?.loading ? <MWLoader /> : null}
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            {selectedItem?.id ? (
              <Typography variant="h5">
                {
                  t("userprofilepage.editlegaldetail", {
                    ns: ["home"],
                  })!
                }
              </Typography>
            ) : (
              <Typography variant="h5">
                {
                  t("userprofilepage.addlegaldetail", {
                    ns: ["home"],
                  })!
                }
              </Typography>
            )}
          </Grid>
          <Grid item>
            <IconButton onClick={() => closeDialog()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-between"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box>{t("userprofilepage.pendingcase", { ns: ["home"] })}</Box>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  row
                  value={pendingCaseCheck}
                  onChange={handlePendingCaseCheckChange}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label={
                      <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        spacing={0.5}
                      >
                        <Typography>{t("filePrepPage.yes", { ns: ["home"] })!}</Typography>
                      </Stack>
                    }
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio />}
                    label={
                      <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        spacing={0.5}
                      >
                        <Typography>{t("filePrepPage.no", { ns: ["home"] })!}</Typography>
                      </Stack>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box>{t("userprofilepage.deathreason", { ns: ["home"] })}</Box>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  row
                  value={deathCaseCheck}
                  onChange={handleDeathCaseCheckChange}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label={
                      <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        spacing={0.5}
                      >
                        <Typography>{t("filePrepPage.yes", { ns: ["home"] })!}</Typography>
                      </Stack>
                    }
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio />}
                    label={
                      <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        spacing={0.5}
                      >
                        <Typography>{t("filePrepPage.no", { ns: ["home"] })!}</Typography>
                      </Stack>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography variant="body1" style={{ marginBottom: "5px" }}>
                {t("userprofilepage.casependingat", { ns: ["home"] })}
              </Typography>
              <Autocomplete
                value={selectedLegalType}
                id="highest-states"
                options={legalTypeList}
                onChange={(event: any, newValue: any) => {
                  selectedLegalTypeChange(event, newValue);
                }}
                renderInput={(params) => <TextField {...params} placeholder="Select Legal Type" />}
                size="small"
                getOptionLabel={(option: any) => option?.label! || ""}
                isOptionEqualToValue={(option: any, value:any ) => option.value === value?.value!}
              />
              <Typography style={{ marginBottom: "5px" }}>
                {t("appointmentpage.desc", { ns: ["home"] })}
              </Typography>
              <TextField
                value={description}
                onChange={(e) => descriptionChange(e.target.value)}
                label={t("appointmentpage.desc", { ns: ["home"] })}
                placeholder={t("userprofilepage.enterlegaldetail", { ns: ["home"] })!}
                type="text"
                fullWidth
                size="small"
                multiline
                minRows={4}
                maxRows={4}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <MWUploader
                documentType="doctor-legal-details"
                criteria={t("userprofilepage.awarddocsize", { ns: ["home"] })!}
                updatedDataState={updateDataStateFromChild}
                title={t("userprofilepage.uploadsuppdoc", { ns: ["home"] })!}
                visibility={false}
                imageUrl={imageInfo}
                deleteImgAction={delImgAction}
                previewImageData={viewPreviewImageData}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography>{t("userprofilepage.startdate", { ns: ["home"] })!}</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="DD/MM/YYYY"
                  value={selectedDate}
                  onChange={handleDateChange}
                  views={["year", "month", "day"]}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      fullWidth
                      inputProps={{ ...params.inputProps }}
                      size="small"
                      variant="outlined"
                    />
                  )}
                  InputAdornmentProps={{ position: "start" }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography>{t("appointmentpage.enddate", { ns: ["home"] })!}</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="DD/MM/YYYY"
                  value={endDate}
                  onChange={handleToDateChange}
                  views={["year", "month", "day"]}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      fullWidth
                      inputProps={{ ...params.inputProps }}
                      size="small"
                      variant="outlined"
                    />
                  )}
                  InputAdornmentProps={{ position: "start" }}
                  // maxDate={isEdit ? dayjs(new Date()) : undefined}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          {startEndDateError !== "" ? (
                <Typography style={{ marginBottom: "5px", color: "#d32f2f", alignItems:"center", marginLeft:"20px" }}>
                  {startEndDateError}
                </Typography>
              ) : null}
        </Stack>
        {addDrLegalDetailsMsg !== "" ? (
          <Box my={2}>
            <Alert
              severity={addDrLegalDetailsMsgType}
              onClose={() => {
                dispatch(clearEditDrLegalDetailsAPIRes());
                setAddDrLegalDetailsMsg("");
              }}
            >
              {addDrLegalDetailsMsg}
            </Alert>
          </Box>
        ) : null}
        {editLegalDetailsMsg !== "" ? (
          <Box my={2}>
            <Alert
              severity={editLegalDetailsMsgType}
              onClose={() => {
                dispatch(clearEditDrLegalDetailsAPIRes());
                setEditLegalDetailsMsg("");
              }}
            >
              {editLegalDetailsMsg}
            </Alert>
          </Box>
        ) : null}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={2}>
          <Grid item>
            <Button variant="outlined" onClick={() => closeDialog()} sx={{ textTransform: "none" }}>
              {t("accountdetail.cancel", { ns: ["home"] })}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              disabled={addDrLegalDetailsRes?.loading || editDrLegalDetailsRes?.loading}
              onClick={() => submitLegalDetails()}
              sx={{ textTransform: "none" }}
            >
              {t("Workschedulepage.submit", { ns: ["home"] })!}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
